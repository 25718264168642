
import { B_REST_Utils } from "@/bREST/core/classes";
import { default as MyApp_Class } from "@/custom/App.js";
const MyApp = MyApp_Class.instance;


//Clash between CalendarEvent::participationType_x() and consts here + vs if their labels should be in app.consts.calendar.x or ex components.calendarEventClientView.trial

export default class CalendarEventClient
{
	//Must match w Model_EventClient::OCCURRENCE_TYPE_x
	static get TYPE_NORMAL()        { return "normal";       }
	static get TYPE_MAKEUP()        { return "makeUp";       }
	static get TYPE_TRIAL()         { return "trial";        }
	static get TYPE_PRIVATE_GROUP() { return "privateGroup"; }
	static get TYPE_SPECIAL_EVENT() { return "specialEvent"; }
	static get TYPE_FLEX()          { return "flex";         }
	static get TYPE_A_LA_CARTE()    { return "aLaCarte";     }
		static _TYPES = [CalendarEventClient.TYPE_NORMAL,CalendarEventClient.TYPE_MAKEUP,CalendarEventClient.TYPE_TRIAL,CalendarEventClient.TYPE_PRIVATE_GROUP,CalendarEventClient.TYPE_SPECIAL_EVENT,CalendarEventClient.TYPE_FLEX,CalendarEventClient.TYPE_A_LA_CARTE];
		static get TYPES() { return CalendarEventClient._TYPES; }
		//IMPORTANT: Also add in app's custom loc @app.consts.calendar.occurrenceTypes.x
	
	//Must match w Model_EventClient::OCCURRENCE_STATE_x
	static get STATE_UPCOMING()                       { return "?"; }
	static get STATE_CAME()                           { return "O"; }
	static get STATE_NOTIFY_THEN_EARN_RESTORE_TOKEN() { return "X"; }
	static get STATE_NO_SHOW()                        { return "!"; }
		static _STATES = [CalendarEventClient.STATE_UPCOMING,CalendarEventClient.STATE_CAME,CalendarEventClient.STATE_NOTIFY_THEN_EARN_RESTORE_TOKEN,CalendarEventClient.STATE_NO_SHOW];
		static get STATES() { return CalendarEventClient._STATES; }
		//IMPORTANT: Also add in app's custom loc @app.consts.calendar.occurrenceStates.x + CalendarEventStaffView.vue::STATES_PROPS
	
	_client_fk = null;
	_firstName = null;
	_lastName  = null;
	_type      = null;  //One of TYPE_x
	_state     = null;  //One of STATE_x
	
	constructor(obj)
	{
		obj = B_REST_Utils.object_hasValidStruct_assert(obj, {
			client_fk: {accept:[Number], required:true},
			firstName: {accept:[String], required:true},
			lastName:  {accept:[String], required:true},
			type:      {accept:[String], required:true},
			state:     {accept:[String], required:true},
		}, "CalendarEventClient");
		
		this._client_fk = obj.client_fk;
		this._firstName = obj.firstName;
		this._lastName  = obj.lastName;
		this._type      = obj.type;
		this._state     = obj.state;
	}
	
	static t_common(subLocPath,details=null) { return MyApp.t_custom(`app.consts.calendar.${subLocPath}`,details); } //WARNING: Path struct also used in App.js::consts_x_asTranslatedItems()
	
	
	get client_fk() { return this._client_fk; }
	get firstName() { return this._firstName; }
	get lastName()  { return this._lastName;  }
	get fullName()  { return `${this._firstName} ${this._lastName}`; }
	
	get type()                { return this._type;                                             }
	set type(val)             { this._type=val;                                                }
	get type_label()          { return CalendarEventClient.type_getLabel(this._type);          }
	static type_getLabel(val) { return CalendarEventClient.t_common(`occurrenceTypes.${val}`); }
	
	get state()                { return this._state;                                             }
	set state(val)             { this._state=val;                                                }
	get state_label()          { return CalendarEventClient.state_getLabel(this._state);         }
	static state_getLabel(val) { return CalendarEventClient.t_common(`occurrenceStates.${val}`); }
	
};
