<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "configAdministrativeRegionList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./ConfigAdministrativeRegionForm.vue"),
			modelName: "ConfigAdministrativeRegion",
			fromLoader: {
				apiBaseUrl: "/configAdministrativeRegions/",
			},
			cols: {
				name: {
					fieldNamePaths: "name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				name: {fieldNamePath:"name"},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>