
import { B_REST_Utils } from "@/bREST/core/classes";
import { default as MyApp_Class } from "@/custom/App.js";
const MyApp = MyApp_Class.instance;



export default class RegFlowFilter
{
	_items      = null;  //Arr of {pkOrEnumTag,label}
	_label      = null;
	_shortLabel = null;
	_selections = []; //Arr of pkOrEnumTag
	
	
	
	constructor(items, label,shortLabel=null)
	{
		this._items      = items;
		this._label      = label;
		this._shortLabel = shortLabel ?? label;
	}
		static create_sharedList(sharedListName, label)
		{
			const items = MyApp.sharedLists_getItems(sharedListName).map(loop_model => ({pkOrEnumTag:loop_model.pk,label:loop_model.toLabel()}) );
			
			return new RegFlowFilter(items, label);
		}
		static create_enumField(modelName, fieldName)
		{
			const items           = MyApp.models_field_getEnumMembers(modelName,fieldName).map(loop_enumMember => ({pkOrEnumTag:loop_enumMember.tag,label:loop_enumMember.label}));
			const fieldDescriptor = MyApp.models_field_getDescriptor(modelName,fieldName);
			
			return new RegFlowFilter(items, fieldDescriptor.label,fieldDescriptor.shortLabel);
		}
		static create_custom(items, label) { return new RegFlowFilter(items,label); }
	
	
	get items()      { return this._items;      }
	get label()      { return this._label;      }
	get shortLabel() { return this._shortLabel; }
	
	get selections()     { return this._selections;          }
	set selections(val)  { this._selections=val;             }
	get selections_has() { return this._selections.length>0; }
	
	items_getLabel(pkOrEnumTag)
	{
		return this._items.find(loop_item => loop_item.pkOrEnumTag===pkOrEnumTag)?.label ?? B_REST_Utils.throwEx(`Item "${pkOrEnumTag}" not found, for RegFlowFilter "${this._label}"`);
	}
	
	selections_add(pkOrEnumTag)
	{
		if (this._selections.includes(pkOrEnumTag)) { return; }
		
		this._selections.push(pkOrEnumTag);
	}
	selections_addMultiple(pkOrEnumTagArr)
	{
		for (const loop_pkOrEnumTag of pkOrEnumTagArr) { this.selections_add(loop_pkOrEnumTag); }
	}
	selections_remove(pkOrEnumTag) { B_REST_Utils.array_remove_byVal(this._selections,pkOrEnumTag); }
	selections_clear() { this._selections = []; }
};
