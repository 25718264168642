<template>
	<br-generic-list-base :derived-component="_self">
		<template #item.pk="{ rowInfo, colInfo, modelField }">
			<span>{{ rowInfo.model.pk }}</span>
		</template>
		
		<template #data-table-area--after>
			<div class="pt-6 text-body-1 font-weight-bold text-right">{{ t("balance",{balance}) }}</div>
		</template>
		
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList, B_REST_Vuetify_Prompt } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "clientReferralHistoryList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			modelName: "ClientReferralHistory",
			fromLoader: {
				apiBaseUrl: "/clientReferralHistory",
			},
			cols: {
				pk: {
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				created_dt: {
					fieldNamePaths: "created_dt",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				type: {
					fieldNamePaths: "type",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				delta: {
					fieldNamePaths: "delta",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col, model)
					{
						const type  = model.select("type").val;
						let   delta = model.select("delta").val;
						if (type===this.$bREST.consts.clientReferralHistory_type.TO_ANY_ON_USAGE) { delta=-delta; }
						return this.$bREST.money_format(delta);
					},
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
		computed: {
			balance() { return this.modelList.lastLoad_coreInjection_customData?.balance ?? "-"; },
		},
	};
	
</script>