<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_VueApp_RouteDef, B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "franchiseeParkList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./FranchiseeParkForm.vue"),
			modelName: "FranchiseePark",
			fromLoader: {
				apiBaseUrl: "/franchiseeParks/",
			},
			cols: {
				/*
				NOTE: Don't display franchisee's name, unless this isn't only being used from within FranchiseeForm.vue
					franchiseeName: {
						fieldNamePaths: "franchisee.name",
						style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					},
				*/
				name: {
					fieldNamePaths: "name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({
					isEnabled(action,selectedModels=null) { return this.$bREST.roles_isAnyRoleAdmin; },
				}),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({
						isEnabled(action,model) { return this.$bREST.roles_isAnyRoleAdmin; },
					}),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				calc_flatSearch: {fieldNamePath:"calc_flatSearch"},
				name: {fieldNamePath:"name"},
				postalCode: {fieldNamePath:"postalCode"},
				isVisible: {fieldNamePath:"isVisible"},
				// franchisee_fk: {fieldNamePath:"franchisee_fk", brFieldDbProps:{items:"unfilteredFranchiseeList"}}, //NOTE: If we want to display this, only makes sense if we're NOT in FranchiseeForm.vue
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>