
import B_REST_Utils                        from "../B_REST_Utils.js";
import B_REST_App_base                     from "../app/B_REST_App_base.js";
import B_REST_FieldDescriptors             from "./B_REST_FieldDescriptors.js";
import B_REST_CustomFilterDescriptor       from "./B_REST_CustomFilterDescriptor.js";
import B_REST_Model                        from "../models/B_REST_Model.js";
import B_REST_Model_Load_RequiredFields    from "../models/B_REST_Model_Load_RequiredFields.js";
import { B_REST_Model_Load_SearchOptions } from "../models/B_REST_Model_Load_SearchOptions.js";



export default class B_REST_Descriptor
{
	static get PARTIAL_CLASS_BASE() { return "<base>"; }
	
	static get LOC_TABLE_PARENT_FIELDNAME() { return "loc";  } //Ex in ActivitySector {pk<int>, name<string>, subSectors[<ActivitySector>], loc[<ActivitySector_Loc>]}, the sub model holding loc is defined as "loc" in the model's fields
	static get LOC_TABLE_LANG_FIELDNAME()   { return "lang"; } //Ex in ActivitySector_Loc {activitySector_fk<int>, lang<string>, shortName<string>, longName<string>, desc<string>}, the "lang" field being part of the FK
	
	static get LOAD_OVERWRITE_CACHE()                 { return true;  } //If in load_x(), when we pass the useCachedShare opt, if we should or not replace cached models w more info than we had before
	static get FROM_OBJ_OVERWRITE_CACHE()             { return true;  } //Same thing as the above, but for now, this is only for B_REST_ModelField_ModelLookupRef::_abstract_fromObj()
	static get FROM_OBJ_SKIP_ILLEGALS_LOAD()          { return false; } //Maybe we should keep this false; could help finding bugs
	static get FROM_OBJ_SKIP_ILLEGALS_SAVE()          { return true;  } //For what the server returns. It contains lots of stuff that's already set, so don't bother
	static get FROM_OBJ_SKIP_ILLEGALS_SHARED_UPDATE() { return true;  } //When we load / save stuff, sometimes shared cache gets updated; we don't want to cause probs
	static get MODEL_LOOKUP_REF_CREATE_EMPTY_CACHE()  { return true;  } //For B_REST_ModelField_DB::lookup_updateBoundField()
	
	static get BATCH_API_CALL_QSA_PKS() { return "batchAction_pks"; } //IMPORTANT: Server's RouteParser_base::GENERIC_LIST_FORM_MODULE_BATCH_API_CALL_QSA_PKS & frontend's B_REST_Descriptor::BATCH_API_CALL_QSA_PKS must match
	
	static get COMMON_DEFS_ADD_FROM_SERVER_BOOT_RESPONSE_EXTRA_OPTIONS()
	{
		return [
			"validation_custom_fastThrottle_delay",
			"hook_load_after",
			"hook_save_before",
			"hook_save_after",
			"model_toLabelFunc"
				//WARNING: We are supporting hook_load_before in frontend now, but not yet in backend
		];
	}
	static get COMMON_DEFS_ADD_FROM_SERVER_BOOT_RESPONSE_FACTORY_ONE_MAKE_FIELD_COMMON_OPTIONS_FN_MAP()
	{
		return {
			ld:   "func_load",
			sv:   "func_save",
			to:   "func_toObj",
			from: "func_fromObj",
			ch:   "func_unsavedChanges_has",
			can:  "func_delete_can_orTag",
			del:  "func_delete",
		};
	}
	
	static _commonDefs = {}; //Map of common descriptors, as name => B_REST_Descriptor instance
	
	
	_name                                 = null;  //String without "Model_x". Would be helpful if it started in uppercase, ex "Contact"
	_partialFieldName                     = null;  //Ex "type", for derived classes patterns. See backend's Descriptor_base::_asPartialClass()
	_partialClassType                     = null;  //Ex PARTIAL_CLASS_BASE or "brand", for derived classes patterns. See backend's Descriptor_base::_asPartialClass()
	_isAutoInc                            = false;
	_softDelete                           = false;
	_serverHooks                          = null;  //Check server's Model_base::hook_getDefinedList(). An arr like ["bl", "bs", "as"] that tells the before/after X hooks that model implements. We don't care about it now though
	_customFilters                        = {};    //Map of filterName => B_REST_CustomFilterDescriptor instances; filters that don't correspond to direct B_REST_FieldDescriptor_DB fields in this model
	_toLabel_fieldNamePaths               = null;  //Ex "cieName|user.firstName+user.lastName|coords_address+coords_city+coords_postalCode"
	_calcFlatSearch_fieldNamePaths        = null;  //Ex "cieName|user.firstName+user.lastName|coords_address+coords_city+coords_postalCode"
	_pks                                  = [];    //PK fields as B_REST_FieldDescriptors.DB instances
	_cDT                                  = null;  //Created DT B_REST_FieldDescriptors.DB instance, if any
	_uDT                                  = null;  //Updated DT B_REST_FieldDescriptors.DB instance, if any
	_dbFields                             = {};    //Map of fieldName => B_REST_FieldDescriptors.DB instances, excluding PKs, created/updated DT
	_modelLookupRefFields                 = {};    //Map of fieldName => B_REST_FieldDescriptors.ModelLookupRef instances
	_subModelFields                       = {};    //Map of fieldName => B_REST_FieldDescriptors.SubModel instances
	_subModelListFields                   = {};    //Map of fieldName => B_REST_FieldDescriptors.SubModelList instances
	_otherFields                          = {};    //Map of fieldName => B_REST_FieldDescriptors.Other instances
	_fileFields                           = {};    //Map of fieldName => B_REST_FieldDescriptors.File instances
	_allFields                            = null;  //Map of all fields, including PKs, created/updated DT, for quick access
	_hasLocTable                          = false; //If it has a B_REST_FieldDescriptor_SubModelList named "loc" (LOC_TABLE_PARENT_FIELDNAME). Check locTable_x() docs
	_validation_custom_fastFuncs          = [];    //Optional funcs as (B_REST_Model model), that will be called everytime something is changed. In it, add to fields' validation_custom_errorList at will
	_validation_custom_fastThrottle_delay = null;  //Nb of msecs to wait between each fast func recalc
	_validation_custom_asyncFuncs         = [];    //Like validation_custom_fastFuncs, but async. Validation funcs will be able to wait for async validation to finish before ex submitting a form
	_hook_load_before                     = null;  //Async hook that can be called in B_REST_Descriptor::load_x(), as (request<B_REST_Request>)
	_hook_load_after                      = null;  //Async hook that can be called in B_REST_Descriptor::load_x(), as (response<B_REST_Response>, models<B_REST_Model arr>)
	_hook_save_before                     = null;  //Async hook that can be called in B_REST_Model::awaitUnsavedChangesSaved(), as (request<B_REST_Request>,  model<B_REST_Model>). Check B_REST_Model::awaitUnsavedChangesSaved() for more info
	_hook_save_after                      = null;  //Async hook that can be called in B_REST_Model::awaitUnsavedChangesSaved(), as (response<B_REST_Response>,model<B_REST_Model>,wasNew). Check B_REST_Model::awaitUnsavedChangesSaved() for more info
	_model_toLabelFunc                    = null;  //Func as (model<B_REST_Model>,reason) that can be called when we call B_REST_Model.toLabel(), to give info about the model instance (ex firstName+lastName)
	//NOTE: If we add new things, also do in commonDefs_fetch_fromServerBootResponse()
	
	
	/*
	Usage ex. Note that we can also load them all from server with commonDefs_fetch_fromServerBootResponse():
		const descriptor = new B_REST_Descriptor("Brand", [{name:"pk",type:B_REST_FieldDescriptors.DB.TYPE_INT,loc}], {
			isAutoInc: true,
			softDelete: true,
			serverHooks: ["al","bs","as"...],
			customFilters: [<B_REST_CustomFilterDescriptor>],
			toLabel_fieldNamePaths: "cieName|user.firstName+user.lastName|coords_address+coords_city+coords_postalCode",
			calcFlatSearch_fieldNamePaths: "cieName|user.firstName+user.lastName|coords_address+coords_city+coords_postalCode",
			validation_custom_fastThrottle_delay: 5000,
			validation_custom_fastFuncs:[(model){ ... }],
			async validation_custom_asyncFuncs:[(model){ ... }],
			hook_load_before(request)                   { ... },
			hook_load_after(response, modelOrModelList) { ... },
			hook_save_before(request, model)            { ... },
			hook_save_after(response, model, wasNew)    { ... },
			model_toLabelFunc(model, reason)            { ... },
			partialFieldName: null,
			partialClassType: null,
			partialLoc: null,
			cDT: {name:"cDT", loc},
			uDT: {name:"uDT", loc},
			dbFields: [
				B_REST_FieldDescriptors.DB.create_type_string( "some_string",  {loc,isRequired:false, min:0, max:20}), //Think about isNullable too
				B_REST_FieldDescriptors.DB.create_type_int(    "some_int",     {loc,isRequired:false, min:0, max:20, isNullable:true}),
				B_REST_FieldDescriptors.DB.create_type_decimal("some_decimal", {loc,isRequired:false, min:1.23, max:2.54, decimals:2}),
				B_REST_FieldDescriptors.DB.create_type_bool(   "some_bool",    {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_json(   "some_json",    {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_dt(     "some_dt",      {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_d(      "some_d",       {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_t(      "some_t",       {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_c_stamp("some_c_stamp", {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_u_stamp("some_u_stamp", {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_enum(   "some_enum",    {loc,isRequired:false, enum_members:"f|m"}),
				B_REST_FieldDescriptors.DB.create_type_phone(  "some_phone",   {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_email(  "some_email",   {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_pwd(    "some_pwd",     {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_custom( "some_custom",  {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_int(    "fk_coords",    {loc,isRequired:false}),
				B_REST_FieldDescriptors.DB.create_type_int(    "fk_user",      {loc,isRequired:false}),
			],
			modelLookupRefFields: [
				new B_REST_FieldDescriptors.ModelLookupRef("coords", "Coordinates", "fk_coords", {loc}),
				new B_REST_FieldDescriptors.ModelLookupRef("user",   "User",        "fk_user",   {loc}),
			],
			subModelFields: [
				new B_REST_FieldDescriptors.SubModel("mainEmp",  "BrandEmp", "fk_brand", {loc}),
				new B_REST_FieldDescriptors.SubModel("otherEmp", "BrandEmp", "fk_brand", {loc}),
			],
			subModelListFields: [
				new B_REST_FieldDescriptors.SubModelList("orderLines",   "OrderLine",   "fk_brand", {loc}),
				new B_REST_FieldDescriptors.SubModelList("invoiceLines", "InvoiceLine", "fk_brand", {loc}),
			],
			otherFields: [
				new B_REST_FieldDescriptors.Other("other_one", {loc}),
				new B_REST_FieldDescriptors.Other("other_two", {loc}),
			],
			fileFields: [
				new B_REST_FieldDescriptors.File("logo", false, {
					maxFileSize:  B_REST_FieldDescriptors.File.FILE_SIZE_MB,
					allowedTypes: B_REST_FieldDescriptors.File.FILES_MIME_PATTERNS_IMG,
					image_minW: 0,
					image_maxW: 1000,
					image_minH: 100,
					image_maxH: 200,
					loc
				}),
				new B_REST_FieldDescriptors.File("docs", true, {
					maxFileCount: 10,
					maxFileSize:  B_REST_FieldDescriptors.File.FILE_SIZE_MB,
					allowedTypes: B_REST_FieldDescriptors.File.FILES_MIME_PATTERNS_PDF_WORD,
					loc
				}),
			],
		});
	*/
	constructor(name, pks, options)
	{
		this._name = name;
		
		B_REST_Utils.array_isOfObjects_assert(pks);
		if (pks.length===0) { this._throwEx(`Expected at least one PK, as {name,type}`); }
		
		if (B_REST_Utils.object_hasPropName(options,"partialFieldName"))                     { this._partialFieldName                     = options.partialFieldName;                     }
		if (B_REST_Utils.object_hasPropName(options,"partialClassType"))                     { this._partialClassType                     = options.partialClassType;                     }
		if (B_REST_Utils.object_hasPropName(options,"isAutoInc"))                            { this._isAutoInc                            = options.isAutoInc;                            }
		if (B_REST_Utils.object_hasPropName(options,"softDelete"))                           { this._softDelete                           = options.softDelete;                           }
		if (B_REST_Utils.object_hasPropName(options,"serverHooks"))                          { this._serverHooks                          = options.serverHooks;                          }
		if (B_REST_Utils.object_hasPropName(options,"toLabel_fieldNamePaths"))               { this._toLabel_fieldNamePaths               = options.toLabel_fieldNamePaths;               }
		if (B_REST_Utils.object_hasPropName(options,"calcFlatSearch_fieldNamePaths"))        { this._calcFlatSearch_fieldNamePaths        = options.calcFlatSearch_fieldNamePaths;        }
		if (B_REST_Utils.object_hasPropName(options,"validation_custom_fastFuncs"))          { this._validation_custom_fastFuncs          = options.validation_custom_fastFuncs;          }
		if (B_REST_Utils.object_hasPropName(options,"validation_custom_fastThrottle_delay")) { this._validation_custom_fastThrottle_delay = options.validation_custom_fastThrottle_delay; }
		if (B_REST_Utils.object_hasPropName(options,"validation_custom_asyncFuncs"))         { this._validation_custom_asyncFuncs         = options.validation_custom_asyncFuncs;         }
		if (B_REST_Utils.object_hasPropName(options,"hook_load_before"))                     { this._hook_load_before                     = options.hook_load_before;                     }
		if (B_REST_Utils.object_hasPropName(options,"hook_load_after"))                      { this._hook_load_after                      = options.hook_load_after;                      }
		if (B_REST_Utils.object_hasPropName(options,"hook_save_before"))                     { this._hook_save_before                     = options.hook_save_before;                     }
		if (B_REST_Utils.object_hasPropName(options,"hook_save_after"))                      { this._hook_save_after                      = options.hook_save_after;                      }
		if (B_REST_Utils.object_hasPropName(options,"model_toLabelFunc"))                    { this._model_toLabelFunc                    = options.model_toLabelFunc;                    }
		
		//Parse PKs
		for (const loop_pkInfo of pks)
		{
			const loop_fieldDescriptor = new B_REST_FieldDescriptors.DB(loop_pkInfo.name, loop_pkInfo.type, {isPKField:true,loc:loop_pkInfo.loc});
			loop_fieldDescriptor.descriptor = this;
			
			this._pks.push(loop_fieldDescriptor);
		}
		
		//If we have a partial class field name, create a DB field for it
		if (this._partialFieldName)
		{
			this._dbFields[this._partialFieldName] = new B_REST_FieldDescriptors.DB(this._partialFieldName, B_REST_FieldDescriptors.DB.TYPE_STRING, {loc:options.partialLoc}); //NOTE: Should be TYPE_ENUM, but we'd need to give it all the possibilities and would be a pain
		}
		
		//Created / updated DT
		if (B_REST_Utils.object_hasPropName(options,"cDT")) { this._cDT = this._constructor_parse_cuDT(options.cDT,B_REST_FieldDescriptors.DB.TYPE_C_STAMP); }
		if (B_REST_Utils.object_hasPropName(options,"uDT")) { this._uDT = this._constructor_parse_cuDT(options.uDT,B_REST_FieldDescriptors.DB.TYPE_U_STAMP); }
		
		//All other fields. Convert arr to map
		for (const loop_fieldType in B_REST_Descriptor._constructor_generalFieldTypeMap)
		{
			if (B_REST_Utils.object_hasPropName(options, loop_fieldType))
			{
				const loop_fieldDescriptor_ClassName = B_REST_Descriptor._constructor_generalFieldTypeMap[loop_fieldType];
				const loop_option_fieldDescriptors  = options[loop_fieldType];
				B_REST_Utils.array_isOfClassInstances_assert(loop_fieldDescriptor_ClassName, loop_option_fieldDescriptors);
				
				const this_which = this[`_${loop_fieldType}`]; //Ex _dbFields
				for (const loop_option_fieldDescriptor of loop_option_fieldDescriptors)
				{
					if (this_which[loop_option_fieldDescriptor.name]) { this._throwEx(`Field "${loop_option_fieldDescriptor.name}" already defined`); }
					
					loop_option_fieldDescriptor.descriptor = this;
					this_which[loop_option_fieldDescriptor.name] = loop_option_fieldDescriptor;	
				}
			}
		}
		
		//Just crash though if we've got a multi-field PK and that we have subModel/List fields
		if (this.isMultiFieldPK && (this._subModelFields.length>0||this._subModelListFields.length>0)) { this._throwEx(`Multi-field PK descriptors can't have sub model fields`); }
		
		//Make a map for easy fields access
		{
			this._allFields = {};
			
			for (const loop_fieldDescriptor of this._pks) { this._allFields[loop_fieldDescriptor.name]=loop_fieldDescriptor; }
			
			if (this._cDT) { this._allFields[this._cDT.name]=this._cDT; }
			if (this._uDT) { this._allFields[this._uDT.name]=this._uDT; }
			
			for (const loop_fieldType in B_REST_Descriptor._constructor_generalFieldTypeMap)
			{
				const this_which = this[`_${loop_fieldType}`]; //Ex _dbFields
				
				for (const loop_fieldName in this_which)
				{
					if (this._allFields[loop_fieldName]) { this._throwEx(`Field "${loop_fieldName}" already defined`); }
					this._allFields[loop_fieldName] = this_which[loop_fieldName];
				}
			}
		}
		
		//Check if we have a sub loc table
		this._hasLocTable = B_REST_Utils.object_hasPropName(this._subModelListFields, B_REST_Descriptor.LOC_TABLE_PARENT_FIELDNAME);
		
		//Custom filters
		const options_customFilters = options.customFilters;
		if (options_customFilters)
		{
			B_REST_Utils.array_isOfClassInstances_assert(B_REST_CustomFilterDescriptor, options_customFilters);
			
			for (const loop_options_customFilter of options_customFilters)
			{
				this._customFilters[loop_options_customFilter.name] = loop_options_customFilter;
			}
		}
	}
		static _constructor_generalFieldTypeMap = {
			dbFields:             B_REST_FieldDescriptors.DB,
			modelLookupRefFields: B_REST_FieldDescriptors.ModelLookupRef,
			subModelFields:       B_REST_FieldDescriptors.SubModel,
			subModelListFields:   B_REST_FieldDescriptors.SubModelList,
			otherFields:          B_REST_FieldDescriptors.Other,
			fileFields:           B_REST_FieldDescriptors.File,
		};
		//Where type is either TYPE_C_STAMP/TYPE_U_STAMP
		_constructor_parse_cuDT(obj, type)
		{
			const fieldDescriptor = new B_REST_FieldDescriptors.DB(obj.name, type, {loc:obj.loc});
			fieldDescriptor.descriptor = this;
			
			return fieldDescriptor;
		}
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(msg,details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`, details); }
	
	
	get name()                                 { return this._name;                                 }
	get debugName()                            { return `B_REST_Descriptor<${this._name}>`;         }
	get partialClassType()                     { return this._partialClassType;                     }
	get partialFieldName()                     { return this._partialFieldName;                     }
	get isAutoInc()                            { return this._isAutoInc;                            }
	get softDelete()                           { return this._softDelete;                           }
	get serverHooks()                          { return this._serverHooks;                          }
	get customFilters()                        { return this._customFilters;                        }
	get toLabel_fieldNamePaths()               { return this._toLabel_fieldNamePaths;               }
	get calcFlatSearch_fieldNamePaths()        { return this._calcFlatSearch_fieldNamePaths;        }
	get pks()                                  { return this._pks;                                  }
	get isMultiFieldPK()                       { return this._pks.length>1;                         }
	get cDT()                                  { return this._cDT;                                  }
	get uDT()                                  { return this._uDT;                                  }
	get dbFields()                             { return this._dbFields;                             }
	get modelLookupRefFields()                 { return this._modelLookupRefFields;                 }
	get subModelFields()                       { return this._subModelFields;                       }
	get subModelListFields()                   { return this._subModelListFields;                   }
	get otherFields()                          { return this._otherFields;                          }
	get fileFields()                           { return this._fileFields;                           }
	get allFields()                            { return this._allFields;                            }
	get validation_custom_fastFuncs()          { return this._validation_custom_fastFuncs;          }
	get validation_custom_fastThrottle_delay() { return this._validation_custom_fastThrottle_delay; }
	get validation_custom_asyncFuncs()         { return this._validation_custom_asyncFuncs;         }
	get hook_load_before()                     { return this._hook_load_before;                     }
	get hook_load_after()                      { return this._hook_load_after;                      }
	get hook_save_before()                     { return this._hook_save_before;                     }
	get hook_save_after()                      { return this._hook_save_after;                      }
	get model_toLabelFunc()                    { return this._model_toLabelFunc;                    }
	
	
	//Things we can change later
	set validation_custom_fastThrottle_delay(val) { this._validation_custom_fastThrottle_delay = val; }
	set hook_load_before(val)                     { this._hook_load_before                     = val; }
	set hook_load_after(val)                      { this._hook_load_after                      = val; }
	set hook_save_before(val)                     { this._hook_save_before                     = val; }
	set hook_save_after(val)                      { this._hook_save_after                      = val; }
	set model_toLabelFunc(val)                    { this._model_toLabelFunc                    = val; }
	
	validation_custom_fastFuncs_add(func)  { this._validation_custom_fastFuncs.push(func);  }
	validation_custom_asyncFuncs_add(func) { this._validation_custom_asyncFuncs.push(func); }
	
	dbFields_find(fieldName,throwOnNotFound=true)             { return this._xFields_find("_dbFields",            fieldName,throwOnNotFound); }
	modelLookupRefFields_find(fieldName,throwOnNotFound=true) { return this._xFields_find("_modelLookupRefFields",fieldName,throwOnNotFound); }
	subModelFields_find(fieldName,throwOnNotFound=true)       { return this._xFields_find("_subModelFields",      fieldName,throwOnNotFound); }
	subModelListFields_find(fieldName,throwOnNotFound=true)   { return this._xFields_find("_subModelListFields",  fieldName,throwOnNotFound); }
	otherFields_find(fieldName,throwOnNotFound=true)          { return this._xFields_find("_otherFields",         fieldName,throwOnNotFound); }
	fileFields_find(fieldName,throwOnNotFound=true)           { return this._xFields_find("_fileFields",          fieldName,throwOnNotFound); }
		_xFields_find(which, fieldName, throwOnNotFound=true)
		{
			if (this[which][fieldName]) { return this[which][fieldName]; }
			
			if (throwOnNotFound) { this._throwEx(`Unknown field "${fieldName}" in ${which}`); }
			return null;
		}
	//Same as the above, but using the index
	allFields_find(fieldName, throwOnNotFound=true)
	{
		if (this._allFields[fieldName]) { return this._allFields[fieldName]; }
		
		if (throwOnNotFound) { this._throwEx(`Field "${fieldName}" unknown`); }
		return null;
	}
	
	/*
	Allows crawling through sub models to reach an inner B_REST_FieldDescriptor_base. Might throw
	NOTE: Code similar to B_REST_Model::_select_parseAllocFieldNamePath_nest(), except that it doesn't instantiate anything
	*/
	allFields_find_byFieldNamePath(fieldNamePath) { return this._allFields_find_byFieldNamePath_nest(this,fieldNamePath,fieldNamePath); }
		_allFields_find_byFieldNamePath_nest(descriptorLvl, self_fieldNamePath, original_fieldNamePath)
		{
			const {self_fieldName, atIdx, target_fieldNameOrExpr} = B_REST_Utils.parseFieldNamePath(self_fieldNamePath);
			
			//First find our field
			const self_fieldDescriptor = descriptorLvl.allFields_find(self_fieldName); //Might throw
			
			//If it's trying to go down a B_REST_FieldDescriptor_SubModelList at a given idx (we don't care which here though)
			if (atIdx!==null)
			{
				//First make sure the field was a sub model list, otherwise we can't navigate sub models
				if (!(self_fieldDescriptor instanceof B_REST_FieldDescriptors.SubModelList))
				{
					this._throwEx(`[${atIdx}] field name path must point on a sub model list field, for "${original_fieldNamePath}"`);
				}
				
				//NOTE: We don't care about which idx here, in contrary to B_REST_Model::_select_parseAllocFieldNamePath_nest(), but we want to move on to the descriptor behind
				if (target_fieldNameOrExpr)
				{
					const sub_descriptorLvl = B_REST_Descriptor.commonDefs_get(self_fieldDescriptor.modelClassName);
					return this._allFields_find_byFieldNamePath_nest(sub_descriptorLvl, target_fieldNameOrExpr, original_fieldNamePath);
				}
				
				//Here, since we have to ret a B_REST_FieldDescriptor_x and not a B_REST_Descriptor, then just stop there, since we have no .fieldName to continue going down
				return self_fieldDescriptor;
			}
			//If we want to go through a sub model or lookup and get an inner field
			else if (target_fieldNameOrExpr)
			{
				//First make sure the field was a sub model or lookup, otherwise we can't navigate sub models
				if (!(self_fieldDescriptor instanceof B_REST_FieldDescriptors.WithFuncs_WithModels_base))
				{
					this._throwEx(`To have a nested field name path without [idx], must go through a sub model (!asList) or model lookup ref, for "${original_fieldNamePath}"`);
				}
				
				const sub_descriptorLvl = B_REST_Descriptor.commonDefs_get(self_fieldDescriptor.modelClassName);
				return this._allFields_find_byFieldNamePath_nest(sub_descriptorLvl, target_fieldNameOrExpr, original_fieldNamePath);
			}
			
			//Else final cases
			return self_fieldDescriptor;
		}
	
	customFilters_find(name, throwOnNotFound=true)
	{
		if (B_REST_Utils.object_hasPropName(this._customFilters,name)) { return this._customFilters[name]; }
		
		if (throwOnNotFound) { this._throwEx(`Custom filter "${name}" not found`); }
		return null;
	}
	
	field_loc_label(fieldNamePath)	
	{
		const fieldDescriptor = this._allFields_find_byFieldNamePath_nest(this,fieldNamePath,fieldNamePath);
		return fieldDescriptor.label;
	}
	field_loc_shortLabel(fieldNamePath)
	{
		const fieldDescriptor = this._allFields_find_byFieldNamePath_nest(this,fieldNamePath,fieldNamePath);
		return fieldDescriptor.shortLabel;
	}
	//Rets an arr of B_REST_FieldDescriptor_DB_EnumMember instances, as long as it's a TYPE_ENUM B_REST_FieldDescriptor_DB
	field_enumMembers(fieldNamePath)
	{
		const fieldDescriptor = this._allFields_find_byFieldNamePath_nest(this,fieldNamePath,fieldNamePath);
		return fieldDescriptor.enum_members ?? this._throwEx(`Field at fieldNamePath "${fieldNamePath}" isn't a TYPE_ENUM B_REST_FieldDescriptor_DB`);
	}
	
	
	/*
	Receives something like 123 or {fk:123, lang:"fr"} or "123-fr",
	and yields something like 123 or "123-fr"
	When it's for a single field PK, it will work with both val or field map
	*/
	pkToTag_vals(pkValOrTagOrFieldMap)
	{
		if (this.isMultiFieldPK)
		{
			//If it's already properly formatted (as long as the nb of "-" fits)
			if (B_REST_Utils.string_is(pkValOrTagOrFieldMap) && pkValOrTagOrFieldMap.split("-").length===this._pks.length) { return pkValOrTagOrFieldMap; }
			
			B_REST_Utils.object_assert(pkValOrTagOrFieldMap);
			return Object.values(pkValOrTagOrFieldMap).join("-");
		}
		else if (B_REST_Utils.object_is(pkValOrTagOrFieldMap))
		{
			const vals = Object.values(pkValOrTagOrFieldMap);
			return vals.length>0 ? vals[0] : this._throwEx(`Got an empty pkFieldMap`);
		}
		else { return this._isAutoInc ? parseInt(pkValOrTagOrFieldMap) : pkValOrTagOrFieldMap; }
	}
	//Yields something like "bob_fk-lang"
	pkToTag_names() { return this._pks.map(loop_fieldDescriptor=>loop_fieldDescriptor.name).join("-"); }
	
	
	
	//METHODS FOR DEFINING DESCRIPTORS FROM SERVER
		static get commonDefs_names() { return Object.keys(B_REST_Descriptor._commonDefs); }
		static commonDefs_has(name) { return B_REST_Utils.object_hasPropName(B_REST_Descriptor._commonDefs,name); }
		static commonDefs_get(name)
		{
			if (!B_REST_Descriptor.commonDefs_has(name)) { B_REST_Descriptor._throwEx(`No common descriptor named "${name}". First add it with B_REST_Descriptor::commonDefs_add()`); }
			return B_REST_Descriptor._commonDefs[name];
		}
		//Adds a common descriptor to the "bank"
		static commonDefs_add(descriptor)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Descriptor, descriptor);
			if (B_REST_Descriptor.commonDefs_has(descriptor._name)) { B_REST_Descriptor._throwEx(`Already have a common descriptor named "${descriptor._name}"`); }
			
			B_REST_Descriptor._commonDefs[descriptor._name] = descriptor;
		}
		
		/*
		Defines models from a server call returning a map of model names w their fields and such.
		We can also re-call this just to update loc
		See _commonDefs_fetch_fromServerBootResponse_factoryOne() & server's Model_base::dump_definition() for the struct
		We can also define more things for each models, like validation hooks
		Usage ex:
			const response = <B_REST_Response>;
			commonDefs_fetch_fromServerBootResponse(response.modelDefs, {
				contact: {
					validation_custom_fastFuncs: [(model)
					{
						...
					}],
					validation_custom_fastThrottle_delay: 1000,
					validation_custom_asyncFuncs: [(model)
					{
						...
					}],
					hook_save_before(model, request)
					{
						...
					},
					hook_save_after(model, response, wasNew)
					{
						...
					},
					model_toLabelFunc(model, reason)
					{
						...
					},
				},
				client: {
					...
				},
				invoice: {
					...
				},
			});
		IMPORTANT:
			If we're missing loc files (not contents) in server, then we'll get loc in the wrong lang (LocUtils::FROM_HEADERS_FALLBACK_ON_UNSUPPORTED_LANG)
		*/
		static commonDefs_fetch_fromServerBootResponse(apiModelDefs, modelOptions={})
		{
			B_REST_Utils.object_assert(apiModelDefs);
			B_REST_Utils.object_assert(modelOptions);
			
			try
			{
				for (const loop_name in apiModelDefs)
				{
					const loop_apiModelDef = apiModelDefs[loop_name];
					const loop_options     = B_REST_Utils.object_hasPropName(modelOptions,loop_name) ? modelOptions[loop_name] : {};
					const loop_descriptor  = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne(loop_apiModelDef);
					
					//We also allow passing extra props like custom validation funcs
					for (const loop_possibleOption of B_REST_Descriptor.COMMON_DEFS_ADD_FROM_SERVER_BOOT_RESPONSE_EXTRA_OPTIONS)
					{
						if (B_REST_Utils.object_hasPropName(loop_options,loop_possibleOption)) { loop_descriptor[loop_possibleOption] = loop_options[loop_possibleOption]; }
					}
					
					//Check if we want to add the descriptor to the list, or just take the received template and apply its new loc to the old one
					if (B_REST_Descriptor.commonDefs_has(loop_descriptor._name)) { B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_updateLoc(loop_descriptor); }
					else                                                         { B_REST_Descriptor.commonDefs_add(loop_descriptor);                                     }
				}
			}
			catch (e)
			{
				B_REST_Descriptor._throwEx(`Got err converting response to common model defs: ${e}`, apiModelDefs);
			}
		}
			//Check backend's Model_base::dump_definition() docs
			static _commonDefs_fetch_fromServerBootResponse_factoryOne(apiModelDef)
			{
				B_REST_Utils.object_assert(apiModelDef);
				
				const pks     = [];
				const options = {
					dbFields:             [],
					modelLookupRefFields: [],
					subModelFields:       [],
					subModelListFields:   [],
					otherFields:          [],
					fileFields:           [],
				};
				
				//General stuff
				{
					if (apiModelDef.hooks) { options.serverHooks                   = apiModelDef.hooks.split("|"); }
					if (apiModelDef.toLbl) { options.toLabel_fieldNamePaths        = apiModelDef.toLbl;            }
					if (apiModelDef.flatS) { options.calcFlatSearch_fieldNamePaths = apiModelDef.flatS;            }
					if (apiModelDef.partial)
					{
						options.partialFieldName = apiModelDef.partial.n;
						options.partialClassType = apiModelDef.partial.c;
						options.partialLoc       = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeLocProp(apiModelDef.partial);
					}
					if (apiModelDef.flags)
					{
						const flags = apiModelDef.flags.split("|");
						if (flags.includes("auto")) { options.isAutoInc  = true; }
						if (flags.includes("soft")) { options.softDelete = true; }
					}
				}
				
				//Fields
				{
					const apiModelDef_fields = apiModelDef.fields;
					
					//PKs
						for (const loop_pk of apiModelDef_fields.pks) { pks.push({name:loop_pk.n,type:loop_pk.t,loc:B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeLocProp(loop_pk)}); }
					
					//Created / updated DT
						const cDT = apiModelDef_fields.cDT;
						const uDT = apiModelDef_fields.uDT;
						if (cDT) { options.cDT={name:cDT.n,loc:B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeLocProp(cDT)}; }
						if (uDT) { options.uDT={name:uDT.n,loc:B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeLocProp(uDT)}; }
					
					//B_REST_FieldDescriptors.DB fields
						if (apiModelDef_fields.db)
						{
							for (const loop_apiModelDef_field of apiModelDef_fields.db)
							{
								const loop_options = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeFieldCommonOptions(apiModelDef, loop_apiModelDef_field);
								
									if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"min")) { loop_options.min        =loop_apiModelDef_field.min; }
									if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"max")) { loop_options.max        =loop_apiModelDef_field.max; }
									if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"dec")) { loop_options.decimals   =loop_apiModelDef_field.dec; }
									if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"fal")) { loop_options.optionalVal=loop_apiModelDef_field.fal; } //NOTE: If default is an arr, in code we take precaution of making a copy of it
									if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"enum"))
									{
										//Convert enum tags into instances of B_REST_FieldDescriptors.DB_EnumMember as {tag, locPath, extraData}
										loop_options.enum_members = B_REST_FieldDescriptors.DB.enum_makeEnumMembersFromPipedTagList(apiModelDef.name, loop_apiModelDef_field.n, loop_apiModelDef_field.enum); //Pass the model's name + field name
									}
									
									const loop_apiModelDef_field_lookupInfo = loop_apiModelDef_field.look;
									if (loop_apiModelDef_field_lookupInfo)
									{
										loop_options.lookupInfo = {modelName:loop_apiModelDef_field_lookupInfo.m, fieldName:loop_apiModelDef_field_lookupInfo.n};
									}
									
									//We also have loop_options.onch, for server's func_onChanged_get, but we won't care about it here
									
								options.dbFields.push(new B_REST_FieldDescriptors.DB(loop_apiModelDef_field.n, loop_apiModelDef_field.t, loop_options));
							}
						}
						
					//B_REST_FieldDescriptors.ModelLookupRef fields
						if (apiModelDef_fields.lookPub)
						{
							B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_add_modelLookupRefFields(apiModelDef, options, apiModelDef_fields.lookPub, true);
						}
						if (apiModelDef_fields.lookPriv) { B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_add_modelLookupRefFields(apiModelDef,options,apiModelDef_fields.lookPriv,false); }
						
					//B_REST_FieldDescriptors.SubModel fields
						if (apiModelDef_fields.sub)
						{
							for (const loop_apiModelDef_field of apiModelDef_fields.sub)
							{
								const loop_options = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeFieldCommonOptions(apiModelDef, loop_apiModelDef_field);
								options.subModelFields.push(new B_REST_FieldDescriptors.SubModel(loop_apiModelDef_field.n, loop_apiModelDef_field.c, loop_apiModelDef_field.fk, loop_options));
							}
						}
						
					//B_REST_FieldDescriptors.SubModelList fields
						if (apiModelDef_fields.subList)
						{
							for (const loop_apiModelDef_field of apiModelDef_fields.subList)
							{
								const loop_options = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeFieldCommonOptions(apiModelDef, loop_apiModelDef_field);
								options.subModelListFields.push(new B_REST_FieldDescriptors.SubModelList(loop_apiModelDef_field.n, loop_apiModelDef_field.c, loop_apiModelDef_field.fk, loop_options));
							}
						}
					
					//B_REST_FieldDescriptors.Other fields
						if (apiModelDef_fields.etc)
						{
							for (const loop_apiModelDef_field of apiModelDef_fields.etc)
							{
								const loop_options = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeFieldCommonOptions(apiModelDef, loop_apiModelDef_field);
								options.otherFields.push(new B_REST_FieldDescriptors.Other(loop_apiModelDef_field.n, loop_options));
							}
						}
					
					//B_REST_FieldDescriptors.File fields
						if (apiModelDef_fields.f)     { B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_add_fileFields(apiModelDef,options,apiModelDef_fields.f,    false); }
						if (apiModelDef_fields.fList) { B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_add_fileFields(apiModelDef,options,apiModelDef_fields.fList,true);  }
				}
				
				//Custom filters
				if (apiModelDef.cFilts)
				{
					options.customFilters = [];
					
					for (const loop_cFilt of apiModelDef.cFilts)
					{
						let loop_cFiltModelName      = null; //Ex "User"
						let loop_cFiltModelFieldName = null; //Ex "firstName"
						
						if (loop_cFilt.m)
						{
							const loop_cFilt_m_parts = loop_cFilt.m.split("|");
							loop_cFiltModelName      = loop_cFilt_m_parts[0];
							loop_cFiltModelFieldName = loop_cFilt_m_parts[1];
						}
						
						options.customFilters.push(new B_REST_CustomFilterDescriptor(loop_cFilt.n,loop_cFiltModelName,loop_cFiltModelFieldName));
					}
				}
				
				return new B_REST_Descriptor(apiModelDef.name, pks, options);
			}
				static _commonDefs_fetch_fromServerBootResponse_factoryOne_add_modelLookupRefFields(apiModelDef, options, apiModelDef_fields, isShared)
				{
					for (const loop_apiModelDef_field of apiModelDef_fields)
					{
						const loop_options = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeFieldCommonOptions(apiModelDef, loop_apiModelDef_field);
						options.modelLookupRefFields.push(new B_REST_FieldDescriptors.ModelLookupRef(loop_apiModelDef_field.n, loop_apiModelDef_field.c, loop_apiModelDef_field.fk, isShared, loop_options));
					}
				}
				static _commonDefs_fetch_fromServerBootResponse_factoryOne_add_fileFields(apiModelDef, options, apiModelDef_fields, isMultiple)
				{
					for (const loop_apiModelDef_field of apiModelDef_fields)
					{
						const loop_options = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeFieldCommonOptions(apiModelDef, loop_apiModelDef_field);
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"cnt"))    { loop_options.maxFileCount      = loop_apiModelDef_field.cnt;    }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"size"))   { loop_options.maxFileSize       = loop_apiModelDef_field.size;   }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"mime"))   { loop_options.allowedTypes      = loop_apiModelDef_field.mime;   }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"soft"))   { loop_options.softDelete        = loop_apiModelDef_field.soft;   }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"resize")) { loop_options.image_resize_func = loop_apiModelDef_field.resize; }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"minW"))   { loop_options.image_minW        = loop_apiModelDef_field.minW;   }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"maxW"))   { loop_options.image_maxW        = loop_apiModelDef_field.maxW;   }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"minH"))   { loop_options.image_minH        = loop_apiModelDef_field.minH;   }
							if (B_REST_Utils.object_hasPropName(loop_apiModelDef_field,"maxH"))   { loop_options.image_maxH        = loop_apiModelDef_field.maxH;   }
						options.fileFields.push(new B_REST_FieldDescriptors.File(loop_apiModelDef_field.n, isMultiple, loop_options));
					}
				}
					static _commonDefs_fetch_fromServerBootResponse_factoryOne_makeLocProp(apiModelDef_field)
					{
						const loc = B_REST_Utils.object_hasPropName(apiModelDef_field,"lx") ? apiModelDef_field.lx : {};
						
						if (B_REST_Utils.object_hasPropName(apiModelDef_field,"l"))  { loc[B_REST_App_base.LOC_KEY_LABEL]       = apiModelDef_field.l;  }
						if (B_REST_Utils.object_hasPropName(apiModelDef_field,"ls")) { loc[B_REST_App_base.LOC_KEY_SHORT_LABEL] = apiModelDef_field.ls; }
						if (B_REST_Utils.object_hasPropName(apiModelDef_field,"le")) { loc[B_REST_App_base.LOC_KEY_ENUM_TAGS]   = apiModelDef_field.le; }
						
						return loc;
					}
					static _commonDefs_fetch_fromServerBootResponse_factoryOne_makeFieldCommonOptions(apiModelDef, apiModelDef_field)
					{
						/*
						NOTE:
							Don't set the locPath option here; let it be auto-handled when we link the field to its descriptor
							otherwise for now it'd be as "models.<ModelName>.fields.<fieldName>.label" but could change later
						*/
						const options = {};
						
						if (B_REST_Utils.object_hasPropName(apiModelDef_field,"flags"))
						{
							const flags = apiModelDef_field.flags.split("|");
							
							if (flags.includes("*"))                                         { options.isRequired    = true;                                        }
							if (flags.includes("null"))                                      { options.isNullable    = true;                                        }
							if (flags.includes("block"))                                     { options.wCustomSetter = true;                                        } //NOTE: For now we don't care about "tweak" case (wCustomSetter_is_tweakVal() vs wCustomSetter_is_blockVal())
							if (flags.includes(B_REST_FieldDescriptors.base.SET_ONCE_NOW))   { options.setOnce       = B_REST_FieldDescriptors.base.SET_ONCE_NOW;   }
							if (flags.includes(B_REST_FieldDescriptors.base.SET_ONCE_LATER)) { options.setOnce       = B_REST_FieldDescriptors.base.SET_ONCE_LATER; }
						}
						
						if (B_REST_Utils.object_hasPropName(apiModelDef_field,"fn"))
						{
							const apiModelDef_field_fn = apiModelDef_field.fn;
							
							for (const loop_shortName in apiModelDef_field_fn)
							{
								const loop_fnLongName = B_REST_Descriptor.COMMON_DEFS_ADD_FROM_SERVER_BOOT_RESPONSE_FACTORY_ONE_MAKE_FIELD_COMMON_OPTIONS_FN_MAP[loop_shortName];
								const loop_fnVal      = apiModelDef_field_fn[loop_shortName];
								switch (loop_fnVal)
								{
									case 1: options[loop_fnLongName]=B_REST_FieldDescriptors.WithFuncs_base.FUNC_AUTO;    break;
									case 2: options[loop_fnLongName]=B_REST_FieldDescriptors.WithFuncs_base.FUNC_MANUAL;  break;
									case 3: options[loop_fnLongName]=B_REST_FieldDescriptors.WithFuncs_base.FUNC_CLOSURE; break;
									default: this._throwEx(`Unknown fn val "${loop_fnVal}" for field "${apiModelDef_field.n}"`);
								}
							}
						}
						
						options.loc = B_REST_Descriptor._commonDefs_fetch_fromServerBootResponse_factoryOne_makeLocProp(apiModelDef_field);
						
						return options;
					}
			static _commonDefs_fetch_fromServerBootResponse_updateLoc(descriptor_new)
			{
				const descriptor_old = this._commonDefs[descriptor_new._name]; //NOTE: No validation; if we get here, we know it matches
				
				for (const loop_fieldName in descriptor_old._allFields)
				{
					const loop_field_old = descriptor_old._allFields[loop_fieldName];
					const loop_field_new = descriptor_new._allFields[loop_fieldName] || B_REST_Descriptor._throwEx(`Couldn't find field "${loop_fieldName}" in new descriptor`,{descriptor_old,descriptor_new});
					
					loop_field_old.updateLoc_fromNewerFieldDescriptor(loop_field_new);
				}
			}
	
	
		
	//LOAD RELATED
		/*
		Options:
			{
				searchOptions:               Instance of B_REST_Model_Load_SearchOptions (check its docs. Can create with B_REST_Model_Load_SearchOptions::commonDefs_make(<ModelName>))
				requiredFields:              Instance of B_REST_Model_Load_RequiredFields, or a string of required pipedFieldNamePaths (check its docs. Can create with B_REST_Model_Load_RequiredFields::commonDefs_make(<ModelName>))
				apiBaseUrl:                  Ex "/brands/". Also accepts complicated things like "/config/regions/{region}/packageTiers/{type}/", as long as we def the path vars in apiBaseUrl_path_vars
				apiBaseUrl_path_vars:        If we must specify extra path vars for the above apiBaseUrl
				apiBaseUrl_needsAccessToken: Check notes in B_REST_Request_base::_needsAccessToken var def. Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
				useCachedShare:              If we want to put these models to B_REST_Model::cachedShare_put(). Bool or string (string should be a pkTag and only used in load_one() & load_one_uniqueKey()). If for PK, can generate multi-pk fields strings ex with B_REST_Descriptor::pkToTag_vals()
				beforeLoad:                  Async hook as (request<B_REST_Request>) (Can be defined in B_REST_Descriptor::hook_load_before too)
				afterLoad:                   Async hook as (response<B_REST_Response>, models<B_REST_Model arr>) (Can be defined in B_REST_Descriptor::hook_load_after too)
				uploadProgressCallback:      Check B_REST_API::call() docs
				downloadProgressCallback:    Check B_REST_API::call() docs
			}
		May throw for various reason (ex connection prob)
		Expects call response struct to be like {items:[], paging:{nbRecords_all,nbRecords_filtered}}
			but we also accept to receive and convert something like {item:{}} to the above
		Rets as:
			{
				models:    Arr of B_REST_Model instances,
				nbRecords: NULL | {all, filtered}, //Check below for more info
			}
		About nb records:
			all:      Total nb of records in DB, against "static" server-filters (ex permissions limiting data access)
			filtered: Out of those we "can" see, nb after we've applied filters in our load options, before paging
			Note that the server also provides with nbRecords_limit, but we don't care about it, since it just gives the nb of records returned
			If we got search options, we'll also note the new nbs (or that we don't know) in its B_REST_Model_Load_SearchOptions::paging_lastCall_updateNbRecords()
		TYPE_MULTILINGUAL_STRING:
			To only load a particular lang, pass a searchOptions where we defined B_REST_Model_Load_SearchOptions::multilingualStrings_isReadonlyModel_limitToLang
		WARNING:
			-Since we can pass random stuff to apiBaseUrl, we can't guarantee that:
				-Applied filters will make sense
				-Ret structure will fit with described fields and sub models
			-> Impacts in B_REST_Descriptor::load_list(), B_REST_Model::awaitUnsavedChangesSaved() & B_REST_ModelList::_load()
			-> We could also do the API calls manually (in case ret struct doesn't match or we need to nest):
				-Just destroy / add items back in modelList by ourselves
				-Check to call paging_lastCall_updateNbRecords()
			-If we wanted to useCachedShare, the final models will have their isInCachedShare props set to true,
				but we can't guarantee they'll always contain all the fields we care about.
		*/
		async load_list(options) { return this._load_x(options,/*isSingle*/false); }
		/*
		Shortcut to using a B_REST_ModelList to ret a single instance
		Accepts the same options as B_REST_Descriptor::load_list() plus:
			throwOnNotFound: <bool>; default true. Else rets NULL when not found
		Usage ex:
			load_one({
				apiBaseUrl:           "/citizens/{pkTag}"
				apiBaseUrl_path_vars: {pkTag:1}
			})
			
			load_one({
				apiBaseUrl:           "/config/regions/{region}/packageTiers/{type}/"
				apiBaseUrl_path_vars: {region:1,type:"brandMembership"}
			})
		Rets as the following, unless it's not found and we don't want to throw (would ret NULL)
			{
				model: NULL | B_REST_Model instance
			}
		TYPE_MULTILINGUAL_STRING:
			To only load a particular lang, pass a searchOptions where we defined B_REST_Model_Load_SearchOptions::multilingualStrings_isReadonlyModel_limitToLang
		NOTES:
			-Also accepts complicated things like "/config/regions/{region}/packageTiers/{type}/"
			-Doesn't throw if it'd ret more than 1 match
			-Use the options to override default of throwing on not found
		*/
		async load_one(options)
		{
			B_REST_Utils.object_assert(options);
			
			//Normalize search options
			{
				if (options.searchOptions) { B_REST_Utils.instance_isOfClass_assert(B_REST_Model_Load_SearchOptions,options.searchOptions); }
				else                       { options.searchOptions = new B_REST_Model_Load_SearchOptions(this);                             }
			}
			
			options.searchOptions.paging_size = 1;
			
			//Just wrap with descriptor's load_list, and since it rets as {models,nbRecords}, change to {model}
			return this._load_x(options,/*isSingle*/true);
		}
			//Shortcut to load_one() + filtering by multiple fields (specifying WHEREs)
			async load_one_uniqueKey(fieldMap, options)
			{
				B_REST_Utils.object_assert(fieldMap);
				
				//Normalize search options
				{
					if (options.searchOptions) { B_REST_Utils.instance_isOfClass_assert(B_REST_Model_Load_SearchOptions,options.searchOptions); }
					else                       { options.searchOptions = new B_REST_Model_Load_SearchOptions(this);                             }
				}
				
				//Add the fieldMap to the search options
				for (const loop_fieldName in fieldMap)
				{
					const loop_fieldDescriptor = this.allFields_find(loop_fieldName); //Throws
					if (!(loop_fieldDescriptor instanceof B_REST_FieldDescriptors.DB)) { this._throwEx(`Can only specify B_REST_FieldDescriptor_DB fields in load_one_uniqueKey()`); }
					
					options.searchOptions.f_equalOrIN(loop_fieldName).valOrArr = fieldMap[loop_fieldName];
				}
				
				return this.load_one(options);
			}
			async _load_x(options, isSingle)
			{
				options = B_REST_Utils.object_hasValidStruct_assert(options, {
					apiBaseUrl:                  {accept:[String],                                       required:true},
					apiBaseUrl_path_vars:        {accept:[null,Object],                                  default:null},
					apiBaseUrl_needsAccessToken: {accept:[null,Boolean],                                 default:null},
					requiredFields:              {accept:[null,B_REST_Model_Load_RequiredFields,String], default:null},
					searchOptions:               {accept:[null,B_REST_Model_Load_SearchOptions],         default:null},
					uploadProgressCallback:      {accept:undefined,                                      default:null},
					downloadProgressCallback:    {accept:undefined,                                      default:null},
					beforeLoad:                  {accept:undefined,                                      default:null},
					afterLoad:                   {accept:undefined,                                      default:null},
					useCachedShare:              {accept:[null,Boolean,String],                          default:false},
					throwOnNotFound:             {accept:[null,Boolean],                                 default:null}, //WARNING: Don't put false
				}, "Descriptor load options");
				
				/*
				If we wanted to play with cache, check if we already have it, no matter the req fields,
				... otherwise we'd have to crawl requiredFields's requiredFields_allFlag, requiredFields & requiredFieldGroups and do model.select_isUsed(fieldNamePath) on them...
				*/
				if (options.useCachedShare && B_REST_Utils.string_is(options.useCachedShare))
				{
					if (!isSingle) { this._throwEx(`Can't have useCachedShare be a string (instead of bool) for load_list(); only w load_one_x()`); }
					
					const pkTag       = options.useCachedShare;
					const cachedModel = B_REST_Model.cachedShare_get(this._name, pkTag);
					if (cachedModel) { return {model:cachedModel}; }
				}
				
				//Build the request
				const request            = new B_REST_App_base.instance.GET(options.apiBaseUrl, options.apiBaseUrl_path_vars);
				request.needsAccessToken = options.apiBaseUrl_needsAccessToken;
				
				//Check to specify which fields we need
				let requiredFields = options.requiredFields; //Might yield undefined
				if (requiredFields)
				{
					//If it's a string, then switch it to an instance of B_REST_Model_Load_RequiredFields with req fields as specified by the string
					if (B_REST_Utils.string_is(requiredFields))
					{
						const pipedFieldNamePaths = requiredFields;
						
						requiredFields = new B_REST_Model_Load_RequiredFields(this);
						requiredFields.requiredFields_addFields(pipedFieldNamePaths);
					}
					//Otherwise it has to already be an instance of B_REST_Model_Load_RequiredFields
					else { B_REST_Utils.instance_isOfClass_assert(B_REST_Model_Load_RequiredFields,requiredFields); }
					
					requiredFields.validateAgainstDescriptor_todo();
					requiredFields.toQSA(request);
				}
				
				//Check to specify filters, order by, paging stuff - only if in list mode
				const searchOptions = options.searchOptions; //Might yield undefined
				if (searchOptions)
				{
					B_REST_Utils.instance_isOfClass_assert(B_REST_Model_Load_SearchOptions, searchOptions);
					searchOptions.validateAgainstDescriptor_todo();
					searchOptions.toQSA(request);
				}
				
				//Check do pimp request, ex to call request.reConstruct() to change whole URL
				try
				{
					if (this._hook_load_before) { await this._hook_load_before(request); }
					if (options.beforeLoad)     { await options.beforeLoad(request);     }
				}
				catch (e) { B_REST_Utils.console_error(`beforeLoad hook failed, for ${this.debugName}: ${e}`); } //WARNING: Could cause prob to switch to throwEx() - check code below
				
				//Call the API; might throw for multiple reasons
				const requestOptions = {};
					if (options.uploadProgressCallback)   { requestOptions.uploadProgressCallback   = options.uploadProgressCallback;   }
					if (options.downloadProgressCallback) { requestOptions.downloadProgressCallback = options.downloadProgressCallback; }
				const response = await B_REST_App_base.instance.call(request, requestOptions); //Might throw
				
				if (response.debug_isDump) { this._throwEx(`Server outputted debug dump, so breaking app`); }
				
				const response_data = response.data;
				if (!response_data) { this._throwEx(`Got no response data`,response); }
				
				B_REST_Utils.console_todo([
					"Maybe eventually we could have options like {retsAsList:true, propName:'items'} to adapt to edge cases",
				]);
				
				let response_data_items = null; //Arr of model's fromObj() from the server
				
				//NOTE: Sometimes, API calls will ret data via {item:{}} and sometimes {items:[{...}]}, so check in both and uniformize
				{
					if      (B_REST_Utils.object_hasPropName(response_data,"item"))  { response_data_items=[response_data.item]; }
					else if (B_REST_Utils.object_hasPropName(response_data,"items")) { response_data_items=response_data.items;  }
					else { this._throwEx(`Expected either {items:[],paging:{}} or {item:{}}, but got something else`,response_data); }
				}
				
				//Check if we can get paging info, and also send them to the passed search options, if any (only in list mode)
				let nbRecords = null;
				if (response_data.paging)
				{
					nbRecords = {
						all:      response_data.paging.nbRecords_all,
						filtered: response_data.paging.nbRecords_filtered,
					};
					
					if (searchOptions) { searchOptions.paging_lastCall_updateNbRecords(nbRecords.filtered,nbRecords.all); }
				}
				else if (searchOptions) { searchOptions.paging_lastCall_updateNbRecords(/*filtered*/null, /*all*/null); }
				
				//Now add all records
				const models = [];
				for (const loop_itemObj of response_data_items)
				{
					B_REST_Utils.object_assert(loop_itemObj);
					
					const loop_model = new B_REST_Model(this);
						loop_model.fromObj(loop_itemObj, B_REST_Descriptor.FROM_OBJ_SKIP_ILLEGALS_LOAD);
						loop_model.unsavedChanges_unflagAllFields({cleanupDeletions:false}); //Do this, otherwise all that we've just set will be marked as having changes to save later
					models.push(loop_model);
				}
				
				/*
				Check if we have to give the user access to the response + models to do final tweaks on fields
				WARNING:
					Since we've already unflagged unsaved changes, if we alter anything more there, we'll have to unflag it ourselves with unsavedChanges_unflag() in fields, etc
				*/
				try
				{
					if (this._hook_load_after) { await this._hook_load_after(response,models); }
					if (options.afterLoad)     { await options.afterLoad(response,models);     }
				}
				catch (e) { B_REST_Utils.console_error(`afterLoad hook failed, for ${this.debugName}: ${e}`); } //WARNING: Could cause prob to switch to throwEx() - check code below
				
				//Check if we should add these to cache
				if (options.useCachedShare)
				{
					for (const loop_model of models) { B_REST_Model.cachedShare_put(loop_model,/*overwriteIfMoreFieldsSet*/B_REST_Descriptor.LOAD_OVERWRITE_CACHE); }
				}
				
				//Then return appropriately, depending on if it's for a single record or list
				{
					if      (!isSingle)                       { return {models};          }
					else if (models.length>0)                 { return {model:models[0]}; }
					else if (options.throwOnNotFound===false) { return null;              }
					this._throwEx(`Couldn't find any record for the specified search options`, options.searchOptions);
				}
			}
	
	
	
	/*
	BATCH API CALLS HELPER
		To run API call on multiple models at the same time, either specifying some B_REST_Model_Load_SearchOptions filters, explicit B_REST_Model instances or just PKs,
			ex to batch send emails, print stuff, change statuses or delete records
		Check related:
			Server's RouteParser_base::genericListFormModule_helper_batchAction() & RouteParser_base::_overridable_genericListFormModule_action_del()
			Frontend's B_REST_Descriptor::batchAPICall_x(), B_REST_Model::commonDefs_batchAPICall_x(), B_REST_ModelList::batchAPICall_x(), B_REST_ModelList::deleteSome(), BrGenericListBase::xActions_helper_batchAction_x(), B_REST_Vuetify_GenericList_Action::defineCommonAction_delete()
		Options:
			{
				apiBaseUrl:                  Ex "/clients/{pkTag}/contracts/{contact_pk}"
				apiBaseUrl_path_vars:        For when apiBaseUrl is like "/clients/{pkTag}/contracts/{contact_pk}" and we need to pass {pkTag,contract_pk}
				apiBaseUrl_needsAccessToken: Check notes in B_REST_Request_base::_needsAccessToken var def. Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
				use_searchOptions:           One of use_x must be set. Instance of B_REST_Model_Load_SearchOptions to filter which records to target (check its docs. Can create with B_REST_Model_Load_SearchOptions::commonDefs_make(<ModelName>))
				use_models:                  One of use_x must be set. Arr of B_REST_Model instances to target
				use_pks:                     One of use_x must be set. Arr of pk tags
				requestBody:                 Either NULL or {} to be passed in POST etc
				expectsContentType:          One of B_REST_Utils.CONTENT_TYPE_x, or check B_REST_Request::expectsContentType_x(). Ex if csv/pdf, will auto download
				isFileDownload:              If true, will issue a call_download() instead of call()
				uploadProgressCallback:      Check B_REST_API::call() docs
				downloadProgressCallback:    Check B_REST_API::call() docs
			}
		Rets:
			If isFileDownload, either as:
				{baseNameWExt, response:<B_REST_Response>}
				{errorMsg, response:<B_REST_Response>}
			Else rets the B_REST_Response, which would normally contain a {stats} prop like:
				{
					allSuccessful: <bool>,
					totalCount:    <int>,
					successes:     {count,pks,labels},
					failures:      {count,pks,labels},
					failureTags: {
						<perms|exception|notFound|<customTag>>: {count,pks,labels}
					},
					firstException: <null|string>,
				}
		-> For usage cases, check BrGenericListBase::xActions_helper_batchAction_x() docs
		NOTE:
			-If {stats} is ret, then B_REST_Response::isSuccessful. Otherwise, means we got a server error / down etc and will have no {stats} at all
			-We have all these verbs in B_REST_API: GET | GET_File | POST | POST_File | POST_Multipart | PUT | PUT_Multipart | PATCH | PATCH_Multipart | DELETE
	*/
		async batchAPICall_GET(         options) { return this._batchAPICall_x("GET",         options); }
		async batchAPICall_POST(        options) { return this._batchAPICall_x("POST",        options); }
		async batchAPICall_PUT(         options) { return this._batchAPICall_x("PUT",         options); }
		async batchAPICall_PATCH(       options) { return this._batchAPICall_x("PATCH",       options); }
		async batchAPICall_DELETE(      options) { return this._batchAPICall_x("DELETE",      options); }
		async batchAPICall_GET_File_csv(options) { return this._batchAPICall_x("GET_File",{...options,isFileDownload:true,expectsContentType:B_REST_Utils.CONTENT_TYPE_CSV}); }
			async _batchAPICall_x(method, options)
			{
				options = B_REST_Utils.object_hasValidStruct_assert(options, {
					apiBaseUrl:                  {accept:[String],                               required:true},
					apiBaseUrl_path_vars:        {accept:[null,Object],                          default:null},
					apiBaseUrl_needsAccessToken: {accept:[null,Boolean],                         default:null},
					use_searchOptions:           {accept:[null,B_REST_Model_Load_SearchOptions], default:null},
					use_models:                  {accept:[null,Array],                           default:null},
					use_pks:                     {accept:[null,Array],                           default:null},
					requestBody:                 {accept:[null,Object],                          default:null},
					expectsContentType:          {accept:[String],                               default:B_REST_Utils.CONTENT_TYPE_JSON},
					isFileDownload:              {accept:[Boolean],                              default:false},
					uploadProgressCallback:      {accept:undefined,                              default:null},
					downloadProgressCallback:    {accept:undefined,                              default:null},
				}, "Descriptor _batchAPICall_x options");
				
				const request = new B_REST_App_base.instance[method](options.apiBaseUrl, options.apiBaseUrl_path_vars);
				request.needsAccessToken   = options.apiBaseUrl_needsAccessToken;
				request.expectsContentType = options.expectsContentType;
				if (options.requestBody!==null) { request.data=options.requestBody; }
				
				const requestOptions = {}; //NOTE: Not used for B_REST_App_base::call_download() yet
				if (options.uploadProgressCallback)   { requestOptions.uploadProgressCallback   = options.uploadProgressCallback;   }
				if (options.downloadProgressCallback) { requestOptions.downloadProgressCallback = options.downloadProgressCallback; }
				
				if      (options.use_searchOptions) { options.use_searchOptions.toQSA(request); }
				else if (options.use_models)
				{
					B_REST_Utils.array_isOfClassInstances_assert(B_REST_Model, options.use_models);
					const pks = options.use_models.map(loop_model => loop_model.pk);
					request.qsa_add(B_REST_Descriptor.BATCH_API_CALL_QSA_PKS, pks);
				}
				else if (options.use_pks) { request.qsa_add(B_REST_Descriptor.BATCH_API_CALL_QSA_PKS,options.use_pks); }
				else { this._throwEx(`Didn't know what to do w options`,options); }
				
				if (options.isFileDownload) { return B_REST_App_base.instance.call_download(request); }  //Might throw
				
				let response = null;
				try       { response = await B_REST_App_base.instance.call(request,requestOptions); }
				catch (e) { B_REST_Utils.console_error(`Request couldn't be done: ${e}`,request);   }
				
				if (response?.debug_isDump) { this._throwEx(`Server outputted debug dump, so breaking app`); }
				return response;
			}
	
	
	
	//LOC TABLE RELATED
	/*
	Ex if we have:
		ActivitySector {pk<int>, name<string>, subSectors[<ActivitySector>], loc[<ActivitySector_Loc>]}
		ActivitySector_Loc {activitySector_fk<int>, lang<string>, shortName<string>, longName<string>, desc<string>}
	Then we have a loc table, and its field names are shortName, longName & desc
	WARNING: Will break if we create standalone descriptors w/o adding them to commonDefs_add(), as we won't be able to reach the loc one in locTable_descriptor()
	*/
	get locTable_has() { return this._hasLocTable; }
	//Against the ex above, would yield "ActivitySector_Loc". Throws if we have no loc table
	get locTable_modelName()
	{
		if (!this._hasLocTable) { this._throwEx(`Can only do that on a model w loc table`); }
		
		return this._subModelListFields[B_REST_Descriptor.LOC_TABLE_PARENT_FIELDNAME].modelClassName;
	}
	//Rets the loc table's descriptor. Check warning above. Throws if we have no loc table
	get locTable_descriptor() { return B_REST_Descriptor.commonDefs_get(this.locTable_modelName); }
	get locTable_fieldNames()
	{
		const fieldNames = [];
		
		const locTable_dbFields = this.locTable_descriptor._dbFields;
		for (const loop_fieldName in locTable_dbFields)
		{
			const loop_fieldDescriptor = locTable_dbFields[loop_fieldName];
			
			fieldNames.push(loop_fieldDescriptor.name);
		}
		
		return fieldNames;
	}
};
