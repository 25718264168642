<template>
	<br-generic-list-base :derived-component="_self">
		<template #item.pk="{ rowInfo, colInfo, modelField }">
			<span>{{ rowInfo.model.pk }}</span>
		</template>
		
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericList, B_REST_Vuetify_Prompt } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "clientPaymentList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			modelName: "ClientPayment",
			fromLoader: {
				apiBaseUrl: "/clientPayments",
			},
			cols: {
				pk: {
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				franchisee: {
					fieldNamePaths: "franchisee.name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				clientSessionContract_fk: {
					fieldNamePaths: "clientSessionContract_fk",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				amount: {
					fieldNamePaths: "amount",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				due_dt: {
					fieldNamePaths: "due_dt",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				type: {
					fieldNamePaths: "type",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				status: {
					fieldNamePaths: "status",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				paid_dt: {
					fieldNamePaths: "paid_dt",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					payNow: {
						mustConfirm: true,
						click: {
							isEnabled(action,model)
							{
								const status = model.select("status").val;
								return [this.$bREST.consts.clientPayment_status.UPCOMING,this.$bREST.consts.clientPayment_status.OVERDUE].includes(status);
							},
							async hook(action,model,isCtrlClickOrMiddleClick)
							{
								try
								{
									const request  = new this.$bREST.POST("clientPayments/{pkTag}/pay", {pkTag:model.pk});
									const response = await this.$bREST.call(request);
									
									if (response.data.translatedErrorMsg) { this.$bREST.notifs_tmp({msg:response.data.translatedErrorMsg,color:"error"}); }
									else
									{
										const msg = this.t("rowActions.payNow.success", {referenceNum:response.data.paymentGateway.referenceNum});
										this.$bREST.notifs_tmp({msg,color:"success"});
									}
								}
								catch (e) { this.$bREST.notifs_tmp({msg:this.t("rowActions.payNow.failure"),color:"error"}); }
								
								this.reloadList();
							},
						},
						icon: "mdi-credit-card-outline",
					},
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>