<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "promoCodeList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./PromoCodeForm.vue"),
			modelName: "PromoCode",
			fromLoader: {
				apiBaseUrl: "/promoCodes/",
			},
			cols: {
				created_dt: {
					fieldNamePaths: "created_dt",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				updated_dt: {
					fieldNamePaths: "updated_dt",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				code: {
					fieldNamePaths: "code",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				percent: {
					fieldNamePaths: "percent",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				d_from: {
					fieldNamePaths: "d_from",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				d_to: {
					fieldNamePaths: "d_to",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				franchisees: {
					fieldNamePaths: "franchisees",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model) { return model.select("franchisees").val; },
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add()
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit(),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				created_dt: {fieldNamePath:"created_dt"},
				updated_dt: {fieldNamePath:"updated_dt"},
				code: {fieldNamePath:"code"},
				d_from: {fieldNamePath:"d_from"},
				d_to: {fieldNamePath:"d_to"},
				franchisees: {fieldNamePath:"franchisees", picker:"franchiseeList"},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>