<template>
	<v-main class="main-layout">
		<v-app-bar app clipped-left height="64px" color="primary" dark> <!-- Put dark, so icons are easier to see on imgs -->
			<template #img="{ props:imgProps }">
				<v-img src="/_businessFiles/logo-text-only.svg" class="mt-4" style="height:32px" v-bind="imgProps" />
			</template>
			
			<!-- Hamburger -->
			<v-app-bar-nav-icon @click="showLeftDrawer = !showLeftDrawer" />
			
			<v-btn v-if="$bREST.shouldShowBackNavBtn" @click="$bREST.routes_go_back(false)" icon><v-icon>mdi-keyboard-backspace</v-icon></v-btn>
			
			<!-- Check https://vuetifyjs.com/en/components/app-bars/#v-app-bar-title about shrink-on-scroll -->
			<v-toolbar-title>
				<v-icon v-if="pageIcon" v-text="pageIcon" />
			</v-toolbar-title> 
			
			<v-spacer /> <!-- Puts up to 300px(?) of space between title and btns to the right -->
			
			<!-- Want to debug $bREST.currentSessionInfo_dt_now_YmdHis ? Check currentSessionInfo_debug_show below -->
			
			<v-icon v-if="$bREST.user_isSudoing" color="warning">mdi-incognito</v-icon>
		</v-app-bar>
		
		<left-drawer v-model="showLeftDrawer" @input="on_showLeftDrawer_change()" />
			
		<div class="fill-height my-0 mx-auto" :style="{maxWidth:pageShouldRestrainWidth?'1000px':null}">
			<!-- v-xxx-transition. Could improve with https://vuetifyjs.com/en/styles/transitions/#create-your-own -->
			<component :is="routeTransition_componentName" mode="out-in" origin="">
				<router-view />
			</component>
		</div>
		
		<p v-if="$bREST.currentSessionInfo_debug_show" class="text-caption ma-2" style="position:fixed;right:24px;top:5px;z-index:1000;width:300px;" v-html="$bREST.currentSessionInfo_debug_html" />
	</v-main>
</template>

<script>
	
	import {VFadeTransition, VScrollXTransition, VScrollXReverseTransition} from "vuetify/lib";
	
	const DONT_RESTRAIN_WIDTH_ROUTE_NAMES = ["regFlow-normal", "regFlow-trial", "regFlow-makeUp", "calendar_presentialManagement", "calendar_mySchedule"];
	const LS_KEY_DRAWER_DISPLAY_PREF = "leftDrawerDisplayPref";
	
	
	
	export default {
		components: {
			LeftDrawer: () => import("./LeftDrawer.vue"),
			//For router transition
			VFadeTransition,
			VScrollXTransition,
			VScrollXReverseTransition,
		},
		data()
		{
			return {
				//If NULL = hidden by default on mobile / We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
				showLeftDrawer: this.$bREST.utils.localStorage_get(LS_KEY_DRAWER_DISPLAY_PREF,/*throwIfNull*/false) ?? !this.$bREST.uiBreakpoint.mobile,
			};
		},
		computed: {
			routeTransition_componentName()
			{
				if      (this.$bREST.routes_current_travelDir_isToChild)  { return "v-scroll-x-reverse-transition"; }
				else if (this.$bREST.routes_current_travelDir_isToParent) { return "v-scroll-x-transition";         }
				return "v-fade-transition";
			},
			pageLabel()
			{
				const locPath = this.$bREST.routes_current_def?.meta?.locPath ?? null;
				return locPath ? this.$bREST.t_custom(locPath) : this.$bREST.businessConfig.businessName;
			},
			pageIcon() { return this.$bREST.routes_current_def?.meta?.icon ?? null; },
			pageShouldRestrainWidth() { return !DONT_RESTRAIN_WIDTH_ROUTE_NAMES.includes(this.$bREST.routes_current_name); },
		},
		methods: {
			on_showLeftDrawer_change() { this.$bREST.utils.localStorage_set(LS_KEY_DRAWER_DISPLAY_PREF,this.showLeftDrawer,/*isPersistent*/true); },
		},
	};
	
</script>

<style scoped>
	
	.v-image:deep(.v-image__image) {
		background-size: 150px;
	}
	
</style>