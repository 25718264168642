<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "franchiseeList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./FranchiseeForm.vue"),
			modelName: "Franchisee",
			fromLoader: {
				apiBaseUrl: "/franchisees/",
			},
			cols: {
				name: {
					fieldNamePaths: "name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				contactEmail: {
					fieldNamePaths: "contactEmail",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				phone: {
					fieldNamePaths: "phone",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add(),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				calc_flatSearch: {fieldNamePath:"calc_flatSearch"},
				contactEmail: {fieldNamePath:"contactEmail"},
				phone: {fieldNamePath:"phone"},
				postalCode: {fieldNamePath:"postalCode"},
				hqFranchisee_is: {fieldNamePath:"hqFranchisee_is"},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>