<!--
	Recurring props that we could implements by default:
		outlined x3
		text-decoration-underline x2
		
		text-caption x4
		text-body-2 x2
		
		small x1
		large x2
		x-large x5
		
		text x4
		tile x1
-->
<template>
	<v-btn rounded :color="bgColor" :outlined="outlined" :text="text" class="py-4 px-6 text-none" :class="textColor" v-bind="$attrs" @click.stop.prevent="$emit('click',$event)">
		
		<template v-if="iconName">
			<v-badge v-if="badgeArgs" overlap v-bind="badgeArgs"> <v-icon        :color="iconColor" class="mr-1">{{ iconName }}</v-icon> </v-badge>
			                                                      <v-icon v-else :color="iconColor" class="mr-1">{{ iconName }}</v-icon>
		</template>
		
		<span><slot default /></span>
	</v-btn>
</template>

<script>
	
	import { B_REST_Utils } from "@/bREST/core/classes";
	
	
	
	export default {
		props: {
			color:     {type:String, required:true,  validator:validator_piped},                //As "<bgColor>|<textColor>",  ex "grey darken-2|white" -> "grey darken-2 white--text". If textColor is white, can ommit "...|white"
			icon:      {type:String, required:false, validator:validator_piped, default:null},  //As "<iconName>|<iconColor>", ex "mdi-account|red lighten-1". If iconColor is white, can ommit "...|white"
			outlined:  {type:null,   required:false,                            default:false}, //If true, bg will become transparent and will take the text's color
			text:      {type:null,   required:false,                            default:false}, //If true, bg will become transparent
			badgeArgs: {type:Object, required:false,                            default:null},  //Ex {value:true, content:123, color:"red"}
		},
		computed: {
			bgColor()
			{
				const color = this.color.split("|")[0];
				if (this.outlined!==false || this.text!==false)
				{
					if (color!=="transparent") { this.$bREST.throwEx(`Must be transparent when using outlined or text`); }
					return null; //Otherwise ex when using outlined, text and outline will become this color, instead of textColor
				}
				return color;
			},
			iconName()  { return this.icon ? this.icon.split("|")[0] : null; },
			textColor() { return `${this._xColor("color")}--text`;           },
			iconColor() { return    this._xColor("icon");                    },
		},
		methods: {
			_xColor(varName)
			{
				const parts = this[varName].split("|");
				return parts.length===2 ? parts[1] : "white";
			},
		},
	};
	
	
	
	
	
	
	function validator_piped(val)
	{
		const parts = val.split("|");
		if (parts.length<=2) { return true; }
		B_REST_Utils.throwEx(`Expected 2 parts like "success lighten-1|white" / "mdi-account|red lighten-1", or if text color is white, just "success lighten-1" / "mdi-account". Got '${val}'`);
	}
	
</script>