
import { B_REST_Utils } from "@/bREST/core/classes";
import Calendar from "./Calendar.js";
import CalendarEventClient from "./CalendarEventClient.js";
import { default as MyApp_Class } from "@/custom/App.js";
const MyApp = MyApp_Class.instance;



export default class CalendarEvent
{
	static get API_CALL_LIST_OCCURRENCES_ALT_INFO_NOT_LOADED_TAG() { return "<skipped>"; } //Server's RouteParser_Calendar::LIST_OCCURRENCES_ALT_INFO_NOT_LOADED_TAG & frontend's CalendarEvent::API_CALL_LIST_OCCURRENCES_ALT_INFO_NOT_LOADED_TAG must match
	
	static get CONFIG_PROGRAM_STATS_FIELDNAMES() { return ["musculation","flexibility","intensity","cardio","movement"]; }
	
	//These consts must match w the same in server's RouteParser_Calendar. Check there for docs
	static get REG_FLOW_ACTION_STATE_NORMAL_ADD_DISABLED_BEFORE_PRIORITY_REG()     { return "normal_add_disabled_beforePriorityReg";     }
	static get REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_PAST()                       { return "any_add_disabled_past";                     }
	static get REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_CANCELED()                   { return "any_add_disabled_canceled";                 }
	static get REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_FULL()                       { return "any_add_disabled_full";                     }
	static get REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_MEMBERSHIP_SELECTION_COUNT() { return "any_add_disabled_membershipSelectionCount"; }
	static get REG_FLOW_ACTION_STATE_MAKEUP_TRIAL_ADD_DISABLED_NO_TOKENS()         { return "makeUp_trial_add_disabled_noTokens";        }
	static get REG_FLOW_ACTION_STATE_MAKEUP_TRIAL_ADD_DISABLED_TOO_EARLY()         { return "makeUp_trial_add_disabled_tooEarly";        }
	static get REG_FLOW_ACTION_STATE_MAKEUP_TRIAL_ADD_DISABLED_ANY_REGGED()        { return "makeUp_trial_add_disabled_anyRegged";       }
	static get REG_FLOW_ACTION_STATE_ANY_ADD_CAN()                                 { return "any_add_can";                               }
	static get REG_FLOW_ACTION_STATE_ANY_ADD_PENDING()                             { return "any_add_pending";                           }
	static get REG_FLOW_ACTION_STATE_NORMAL_REM_DISABLED_LAST_OCCURRENCE_DONE()    { return "normal_rem_disabled_lastOccurrenceDone";    }
	static get REG_FLOW_ACTION_STATE_NORMAL_REM_CAN()                              { return "normal_rem_can";                            }
	static get REG_FLOW_ACTION_STATE_NORMAL_REM_PENDING()                          { return "normal_rem_pending";                        }
	
	//These consts must match w the same in server's RouteParser_Calendar. Check there for docs
	static get MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_OCCURRENCE_CANCELED() { return "any_miss_disabled_occurrenceCanceled"; }
	static get MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_TOO_EARLY()           { return "any_miss_disabled_tooEarly";           }
	static get MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_CAN()                          { return "any_miss_can";                         }
	static get MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_MISSED_APPROVED()     { return "any_miss_disabled_missed_approved";    }
	static get MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_MISSED_CHOKED()       { return "any_miss_disabled_missed_choked";      }
	static get MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_PAST()                { return "any_miss_disabled_past";               }
	
	//These consts must match w the same in server's Model_Event
	static get ALT_INFO_KEY_MEETING_POINT() { return "m"; }
	static get ALT_INFO_KEY_STAFF_FK()      { return "s"; }
	static get OCCURRENCES_CANCELED_ALL()   { return "*"; }
	
	static get DEFAULT_EVENT_DURATION() { return 30; }
	
	
	
	//Info that are the same for all occurrences of a given Model_Event
	_pk                  = null;  //The PK of the Model_Event, not the actual <occurrenceIdx> we care about. If in management and we're creating a new one, would be "*"
	_type                = null;  //A Model_Event::TYPE_x
	_dt_from             = null;  //Date instances. For weekly templates, will point to a dummy date in Calendar::WEEKLY_TEMPLATE_MODE_DUMMY_DATES. NOTE: Only frontend works w dummy dates; server won't know what to do w these and work w weekdays in weekly template mode
	_dt_to               = null;  //Same as the above
	_franchisee          = null;  //Ptr to a Model_Franchisee in the franchiseeList shared list
	_configProgram       = null;  //Ptr to a Model_ConfigProgram in the configProgramList shared list
	_park                = null;  //As {pk,name,address,postalCode,meetingPoint,configCity}, where meetingPoint can change via Model_Event::occurrences_altInfo
	_staff               = null;  //Optional obj as {pk,firstName,lastName}. We have a staff_fk it in the event itself, but it can change in Model_Event::occurrences_altInfo. Could still be undetermined yet
	//Calc vars to speed up getters
	_calc_t_from_Hi = null;
	_calc_t_to_Hi   = null;
	_calc_date_Ymd  = null;
	//Live info that vary, but that we don't hold an history. WARNING: From server's Model_CurrentSessionInfo::dt_now, so if not updated frequently / if we don't reload page often, will be wrong
	_live_occurrences_remaining       = null;  //For the program, not the client
	_live_placeCount_normal_max       = null;
	_live_placeCount_normal_remaining = null;
	//Info that could change from one occurrence to the other
	_occurrence_isCanceled           = null;   //If given occurrence is canceled, via Model_Event::occurrences_canceled
	_occurrence_altInfo_has          = false;
	_occurrence_altInfo_meetingPoint = null;   //Received multilingual but stored here in MyApp.locale_lang
	_occurrence_altInfo_staff        = null;   //Same struct as _staff
	//Things that vary per occurrence - info related to this occurrence
	_occurrenceIdx      = null;
	_participationType  = null;  //A Model_EventClient::OCCURRENCE_TYPE_x, unless we're staff (NULL)
	_participationState = null;  //Check Model_EventClient::occurrences_states docs
	_placeCounts        = null;  //Map of {normal,trial,makeUp}, where each is as {remaining,max}
		//NOTE: No need to keep arr of participants w attendance statuses here, because we'll only need that when we open EventForm.vue and not CalendarEventClientView.vue
	//If we're in a Calendar::isRegFlow purpose, either to do adds or rems. Depending on if we're in a Calendar::isWeeklyTemplate or not, either targets the full future or just occurrence X
	_regFlow_actionState = null; //One of REG_FLOW_ACTION_STATE_x
	//If we're in a Calendar::isMySchedule as a client, and mostly we can only request to miss a class, usually to get a makeUp token. We can't book new occurrences in this mode
	_mySchedule_clientActionState   = null;  //One of MY_SCHEDULE_CLIENT_ACTION_STATE_x
	_mySchedule_clientMissed_reason = null;  //When MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_CAN, MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_MISSED_APPROVED & MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_MISSED_CHOKED
	//If we're in a Calendar::isMySchedule as staff
	_mySchedule_staffParticipants = null; //Arr of CalendarEventClient instances
	//If we're in presential management as staff
	_weeklyTemplate_isVisibleToClients = null;
	
	
	
	//Depending on Calendar::PURPOSE_x, we'll receive some of these, but never all of them at the same time
	constructor(eventObj, lookups)
	{
		eventObj = B_REST_Utils.object_hasValidStruct_assert(eventObj, {
			//Common info - Check server's RouteParser_Calendar::_x_list_runQuery_getEventsAndLookups() docs
			pk:                               {accept:[Number,String],      required:true},
			type:                             {accept:[String],             required:true},
			franchisee_fk:                    {accept:[Number],             required:true},
			configProgram_fk:                 {accept:[Number,null],        default:null},
			franchiseePark_fk:                {accept:[Number,null],        default:null},
			staff_fk:                         {accept:[Number,null],        default:null},
			placeCounts:                      {accept:[Object,null],        default:null}, //Map of {normal,trial,makeUp}, where each is as {remaining,max} - Even though we have lots of live_placeCount_x around here... IMPORTANT: Don't put req, or management won't work
			live_occurrences_remaining:       {accept:[Number,null],        default:null},
			live_placeCount_normal_max:       {accept:[Number,null],        default:null},
			live_placeCount_normal_remaining: {accept:[Number,null],        default:null},
			participationType:                {accept:[String,null],        default:null},
			participationState:               {accept:[String,null],        default:null},
			occurrences_altInfo:              {accept:[String,Object,null], default:null}, //Either NULL, the full obj or API_CALL_LIST_OCCURRENCES_ALT_INFO_NOT_LOADED_TAG
			occurrences_canceled:             {accept:[String,Array,null],  default:null}, //Either NULL, OCCURRENCES_CANCELED_ALL or arr of canceled occurrenceIdx list
			//Custom stuff - Check server's RouteParser_Calendar::_x_list_runQuery_getEventsAndLookups() docs
			weeklyTemplate:                   {accept:[Object,null],        default:null},
			occurrence:                       {accept:[Object,null],        default:null},
			regFlow_actionState:              {accept:[String,null],        default:null},
			mySchedule_clientActionState:     {accept:[String,null],        default:null},
			mySchedule_clientMissed_reason:   {accept:[String,null],        default:null},
		}, "CalendarEvent");
		
		lookups = B_REST_Utils.object_hasValidStruct_assert(lookups, {
			parks:  {accept:[Object], required:true},
			staffs: {accept:[Object], required:true},
		}, "CalendarEvent_lookups");
		
		this._pk                               = eventObj.pk;
		this._type                             = eventObj.type;
		this._franchisee                       = MyApp.sharedLists_getSrc("unfilteredFranchiseeList").get_byPK(eventObj.franchisee_fk);
		this._configProgram                    = eventObj.configProgram_fk  ? MyApp.sharedLists_getSrc("configProgramList").get_byPK(eventObj.configProgram_fk) : null;
		this._park                             = eventObj.franchiseePark_fk ? lookups.parks[eventObj.franchiseePark_fk]                                         : null;
		this._staff                            = eventObj.staff_fk          ? lookups.staffs[eventObj.staff_fk]                                                 : null;
		this._placeCounts                      = eventObj.placeCounts;
		this._live_occurrences_remaining       = eventObj.live_occurrences_remaining;
		this._live_placeCount_normal_max       = eventObj.live_placeCount_normal_max;
		this._live_placeCount_normal_remaining = eventObj.live_placeCount_normal_remaining;
		this._participationType                = eventObj.participationType;
		this._participationState               = eventObj.participationState;
		this._regFlow_actionState              = eventObj.regFlow_actionState;
		this._mySchedule_clientActionState     = eventObj.mySchedule_clientActionState;
		this._mySchedule_clientMissed_reason   = eventObj.mySchedule_clientMissed_reason;
		
		if (eventObj.weeklyTemplate)
		{
			const eventWeeklyTemplateObj = B_REST_Utils.object_hasValidStruct_assert(eventObj.weeklyTemplate, {
				weekday:            {accept:[Number],      required:true},
				t_from:             {accept:[String],      required:true},
				t_to:               {accept:[String,null], required:true},
				isVisibleToClients: {accept:[Boolean],     required:true},
				//IMPORTANT: If we add more props here, will need to add in Calendar.vue::viewTypeWeekOrDay_onFreeTimeClick() too
			}, "CalendarEvent_weeklyTemplate");
			
			const date   = Calendar.WEEKLY_TEMPLATE_MODE_DUMMY_DATES[eventWeeklyTemplateObj.weekday].Ymd;
			const t_from = eventWeeklyTemplateObj.t_from;
			const t_to   = eventWeeklyTemplateObj.t_to;
			
			this._dt_from = B_REST_Utils.dt_fromYmdHis(`${date} ${t_from}`);
			this._dt_to   = t_to!==null ? B_REST_Utils.dt_fromYmdHis(`${date} ${t_to}`) : B_REST_Utils.dt_deltaMinutes(this._dt_from,CalendarEvent.DEFAULT_EVENT_DURATION);
			
			this._weeklyTemplate_isVisibleToClients = eventWeeklyTemplateObj.isVisibleToClients;
		}
		
		const occurrences_canceled = eventObj.occurrences_canceled;
		
		if (eventObj.occurrence)
		{
			const eventOccurrenceObj = B_REST_Utils.object_hasValidStruct_assert(eventObj.occurrence, {
				dt_from:       {accept:[String], required:true},
				dt_to:         {accept:[String], required:true},
				occurrenceIdx: {accept:[Number], required:true},
			}, "CalendarEvent_occurrence");
			
			this._dt_from       = B_REST_Utils.dt_fromYmdHis(eventOccurrenceObj.dt_from);
			this._dt_to         = B_REST_Utils.dt_fromYmdHis(eventOccurrenceObj.dt_to);
			this._occurrenceIdx = eventOccurrenceObj.occurrenceIdx;
			
			this._occurrence_isCanceled = occurrences_canceled===CalendarEvent.OCCURRENCES_CANCELED_ALL || (B_REST_Utils.array_is(occurrences_canceled)&&occurrences_canceled.includes(this._occurrenceIdx));
		}
		else { this._occurrence_isCanceled = occurrences_canceled===CalendarEvent.OCCURRENCES_CANCELED_ALL; }
		
		//For now, we don't care about alt info unless we have a occurrenceIdx
		if (eventObj.occurrences_altInfo && eventObj.occurrences_altInfo!==CalendarEvent.API_CALL_LIST_OCCURRENCES_ALT_INFO_NOT_LOADED_TAG && this._occurrenceIdx!==null)
		{
			if (B_REST_Utils.object_hasPropName(eventObj.occurrences_altInfo,this._occurrenceIdx))
			{
				const occurrence_altInfo          = eventObj.occurrences_altInfo[this._occurrenceIdx];
				const occurrence_altInfo_staff_fk = occurrence_altInfo[CalendarEvent.ALT_INFO_KEY_STAFF_FK] ?? null;
				
				this._occurrence_altInfo_has          = true;
				this._occurrence_altInfo_meetingPoint = occurrence_altInfo[CalendarEvent.ALT_INFO_KEY_MEETING_POINT]?.[MyApp.locale_lang] ?? null;
				this._occurrence_altInfo_staff        = occurrence_altInfo_staff_fk ? lookups.staffs[occurrence_altInfo_staff_fk]          : null;
			}
		}
		
		//Speed up some recurring getters
		{
			this._calc_t_from_Hi = B_REST_Utils.dt_toHi(this._dt_from);
			this._calc_t_to_Hi   = B_REST_Utils.dt_toHi(this._dt_to);
			this._calc_date_Ymd  = B_REST_Utils.dt_toYmd(this._dt_from);
			
			/*
			NOTE:
				Initially also made the following: _calc_isPast, _calc_isFuture & _calc_timePartsUntil
				But the prob is that even if MyApp::currentSessionInfo_dt_now was being updated via setInterval, these wouldn't update anymore and UX sucked
			*/
		}
	}
	
	
	
	get pk()                                     { return this._pk;                                                                                          }
	get type()                                   { return this._type;                                                                                        }
	get type_isPresential()                      { return this._type===MyApp.consts.event_type.PRESENTIAL;                                                   }
	get type_isVirtual()                         { return this._type===MyApp.consts.event_type.VIRTUAL;                                                      }
	get type_isPrivateGroup()                    { return this._type===MyApp.consts.event_type.PRIVATE_GROUP;                                                }
	get type_isSpecialEvent()                    { return this._type===MyApp.consts.event_type.SPECIAL_EVENT;                                                }
	get dt_from()                                { return this._dt_from;                                                                                     }
	get dt_to()                                  { return this._dt_to;                                                                                       }
	get t_from_Hi()                              { return this._calc_t_from_Hi;                                                                              }
	get t_to_Hi()                                { return this._calc_t_to_Hi;                                                                                }
	get date_Ymd()                               { return this._calc_date_Ymd;                                                                               }
	get isPast()                                 { return this._dt_to<MyApp.currentSessionInfo_dt_now;                                                       } //IMPORTANT: Don't make a _calc_; check constructor docs for why
	get isFuture()                               { return MyApp.currentSessionInfo_dt_now<this._dt_from;                                                     } //IMPORTANT: Don't make a _calc_; check constructor docs for why
	get staff_canPunchCameNoShow()               { return B_REST_Utils.dt_minutesDiff(this._dt_from,MyApp.currentSessionInfo_dt_now) >= -Calendar.EVENT_STAFF_PUNCH_CAME_NO_SHOW_MINS_BEFORE_START; } //IMPORTANT: Don't make a _calc_; check constructor docs for why
	get timePartsUntil()                         { return B_REST_Utils.dt_timePartsBetween(MyApp.currentSessionInfo_dt_now,this._dt_from,/*allowNeg*/false); } //Send flipped so that "now" appears in the past; so don't use dt_now_timePartsBetween(). IMPORTANT: Don't make a _calc_; check constructor docs for why
	get weekday()                                { return this._dt_from.getDay();                                                                            } //0=Sunday. NOTE: Only frontend works w dummy dates; server won't know what to do w these and work w weekdays in weekly template mode
	get weekdayLabel()                           { return Calendar.t_common(`weekdays.${this.weekday}.long`);                                                }
	get live_occurrences_remaining()             { return this._live_occurrences_remaining;                                                                  }
	get live_placeCount_normal_max()             { return this._live_placeCount_normal_max;                                                                  }
	get live_placeCount_normal_remaining()       { return this._live_placeCount_normal_remaining;                                                            }
	get occurrenceIdx()                          { return this._occurrenceIdx;                                                                               }
	get occurrenceTag()                          { return this._occurrenceIdx!==null ? `${this._pk}-${this._occurrenceIdx}` : this._pk;                      }
	get participationType()                      { return this._participationType;                                                                           }
	get participationType_isNormal()             { return this._participationType===MyApp.consts.eventClient_occurrenceType.NORMAL;                          }
	get participationType_isTrial()              { return this._participationType===MyApp.consts.eventClient_occurrenceType.TRIAL;                           }
	get participationType_isMakeUp()             { return this._participationType===MyApp.consts.eventClient_occurrenceType.MAKEUP;                          }
	get participationType_isFlex()               { return this._participationType===MyApp.consts.eventClient_occurrenceType.FLEX;                            }
	get participationState()                     { return this._participationState;                                                                          }
	get participationState_isNotRegged()         { return this._participationState===MyApp.consts.eventClient_occurrenceState.NOT_REGGED;                    }
	get participationState_isPendingReg()        { return this._participationState===MyApp.consts.eventClient_occurrenceState.PENDING_REG;                   }
	get participationState_isCame()              { return this._participationState===MyApp.consts.eventClient_occurrenceState.CAME;                          }
	get participationState_isValidCancelation()  { return this._participationState===MyApp.consts.eventClient_occurrenceState.VALID_CANCELATION;             }
	get participationState_isNoShow()            { return this._participationState===MyApp.consts.eventClient_occurrenceState.NO_SHOW;                       }
	get placeCounts()                            { return this._placeCounts;                                                                                 }
	
	set regFlow_actionState(val)                                          { this._regFlow_actionState=val;    }
	get regFlow_actionState()                                             { return this._regFlow_actionState; }
	get regFlow_actionState_isNormal_add_disabled_beforePriorityReg()     { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_NORMAL_ADD_DISABLED_BEFORE_PRIORITY_REG;     }
	get regFlow_actionState_isAny_add_disabled_past()                     { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_PAST;                       }
	get regFlow_actionState_isAny_add_disabled_canceled()                 { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_CANCELED;                   }
	get regFlow_actionState_isAny_add_disabled_full()                     { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_FULL;                       }
	get regFlow_actionState_isAny_add_disabled_membershipSelectionCount() { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_ANY_ADD_DISABLED_MEMBERSHIP_SELECTION_COUNT; }
	get regFlow_actionState_isMakeUp_trial_add_disabled_noTokens()        { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_MAKEUP_TRIAL_ADD_DISABLED_NO_TOKENS;         }
	get regFlow_actionState_isMakeUp_trial_add_disabled_tooEarly()        { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_MAKEUP_TRIAL_ADD_DISABLED_TOO_EARLY;         }
	get regFlow_actionState_isMakeUp_trial_add_disabled_anyRegged()       { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_MAKEUP_TRIAL_ADD_DISABLED_ANY_REGGED;        }
	get regFlow_actionState_isAny_add_can()                               { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_ANY_ADD_CAN;                                 }
	get regFlow_actionState_isAny_add_pending()                           { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_ANY_ADD_PENDING;                             }
	get regFlow_actionState_isNormal_rem_disabled_lastOccurrenceDone()    { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_NORMAL_REM_DISABLED_LAST_OCCURRENCE_DONE;    }
	get regFlow_actionState_isNormal_rem_can()                            { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_NORMAL_REM_CAN;                              }
	get regFlow_actionState_isNormal_rem_pending()                        { return this._regFlow_actionState===CalendarEvent.REG_FLOW_ACTION_STATE_NORMAL_REM_PENDING;                          }
	
	set mySchedule_clientActionState(val)                                     { this._mySchedule_clientActionState=val;    }
	get mySchedule_clientActionState()                                        { return this._mySchedule_clientActionState; }
	get mySchedule_clientActionState_isAny_miss_disabled_occurrenceCanceled() { return this._mySchedule_clientActionState===CalendarEvent.MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_OCCURRENCE_CANCELED; }
	get mySchedule_clientActionState_isAny_miss_disabled_tooEarly()           { return this._mySchedule_clientActionState===CalendarEvent.MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_TOO_EARLY;           }
	get mySchedule_clientActionState_isAny_miss_can()                         { return this._mySchedule_clientActionState===CalendarEvent.MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_CAN;                          }
	get mySchedule_clientActionState_isAny_miss_disabled_missed_approved()    { return this._mySchedule_clientActionState===CalendarEvent.MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_MISSED_APPROVED;     }
	get mySchedule_clientActionState_isAny_miss_disabled_missed_choked()      { return this._mySchedule_clientActionState===CalendarEvent.MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_MISSED_CHOKED;       }
	get mySchedule_clientActionState_isAny_miss_disabled_past()               { return this._mySchedule_clientActionState===CalendarEvent.MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_DISABLED_PAST;                }
	
	get mySchedule_clientMissed_reason()    { return this._mySchedule_clientMissed_reason; }
	set mySchedule_clientMissed_reason(val) { this._mySchedule_clientMissed_reason=val;    }
	
	get mySchedule_staffParticipants()    { return this._mySchedule_staffParticipants; }
	set mySchedule_staffParticipants(val) { this._mySchedule_staffParticipants=val;    }
	//Used by CalendarEventStaffView::participants_onStateChange() when giving back token to makeUp/trial participant
	mySchedule_staffParticipants_dropParticipant(calendarEventClient)
	{
		B_REST_Utils.instance_isOfClass_assert(CalendarEventClient, calendarEventClient);
		B_REST_Utils.array_remove_byVal(this._mySchedule_staffParticipants, calendarEventClient);
	}
	
	get configProgram_pk()             { return this._configProgram ? this._configProgram.pk                                                                 : null; }
	get configProgram_name()           { return this._configProgram ? this._configProgram.select("name").val_currentLang                                     : null; }
	get configProgram_logoUrl()        { return this._configProgram ? this._configProgram.select("logo" ).ifSingle_apiUrl_wDomainName_resizedVersionOrNormal : null; }
	get configProgram_bgImgUrl()       { return this._configProgram ? this._configProgram.select("bgImg").ifSingle_apiUrl_wDomainName_resizedVersionOrNormal : null; }
	get configProgram_catchphrase()    { return this._configProgram ? this._configProgram.select("catchphrase").val_currentLang                              : null; }
	get configProgram_name_wDuration() { return MyApp.businessConfig_eventTitle_appendDuration ? `${this.configProgram_name??""} ${this.duration}` : this.configProgram_name; }
	//For these, we ret the field itself, so we can do .val, .enum_label, .label etc, without having to create 100 getters
		get configProgram_stats_musculation() { return this._configProgram?.select("stats_musculation") ?? null; }
		get configProgram_stats_flexibility() { return this._configProgram?.select("stats_flexibility") ?? null; }
		get configProgram_stats_intensity()   { return this._configProgram?.select("stats_intensity")   ?? null; }
		get configProgram_stats_cardio()      { return this._configProgram?.select("stats_cardio")      ?? null; }
		get configProgram_stats_movement()    { return this._configProgram?.select("stats_movement")    ?? null; }
	
	//NOTE: Req field so will never be NULL
	get franchisee_pk()           { return this._franchisee.pk;                         }
	get franchisee_phone()        { return this._franchisee.select("phone").val;        }
	get franchisee_contactEmail() { return this._franchisee.select("contactEmail").val; }
	
	get park_pk()              { return this._park?.pk                                ?? null; }
	get park_name()            { return this._park?.name                              ?? null; }
	get park_address()         { return this._park?.address                           ?? null; }
	get park_configCity_name() { return this._park?.configCity?.name                  ?? null; }
	get park_postalCode()      { return this._park?.postalCode                        ?? null; }
	get park_meetingPoint()    { return this._park?.meetingPoint?.[MyApp.locale_lang] ?? null; }
	
	//Could be NULL if undetermined
	get staff_pk()   { return this._staff ? this._staff.pk                                     : null; }
	get staff_name() { return this._staff ? `${this._staff.firstName} ${this._staff.lastName}` : null; }
	
	get duration() { return B_REST_Utils.dt_minutesDiff(this._dt_from,this._dt_to); }
	
	//Per-occurrence alt info
	get occurrence_isCanceled()           { return this._occurrence_isCanceled;           }
	get occurrence_altInfo_has()          { return this._occurrence_altInfo_has;          }
	get occurrence_altInfo_meetingPoint() { return this._occurrence_altInfo_meetingPoint; }
	get occurrence_altInfo_staff_pk()     { return this._occurrence_altInfo_staff ? this._occurrence_altInfo_staff.pk                                                        : null; }
	get occurrence_altInfo_staff_name()   { return this._occurrence_altInfo_staff ? `${this._occurrence_altInfo_staff.firstName} ${this._occurrence_altInfo_staff.lastName}` : null; }
	
	get weeklyTemplate_isVisibleToClients() { return this._weeklyTemplate_isVisibleToClients; }
	
	//Exports this instance to a format <v-calendar> will understand and that we can use
	toVCalendarEvent()
	{
		//NOTE: Vuetify also has other props like "name", but we override the #event slot so we don't need it
		
		return {
			start:    B_REST_Utils.dt_toYmdHis(this._dt_from),
			end:      B_REST_Utils.dt_toYmdHis(this._dt_to),
			instance: this,
		};
	}
};
