<!--
	Usage ex (MyList.vue):
		*** Check the IMPORTANT msg
		Template:
			<br-generic-list-base :derived-component="_self">
				<template #quick-add-form="{ model }">
					<br-field-db :model="model" field="user.firstName" />
				</template>
				
				<template #item.firstName="{ rowInfo, colInfo, modelField, cellDefaultContent }">
					<div>Yea: {{ modelField.val }}</div>
					<div>Or:  {{ cellDefaultContent }}</div>
				</template>
				
				<template #uiFilter.created_dt="{ uiFiFilter }">
					<div>
						<br-field-db :field="uiFilter.modelField_x" no-label />
						<br-field-db :field="uiFilter.modelField_y" no-label />
					</div>
				</template>
			</br-generic-list-base>
		Script:
			import { B_REST_Vuetify_GenericListBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/list/BrGenericListBase.vue";

IMPORTANT: For all props, check B_REST_Vuetify_GenericListBase_createMixin() docs below

			export default {
				name: "TestLeadList",
				mixins: B_REST_Vuetify_GenericListBase_createMixin({
					modelName: "Lead",
					icon: "mdi-account",
					fromLoader: {
						apiBaseUrl: "/leads/",
					},
					cols: {
						"firstName":     {fieldNamePaths:"user.firstName",    style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
						"lastName":      {fieldNamePaths:"user.lastName",     style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
						"recoveryEmail": {fieldNamePaths:"user.recoveryEmail",style:{fromBreakpoint:"sm", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
						"created_dt":    {fieldNamePaths:"created_dt",        style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
						"lastLogin_dt":  {fieldNamePaths:"user.lastLogin_dt", style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
						"isActive":      {fieldNamePaths:"isActive",          style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
						"calc_nbSent": {
							fieldNamePaths:"calc_nbSent",
							style: {
								fromBreakpoint:"lg",
								align: B_REST_Vuetify_GenericList_Col.ALIGN_RIGHT,
								hasRightBorder,
								width,
								tdClass: (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model), //Will go into a <td :class> NOTE: We have this.$bREST.classProp_addTag() helper
								tdStyle: (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model), //Will go into a <td :style> NOTE: We have this.$bREST.styleProp_addTag() helper
							},
							toCellContent(col,model) { return model.select("calc_nbSent").val>0 ? "Oui" : "Non"; },
							click: {
								isEnabled: bool | (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model)
								hook:      async(<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model,isCtrlClickOrMiddleClick), //Must ret bool
							},
							isEditable: bool | (<B_REST_Vuetify_GenericList_Col>col,<B_REST_Model>model),
							vBind:     {},
							vOn:       {},
							extraData: {},
						},
							WARNING: Most of things in B_REST_Vuetify_GenericList_Col are ignored if we define <template #data-table-area> in our list implementation (unless we manually put a <br-generic-list-base-data-table :list-component="_self" /> inside)
					},
IMPORTANT: For all props, check B_REST_Vuetify_GenericListBase_createMixin() docs below
					globalActions: {
						...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({
							isEnabled(action,selectedModels=null) { return this.$bREST.roles_isAnyRoleAdmin; },
						}),
						dropAll: {
							click: {
								isEnabled: bool | string (disabled reason tag w getMsg_disabledReasonTag() loc) | (<B_REST_Vuetify_GenericList_GlobalAction>action,<B_REST_Model>selectedModels=[]),
								async hook(action,selectedModels) {},
							},
							icon: "mdi-close",
							selectionType: B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N,
						},
					},
IMPORTANT: For all props, check B_REST_Vuetify_GenericListBase_createMixin() docs below
					row: {
						checkbox: {isEnabled:true},
						actions: {
							...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({
								isEnabled(action,model) { return this.$bREST.roles_isAnyRoleAdmin; },
							}),
							...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_delete({}),
							sudo: {
								click: {
									async hook(action,model) { this.$bREST.sudoIn(model.select("user.sudoHash").data); },
									isEnabled: bool | string (disabled reason tag w getMsg_disabledReasonTag() loc) | (<B_REST_Vuetify_GenericList_RowAction>action,<B_REST_Model>model),
								},
								isEnabled(action,model)
								{
									const self_idUser     = this.$bREST.user_pk;
									const target_idUser   = model.select("user").pk;
									const target_sudoHash = model.select("user.sudoHash").data;
									
									return target_sudoHash && self_idUser!==target_idUser;
								},
								icon: "mdi-key-arrow-right",
							},
						},
						click: {
							hook:      async(model<B_REST_Model>,isCtrlClickOrMiddleClick)
							isEnabled: bool | (model<B_REST_Model>)
						},
						style: {
							trClass: (model<B_REST_Model>),
							trStyle: (model<B_REST_Model>),
						},
					},
IMPORTANT: For all props, check B_REST_Vuetify_GenericListBase_createMixin() docs below
					filters: {
						regions:            {fieldNamePath:"regions", op:"eq_in", multiple:true},
						created_dt:         {fieldNamePath:"created_dt", },
						campaign_fk:        {fieldNamePath:"campaign_fk", multiple:true, brFieldDbProps:{items:"campaignList"}},
										or:	{fieldNamePath:"campaign_fk", multiple:true, items:"campaignList"},
						currency:           {fieldNamePath:"currency", multiple:true, brFieldDbProps:{items:"currencyList"}},
										or:	{fieldNamePath:"currency", multiple:true, items:"currencyList"},
						calc_likeSearch:    {fieldNamePath:"calc_likeSearch"},
						coords_address:     {fieldNamePath:"coords_address"},
						dues_referring_fk:  {fieldNamePath:"dues_referring_fk", multiple:true, brFieldDbProps:{picker:"consultantList"}},
										or:	{fieldNamePath:"dues_referring_fk", multiple:true, picker:"consultantList"},
						isProfileCompleted: {fieldNamePath:"isProfileCompleted", brFieldDbProps:{as:"select"}},
										or:	{fieldNamePath:"isProfileCompleted", as:"select"},
						gender:             {fieldNamePath:"user.gender"},
					},
IMPORTANT: For all props, check B_REST_Vuetify_GenericListBase_createMixin() docs below
					quickAddForm: {
						async hook(modelList, model)
						{
							const savedSomething = await model.awaitUnsavedChangesSaved({...});
							modelList.prepend(model);
							return true;
						},
						mustConfirm:   true,
						displayResult: true,
					},
				}),
			};
		Usage:
			<my-list :from-model-list="modelList" />
	IMPORTANT:
		Check B_REST_VueApp_base::_routes_define_genericListFormModule() for the transferComponentProps_forRouteInfo() prop, that passes down the req fromRouteInfo when we load the list directly
-->

<template>
	<v-card v-if="!derivedComponent.openFormInVDialog_show" :loading="derivedComponent.final_isLoading" :disabled="derivedComponent.final_isDisabled" :elevation="derivedComponent.elevation" class="br-generic-list" :class="derivedComponent.cssClassBase" width="100%">
		
		<v-card-title class="text-h5" v-if="derivedComponent.showTitle">
			<v-icon v-if="derivedComponent.icon" v-text="derivedComponent.icon" />{{ derivedComponent.title }}
		</v-card-title>
		
		<v-card-text>
			
			<!-- Quick add form, either directly in the list, or in a popup -->
				<slot name="quick-add-form--before" />
				<v-card v-if="derivedComponent.quickAddForm_uses_inline" color="accent" dark class="mb-4" elevation="2" @keyup.enter="derivedComponent.on_quickAddForm_submit()">
					<v-card-title v-text="derivedComponent.quickAddForm_title" />
					<v-card-text> <slot name="quick-add-form" v-bind="{model:derivedComponent.quickAddForm_model}" /> </v-card-text>
					<v-card-actions>
						<v-spacer /> <v-btn @click.stop.prevent="derivedComponent.on_quickAddForm_submit()" v-text="derivedComponent.t_alt('quickAddForm.submit.normal')" />
					</v-card-actions>
				</v-card>
				<v-dialog v-else-if="derivedComponent.quickAddForm_uses_dialog" :value="derivedComponent.quickAddForm_dialogOpenState" :persistent="false" @input="derivedComponent.quickAddForm_dialogOpenState=false">
					<v-card class="pb-2" @keyup.enter="derivedComponent.on_quickAddForm_submit()">
						<v-card-title v-text="derivedComponent.quickAddForm_title" />
						<v-card-text> <slot name="quick-add-form" v-bind="{model:derivedComponent.quickAddForm_model}" /> </v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn @click.stop.prevent="derivedComponent.quickAddForm_dialogOpenState=false" v-text="derivedComponent.t_alt('quickAddForm.closeDialog')"   color="error" text />
							<v-btn @click.stop.prevent="derivedComponent.on_quickAddForm_submit()"           v-text="derivedComponent.t_alt('quickAddForm.submit.picker')" color="secondary"  />
						</v-card-actions>
					</v-card>
				</v-dialog>
				<slot name="quick-add-form--after" />
			
			<!-- Toolbar OUTSIDE the <v-data-table> -->
			<slot name="toolbar--before" />
			<v-toolbar v-if="needsToolbar" flat class="mb-1">
				<!-- ctrlF3 NOTE: Appears in the list + filters panel -->
					<v-text-field v-if="derivedComponent.final_ctrlF3_show" v-model="derivedComponent.ctrlF3_val" :label="derivedComponent.t_alt('ctrlF3.label')" clearable append-icon="mdi-magnify" />
					<v-divider    v-if="derivedComponent.final_ctrlF3_show" class="mx-4" inset vertical />
					<v-spacer/>
				<!-- Quick add form btn, if we don't want it inline -->
					<v-btn v-if="derivedComponent.quickAddForm_uses_dialog" class="ml-2" @click.stop.prevent="derivedComponent.quickAddForm_dialogOpenState=true">
						<span class="hidden-sm-and-down" v-text="derivedComponent.quickAddForm_title" />
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				<!-- Filters -->
					<v-btn v-if="derivedComponent.uiFilters_uses" color="secondary" @click.stop.prevent="derivedComponent.uiFilters_togglePanel()" class="ml-2">
						<v-icon v-if="derivedComponent.uiFilters_visible_anySet" color="success" class="mr-2">mdi-check</v-icon>
						<span class="hidden-sm-and-down" v-text="derivedComponent.t_alt('filters.label')" />
						<v-icon>mdi-filter-menu</v-icon>
					</v-btn>
				<!-- Global actions -->
					<template v-if="derivedComponent.globalActions_uses">
						<v-menu v-if="derivedComponent.final_globalActions_show_grouped" offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2">
									<span class="hidden-sm-and-down" v-text="derivedComponent.t_alt('globalActions.label')" />
									<v-icon>mdi-menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-tooltip v-for="(loop_globalActionInfo, loop_idx) in derivedComponent.final_globalActionInfoList" :key="loop_idx" bottom :disabled="loop_globalActionInfo.disabledTooltip===null">
									{{ loop_globalActionInfo.disabledTooltip }}
									<template #activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on"> <!-- NOTE: Div needed for when item is disabled, otherwise tooltip won't appear -->
											<v-list-item :disabled="loop_globalActionInfo.isClickable!==true"
											             @click.stop.prevent="loop_globalActionInfo.isClickable===true ? derivedComponent.on_globalAction_click(loop_globalActionInfo.action,$event.ctrlKey) : null"
											             @mousedown.middle="  loop_globalActionInfo.isClickable===true ? derivedComponent.on_globalAction_click(loop_globalActionInfo.action,true)           : null"
											>
												<v-list-item-icon>
													<v-icon v-text="loop_globalActionInfo.action.icon" :color="loop_globalActionInfo.isClickable===true ? loop_globalActionInfo.action.color : null" />
												</v-list-item-icon>
												<v-list-item-content> <v-list-item-title v-text="loop_globalActionInfo.action.label" /> </v-list-item-content>
											</v-list-item>
										</div>
									</template>
								</v-tooltip>
							</v-list>
						</v-menu>
						<template v-else>
							<v-tooltip v-for="(loop_globalActionInfo, loop_idx) in derivedComponent.final_globalActionInfoList" :key="loop_idx" bottom :disabled="loop_globalActionInfo.disabledTooltip===null">
								{{ loop_globalActionInfo.disabledTooltip }}
								<template #activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on"> <!-- NOTE: Span needed for when btn is disabled, otherwise tooltip won't appear -->
										<v-btn :disabled="loop_globalActionInfo.isClickable!==true"
										       v-bind="loop_globalActionInfo.action.getBtnAttrs(loop_globalActionInfo.isClickable)"
										       @click.stop.prevent="loop_globalActionInfo.isClickable===true ? derivedComponent.on_globalAction_click(loop_globalActionInfo.action,$event.ctrlKey) : null"
										       @mousedown.middle="  loop_globalActionInfo.isClickable===true ? derivedComponent.on_globalAction_click(loop_globalActionInfo.action,true)           : null"
										       class="ml-2"
										>
											<span class="hidden-sm-and-down" v-text="loop_globalActionInfo.action.label" />
											<v-icon v-text="loop_globalActionInfo.action.icon" :color="loop_globalActionInfo.isClickable===true ? loop_globalActionInfo.action.color : null" />
										</v-btn>
									</span>
								</template>
							</v-tooltip>
						</template>
					</template>
			</v-toolbar>
			<slot name="toolbar--after" />
			
			<!--
				Here is where we should put the data table, but we could override to put other things in the way
				WARNING: Most of things in B_REST_Vuetify_GenericList_Col are ignored if we define <template #data-table-area> in our list implementation (unless we manually put a <br-generic-list-base-data-table :list-component="_self" /> inside)
			-->
				<slot name="data-table-area--before" />
				<slot name="data-table-area" v-bind="{modelList:derivedComponent.modelList}">
					<br-generic-list-base-data-table :list-component="derivedComponent">
						<template v-for="(loop_col,loop_idx) in definedSlots_colTD" #[loop_col.vDataTableSlotName]="{ rowInfo, colInfo, modelField, cellDefaultContent }">
							<slot :name="loop_col.vDataTableSlotName" v-bind="{ rowInfo, colInfo, modelField, cellDefaultContent }" />
						</template>
					</br-generic-list-base-data-table>
				</slot>
					<!--
						Slot that could be used to show ex a balance in an invoice list, using something like {{ modelList.lastLoad_coreInjection_customData?.balance ?? "-" }},
						and in server's route _overridable_genericListFormModule_tweakOutput() w output_json_injectCore_customData()
					-->
						<slot name="data-table-area--after" />
			
			<!-- For multiple pickers -->
				<slot name="picker-selection--before" />
				<v-card v-if="derivedComponent.final_isPicker_isMultiple && derivedComponent.selectedItems_has" color="accent" dark class="mt-4" elevation="2">
					<v-card-text>
						<!-- NOTE: Don't wrap in a <v-chip-group>, or they'll become selectable -->
						<v-chip v-for="(loop_selectedItem,loop_idx) in derivedComponent.selectedItems" :key="loop_idx" outlined class="ma-1" close @click:close="derivedComponent.on_multiplePickerFooter_removeChip(loop_selectedItem)">
							{{ loop_selectedItem.rowInfo.model.toLabel(derivedComponent.quotedName) }}
						</v-chip>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn :disabled="!derivedComponent.selectedItems_has" @click.stop.prevent="derivedComponent.on_picker_submit()" v-text="derivedComponent.t_alt('picker.submitLabel')" />
					</v-card-actions>
				</v-card>
				<slot name="picker-selection--after" />
			
			<!-- Especially for global & row actions req confirmation -->
				<br-prompt v-if="derivedComponent.xPrompt" :instance="derivedComponent.xPrompt" />
			
		</v-card-text>
		
		<!--
			NOTE: Decided that pickers cancel btn be always at the end of the form, even if we're in multiple mode and made selections appear in the "accent" sub-card
			-> Should have slots around v-card-actions, but not sure where they should be (inside vs around)
		-->
			<v-card-actions v-if="derivedComponent.pickerHandle">
				<v-row justify="end" class="mr-0 mb-1">
					<v-col cols="auto"> <v-btn @click.stop.prevent="derivedComponent.on_picker_cancel()" v-text="derivedComponent.t_alt('picker.cancelLabel')" text color="error" /> </v-col>
				</v-row>
			</v-card-actions>
		
		<!--
			If we have filters to display, either display them inline, or "teleport" them in the <BrRightDrawer>
			WARNING: If we alter theme here, should check to also alter how it works in <BrRightDrawer>
		-->
			<v-navigation-drawer v-if="derivedComponent.uiFilters_uses_inline" v-model="derivedComponent.uiFilters_inlinePanelOpenState" absolute right temporary dark disable-resize-watcher :width="derivedComponent.uiFilters_inlineWidth">
				<v-toolbar flat color="primary" dark>
					<v-btn icon @click.stop.prevent="derivedComponent.uiFilters_inlinePanelOpenState=false"><v-icon>mdi-close</v-icon></v-btn>
					<v-toolbar-title>{{ derivedComponent.t_alt('filters.label') }}</v-toolbar-title>
				</v-toolbar>
				<br-generic-list-base-filters :list-component="derivedComponent" :show-title="false" :card-color="null" :card-theme="null" :filters-theme="null" class="mb-2">
					<template v-for="(loop_uiFilter,loop_idx) in definedSlots_uiFilters" #[loop_uiFilter.slotName]>
						<slot :name="loop_uiFilter.slotName" v-bind="{ uiFilter:loop_uiFilter }" />
					</template>
				</br-generic-list-base-filters>
			</v-navigation-drawer>
			<br-right-drawer-content v-else-if="derivedComponent.uiFilters_uses_external && derivedComponent.uiFilters_rightDrawerContent" :content="derivedComponent.uiFilters_rightDrawerContent">
				<br-generic-list-base-filters :list-component="derivedComponent" :show-title="true" :card-color="null" :card-theme="null" :filters-theme="null">
					<template v-for="(loop_uiFilter,loop_idx) in definedSlots_uiFilters" #[loop_uiFilter.slotName]>
						<slot :name="loop_uiFilter.slotName" v-bind="{ uiFilter:loop_uiFilter }" />
					</template>
				</br-generic-list-base-filters>
			</br-right-drawer-content>
	</v-card>
	
	<!-- When we use openFormInVDialog() to display its BrGenericFormBase der in a <v-dialog>. For now, we decided we should hide the list when we do so -->
	<v-dialog v-else :value="true" @input="derivedComponent.openFormInVDialog_cancel()" :max-width="derivedComponent.openFormInVDialog_maxWidth" :fullscreen="derivedComponent.openFormInVDialog_isFullscreen">
		<component :is="derivedComponent.formComponent" v-bind="derivedComponent.openFormInVDialog_vBind" @close="derivedComponent.openFormInVDialog_cancel()" />
	</v-dialog>
</template>

<script>
	
	import { B_REST_Utils, B_REST_Model, B_REST_ModelList, B_REST_ModelFields, B_REST_Model_Load_SearchOptions, B_REST_Model_Load_SearchOptions_Filters } from "../../../../../classes";
	import B_REST_VueApp_base                                                                 from "../../../B_REST_VueApp_base.js";
	import B_REST_VueApp_CreateCoreMixin                                                      from "../../../B_REST_VueApp_CreateCoreMixin.js";
	import B_REST_Vuetify_GenericList_Col                                                     from "./B_REST_Vuetify_GenericList_Col.js";
	import { B_REST_Vuetify_GenericList_GlobalAction, B_REST_Vuetify_GenericList_RowAction }  from "./B_REST_Vuetify_GenericList_Action.js";
	import B_REST_Vuetify_GenericList_Filter                                                  from "./B_REST_Vuetify_GenericList_Filter.js";
	import { B_REST_Vuetify_RightDrawerContent, DESKTOP_WIDTH as RIGHT_DRAWER_DESKTOP_WIDTH } from "../../rightDrawer/BrRightDrawer.vue";
	import B_REST_Vuetify_PickerHandle                                                        from "../../picker/B_REST_Vuetify_PickerHandle.js";
	import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action }                            from "../../prompt/B_REST_Vuetify_Prompt.js";
	
	export { B_REST_Vuetify_GenericList_Col, B_REST_Vuetify_GenericList_GlobalAction, B_REST_Vuetify_GenericList_RowAction };
	
	export const FILTER_OPS = B_REST_Model_Load_SearchOptions_Filters.base;
	
	export const GENERIC_LIST_CONSTS = {
		ROW_ACTIONS_COL_NAME:                             "_actions_",
		ROW_ACTIONS_BTN_REQ_SIZE:                         60, //NOTE: If we change that, will maybe have impacts in B_REST_Vuetify_GenericList_Action_base::ROW_ACTIONS_BTN_REQ_SIZE
		ROW_ACTIONS_INCLUDE_DISABLED:                     true,
		X_ACTION_MODELS_STRING_MAX_LABEL_COUNT:           5,   //For on_xAction_click()
		GLOBAL_ACTIONS_INCLUDE_DISABLED:                  true,
		RIGHT_DRAWER_OPEN_SIDE_BREAKPOINTS:               ["lg","xl"],
		RIGHT_DRAWER_ALWAYS_CLOSE_AFTER:                  false, //If false, will depend on if it's opened "aboveAll"
		RIGHT_DRAWER_ACTION_TAGS_APPLY:                   "apply",
		RIGHT_DRAWER_ACTION_TAGS_RESET:                   "reset",
		RIGHT_DRAWER_ACTION_TAGS_CANCEL:                  "cancel",
		RELOAD_ON_RESET_FILTERS:                          true,
		PAGING_SIZE_ALL:                                  -1,           //WARNING: Our B_REST_Model_Load_SearchOptions.PAGING_SIZE_ALL is NULL, but Vuetify's -1
		PAGING_SIZE_DEFAULT_CHOICES:                      [5,10,30,50], //We need to add the B_REST_Model_Load_SearchOptions's val too, or we'll have a prob
		PAGING_IGNORE:                                    -1,           //Check final_server_nbRecords()'s docs
		PAGING_UNKNOWN_SERVER_NB_RECORDS_TMP_FALLBACK:    1000,         //Check final_server_nbRecords()'s docs
		NEVER_SWITCH_TO_MOBILE_REPRESENTATION_BREAKPOINT: 0,            //We tell that the orientation of the data table shouldn't flip to vertical, until the width of the viewport is <0px
		FROM_LOADER_BOOT_STATES_NOT_YET_BOOTING:          'notYetBooting',
		FROM_LOADER_BOOT_STATES_BOOTING:                  'booting',
		FROM_LOADER_BOOT_STATES_BOOTED:                   'booted',
		CLASS_ROW_TO_REM_OR_TO_DEL:                       'br-generic-list--toRemoveOrDelete',
		SLOT_NAME_QUICK_ADD_FORM:                         'quick-add-form',
		SLOT_NAME_DATA_TABLE_AREA:                        'data-table-area',
		OPEN_FORM_IN_V_DIALOG_DEFAULT_MAX_WIDTH:          1000,
		HELPER_BATCH_ACTION_DOWNLOADABLE_CONTENT_TYPES:   [B_REST_Utils.CONTENT_TYPE_CSV,B_REST_Utils.CONTENT_TYPE_IMAGE,B_REST_Utils.CONTENT_TYPE_PDF],
	};
	
	const CORE_ALT_BASE_LOC_PATH = "app.components.BrGenericListBase";
	
	
	
	export default {
		props: {
			derivedComponent: {type:Object, required:true}, //Derived component instance using this base component
		},
		data()
		{
			return {
				GENERIC_LIST_CONSTS,
			};
		},
		//Detect hell
		created()
		{
			let anyColDefiningCellContentSlot_name = null;
			
			for (const loop_col of this.derivedComponent.cols)
			{
				const loop_hasValidFieldDescriptor = loop_col.fieldDescriptor_isDB || loop_col.useToLabel;
				const loop_definesSlot             = !!this.$scopedSlots[loop_col.vDataTableSlotName];
				const loop_definesCellContent      = !!loop_col.toCellContent;
				
				if (loop_definesCellContent && !anyColDefiningCellContentSlot_name) { anyColDefiningCellContentSlot_name=loop_col.name; }
				
				if (!loop_hasValidFieldDescriptor && !loop_definesSlot && !loop_definesCellContent)
				{
					const loop_errorMsg = `Col ${loop_col.name} requires defining a <template #${loop_col.vDataTableSlotName}> because it's not exactly bound to 1 B_REST_FieldDescriptor_DB or <toLabel> (B_REST_FieldDescriptor_ModelLookupRef or B_REST_FieldDescriptor_SubModel) and doesn't define a toCellContent() callback`;
					
					if (this.definedSlots_dataTableArea) { B_REST_Utils.console_warn(`${loop_errorMsg} (if we define a <template #data-table-area> that implements <br-generic-list-base-data-table>)`); }
					else                                 { this.derivedComponent.throwEx(loop_errorMsg); }
				}
			}
			
			if (anyColDefiningCellContentSlot_name && this.definedSlots_dataTableArea)
			{
				B_REST_Utils.console_warn(`${this.derivedComponent.quotedName}'s "${anyColDefiningCellContentSlot_name}" col's toCellContent() will likely be ignored because we define the <template #data-table-area> slot (unless we manually put a <br-generic-list-base-data-table :list-component="_self" /> inside)`);
			}
			
			if      ( this.derivedComponent.quickAddForm_use && !this.definedSlots_quickAddForm) { this.derivedComponent.throwEx(`Received quickAddForm:{} stuff in options, but not defining <template #${GENERIC_LIST_CONSTS.SLOT_NAME_QUICK_ADD_FORM}>`);  }
			else if (!this.derivedComponent.quickAddForm_use &&  this.definedSlots_quickAddForm) { this.derivedComponent.throwEx(`Defining <template #${GENERIC_LIST_CONSTS.SLOT_NAME_QUICK_ADD_FORM}> but didn't receive quickAddForm:{} stuff in options`); }
		},
		computed: {
			/*
			Mandatory if quickAddForm_use
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #quick-add-form="{ model }">
						<br-field-db :model="model" field="user.firstName" />
					</template>
				</br-generic-list-base>
			*/
			definedSlots_quickAddForm() { return !!this.$scopedSlots[GENERIC_LIST_CONSTS.SLOT_NAME_QUICK_ADD_FORM]; },
			/*
			Slot underneath the toolbar, where a <br-generic-list-base-data-table> should go
			Use that if we want to put other things before, after, or toggle between display styles (ex show a google map instead)
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #data-table-area>
						<br-generic-list-base-data-table :list-component="_self">
							<template #item.firstName="{ rowInfo, colInfo, modelField, cellDefaultContent }">
								<div>Yea: {{ modelField.val }}</div>
								<div>Or:  {{ cellDefaultContent }}</div>
							</template>
						</br-generic-list-base-data-table>
					</template>
					<template #uiFilter.created_dt="{ uiFilter }">
				</br-generic-list-base>
			WARNING:
				Careful that filter slots go out of <template #data-table-area>
				Most of things in B_REST_Vuetify_GenericList_Col are ignored if we define <template #data-table-area> in our list implementation (unless we manually put a <br-generic-list-base-data-table :list-component="_self" /> inside)
			*/
			definedSlots_dataTableArea() { return !!this.$scopedSlots[GENERIC_LIST_CONSTS.SLOT_NAME_DATA_TABLE_AREA]; },
			/*
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #item.firstName="{ rowInfo, colInfo, modelField, cellDefaultContent }">
						<div>Yea: {{ modelField.val }}</div>
						<div>Or:  {{ cellDefaultContent }}</div>
					</template>
				</br-generic-list-base>
			*/
			definedSlots_colTD()
			{
				const cols = [];
				
				for (const loop_col of this.derivedComponent.cols)
				{
					if (!!this.$scopedSlots[loop_col.vDataTableSlotName]) { cols.push(loop_col); }
				}
				
				return cols;
			},
			/*
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #uiFilter.created_dt="{ uiFilter }">
						<div>
							<br-field-db :field="uiFilter.modelField_x" no-label />
							<br-field-db :field="uiFilter.modelField_y" no-label />
						</div>
					</template>
				</br-generic-list-base>
			*/
			definedSlots_uiFilters()
			{
				const uiFilters = [];
				
				for (const loop_uiFilter of this.derivedComponent.uiFilters)
				{
					if (!!this.$scopedSlots[loop_uiFilter.slotName]) { uiFilters.push(loop_uiFilter); }
				}
				
				return uiFilters;
			},
			needsToolbar() { return this.derivedComponent.final_ctrlF3_show || this.derivedComponent.quickAddForm_uses_dialog || this.derivedComponent.uiFilters_uses || this.derivedComponent.globalActions_uses; },
		},
		methods: {
			col_definesTDSlot(col) { return !!this.$scopedSlots[col.vDataTableSlotName]; },
		},
	};
	
	
	
	
	/*
	Options as:
		{
			icon:          string,
			cols:          {<colName>:<B_REST_Vuetify_GenericList_Col constructor options - check docs>},
								WARNING: Most of things in B_REST_Vuetify_GenericList_Col are ignored if we define <template #data-table-area> in our list implementation (unless we manually put a <br-generic-list-base-data-table :list-component="_self" /> inside)
			globalActions: {<actionName>:<B_REST_Vuetify_GenericList_GlobalAction constructor options - check docs>],
			row: {
				actions: {<actionName>:<B_REST_Vuetify_GenericList_RowAction constructor options - check docs>],
				click: {
					isEnabled: bool | (model<B_REST_Model>)
					hook:      async(model<B_REST_Model>,isCtrlClickOrMiddleClick), //Must ret bool
				},
				checkbox: {
					isEnabled: bool | (model<B_REST_Model>),
				},
				style: {
					trClass: (model<B_REST_Model>), //NOTE: We have this.$bREST.classProp_addTag() helper
					trStyle: (model<B_REST_Model>), //NOTE: We have this.$bREST.styleProp_addTag() helper
				},
			},
			ctrlF3: {
				hook: (model<B_REST_Model>,nonEmptySearchString),
			},
			filters: {<filterName>:<B_REST_Vuetify_GenericList_Filter constructor options - check docs>},
			quickAddForm: {
				hook:          async(modelList<B_REST_ModelList>,model<B_REST_Model>), //Used to validate + add a model to the modelList, optionnally saving it to DB right away. Must ret bool. Check on_quickAddForm_submit() docs
				mustConfirm:   bool,
				displayResult: bool,
			},
			extraData,
		}
	IMPORTANT: For a usage ex, check at top of the file
	*/
	export function B_REST_Vuetify_GenericListBase_createMixin(mixinOptions)
	{
		//For docs on these, check top of this file & created() docs
		mixinOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions, {
			formComponent: {accept:[Function,Object],default:null},  //Its BrGenericFormBase der import, either as ()=>import() of , or as inline import X from Y. Leave NULL if it's a list w no [+] or [edit] btn
			modelName:     {accept:[String],         required:true}, //Ex: "Citizen"
			fromLoader:    {accept:[Object],         default:null},  //WARNING: Check warnings at the end. Optional - check docs. As {apiBaseUrl<string>:null, apiBaseUrl_path_vars<string>:null, reloader<async(modelList)>:null}. Can fill partly. Notice it's a diff obj struct to the prop w the same name. WARNING: Check that other prop's w the same name's WARNINGs
			icon:          {accept:[String],         default:null},
			cols:          {accept:[Object],         required:true},
			globalActions: {accept:[Object],         default:null},
			row:           {accept:[Object],         default:null},
			ctrlF3:        {accept:[Object],         default:null},
			filters:       {accept:[Object],         default:null},
			paging:        {accept:[Object],         default:null},
			quickAddForm:  {accept:[Object],         default:null},
			extraData:     {accept:[Object],         default:null}, //NOTE: Also has B_REST_ModelList::lastLoad_coreInjection_customData. Check that & reloadList() docs, ex if wanted to show a balance in an invoice list
		}, "Generic list");
		
		return [
			//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
			//Our mixin
			{
				isGenericListBase: true, //Ex if we want to test whether a component import is a list, prior to actually using it. Check w B_REST_Vuetify_GenericListBase_isDerivedComponentImport()
				mixinOptions, //IMPORTANT: Leave this here, to help in BrGenericFormBaseSubModelList
				props: {
					showTitle:                   {type:Boolean,                     required:false, default:true},  //Check B_REST_VueApp_base::_routes_define_genericListFormModule() & B_REST_VueApp_RouteDef::convertToVueRouteDefObj() docs; Vue Router route obj needs a props() func
					isCurrentRouteMainComponent: {type:Boolean,                     required:false, default:false}, //Check B_REST_VueApp_base::_routes_define_genericListFormModule()
					elevation:                   {type:Number,                      required:false, default:8},     //Main card's elevation. Put 0 to have none
					//IMPORTANT: Check the usage ex in the docs above for how this works. One of these must be set
					fromModelList:               {type:B_REST_Model,                required:false, default:null},                                 //If providing a modelList with models prefetched & controlled independently
					fromLoader:                  {type:undefined,                   required:false, default:null, validator:validator_fromLoader}, //WARNING: Check warnings at the end. If otherwise wanting to depend on API calls. Either true, or {apiBaseUrl<string>:null, apiBaseUrl_path_vars<string>:null, reloader<async(modelList)>:null}. Can fill partly. Check docs above for how it works. WARNING: Possible that watch gets fired for no reason, ex if we structured an inline obj like :from-loader="{a,b,c}"> and other elems in UI change around it. If so, seems to be a bug and solution is to make a computed like abc(){return{a,b,c};} and do :from-loader="abc" instead
					pickerHandle:                {type:B_REST_Vuetify_PickerHandle, required:false, default:null},                                 //If used w B_REST_VueApp_base::pickers_prompt_x(). Check BrFieldDb::_picker_emit() docs for normal use cases, including having a [+] btn to create on the fly, specifying opt parent FK + note in B_REST_VueApp_base::pickers_getHandle() about pre-filtering todo
					subModelListOptions:         {type:Object,                      required:false, default:null},                                 //Check BrGenericFormBaseSubModelList::checkPrepSubModelList(). As {parent_routeName,parent_form,sub_routeName}
					//Stuff mostly for when we have a fromLoader
					uiFilters_prefs_lsKey:       {type:String,                      required:false, default:null}, //For uiFilters_prefs_x(). Which LS key to store prefs to
					//Stuff for when it's used in a picker in its pickerOptions, or via BrGenericFormBaseSubModelList::checkPrepSubModelList(). Helps if we then want to use openFormInVDialog(). WARNING: If we want to change algo, consider complex CPA case where we have 3 models at the same time, ex we're in a EventForm, we want to choose a FranchiseePark, so open FranchiseeParkList picker, click [+], and transfer a franchisee_fk that has nothing to do w the parent EventForm
					parent_pkTag:                {type:Number,                      required:false, default:null}, //When in a parent/child modules context, this will always be set; can't get there if parent model is still isNew
					parent_modelName:            {type:String,                      required:false, default:null}, //Ex if we do BrGenericListBase::openFormInVDialog(), we'll always feed this. Ex "Citizen"
					//These are usually for pickers. WARNING: In BrFieldDb::_final_slotConfig_x() for pickers, if we don't tell whether or not these are disabled, then they'll all be switched to true
					disableGlobalActions:        {type:Boolean,                     required:false, default:false},
					disableRowActions:           {type:Boolean,                     required:false, default:false},
					disableRowClick:             {type:Boolean,                     required:false, default:false},
					disableCellClick:            {type:Boolean,                     required:false, default:false},
					disableCellEdits:            {type:Boolean,                     required:false, default:false},
				},
				data()
				{
					return {
						//Misc
						formComponent:                          mixinOptions.formComponent, //Its BrGenericFormBase der import, either as ()=>import() of , or as inline import X from Y.
						modelName:                              mixinOptions.modelName,     //Ex "Citizen"
						descriptor:                             this.$bREST.models_getDescriptor(mixinOptions.modelName), //B_REST_Descriptor instance
						modelList:                              null,                   //Final B_REST_ModelList instance, either from a received instance, or created for the wanted route
						icon:                                   mixinOptions.icon,      //Ex "mdi-account"
						cols:                                   [],                     //Arr of B_REST_Vuetify_GenericList_Col instances
						globalActions:                          [],                     //Arr of B_REST_Vuetify_GenericList_GlobalAction instances, usable unless disableGlobalActions
						paging_choices:                         [],                     //Ex [5,10,20]
						extraData:                              null,                   //Optional obj. NOTE: Also has B_REST_ModelList::lastLoad_coreInjection_customData. Check that & reloadList() docs, ex if wanted to show a balance in an invoice list
						selectedItems:                          [],                     //For now, an arr of what toVDataTableItem() provides. Notice that in picker mode, we'll remove selection after the popup is closed
						xPrompt:                                null,                   //Optional instance of B_REST_Vuetify_Prompt, for various dialogs
						isDoingUserHook:                        false,                  //To cause the control to spin until user ends hooks & answers prompts
						useForLoading:                          null,                   //If we have a fromLoader or fromModelList.useForLoading
						final_server_nbRecords:                 0,                      //Check docs in _onSpecsChanged_checkRebuildReloadList()
						//Stuff for when not providing a modelList with models prefetched & controlled independently (therefore we expect it's a list that we might have to load & reload)
						final_fromLoader_reloader:              null,                   //Optionnally, user could provide a func that takes care of reloading the modelList when something changes, as async(<modelList>:null)
						final_fromLoader_isSleepingToReload:    false,
						//Stuff when we have a pickerHandle
						final_isPicker_isSingle:                false,
						final_isPicker_isMultiple:              false,
						skipNextDataTablePaginationOrSortEvent: false,
						//Stuff per row
						rowActions:                             [],                     //Arr of B_REST_Vuetify_GenericList_RowAction instances, usable unless disableRowActions
						rowClick_isEnabled:                     null,                   //Either bool or func as (model<B_REST_Model>), taken into account unless disableRowClick
						rowClick_hook:                          null,                   //Async func as (model<B_REST_Model>,isCtrlClickOrMiddleClick) if we want to listen to when we click on rows. We also have the same per cell. Must ret bool
						rowCheckbox_isEnabled:                  null,                   //Either bool or func as (model<B_REST_Model>)
						rowStyle_class:                         null,                   //As (model<B_REST_Model>). Will go into a <tr :class>
						rowStyle_style:                         null,                   //As (model<B_REST_Model>). Will go into a <tr :style>
						//If we want to have an input w autocomplete / calls to server to filter down the list
						ctrlF3_use:                             true,                   //If we want to show a field for CTRL+F3, that's not related to filters and won't cause reloading of records. NOTE: For now, there's a prob in Vuetify and it doesn't work when useForLoading... Check TODO.txt
						ctrlF3_val:                             null,                   //Current search val. When changed, doesn't auto reload modelList
						ctrlF3_hook:                            null,                   //By default, checks against the model::toLabel('<generic-list data-table="...">'), but we can use a custom func that must ret true for if search matches, as (model<B_REST_Model>,nonEmptySearchString)
						//Server filters that should open in a panel
						uiFilters:                              [],                     //Arr of B_REST_Vuetify_GenericList_Filter instances, that we'll place in a <br-model-list-filters>
						uiFilters_inlinePanelOpenState:         false,                  //Bool for panel open state, If uiFilters_uses_inline
						uiFilters_rightDrawerContent:           null,                   //Instance of B_REST_Vuetify_RightDrawerContent, if uiFilters_uses_external
						uiFilters_prefs_shouldStore:            null,                   //Ex when isCurrentRouteMainComponent, we should, but if in a picker or subModelList, maybe not
						//If we implement a helper form to create new models and auto add them to the modelList
						quickAddForm_use:                       false,                  //If we show the quick add form (not as a popup)
						quickAddForm_dialogOpenState:           false,                  //When quickAddForm_uses_dialog
						quickAddForm_model:                     null,                   //When we show the form, we must ALWAYS have an allocated B_REST_Model of the B_REST_Descriptor found in modelList.descriptor
						quickAddForm_hook:                      null,                   //Async as (modelList<B_REST_ModelList>,model<B_REST_Model>). Used to validate + add a model to the modelList, optionnally saving it to DB right away. Must ret bool. Check on_quickAddForm_submit() docs
						quickAddForm_mustConfirm:               null,                   //If we want an automatic dialog to prompt for confirmation. Loc path will be "<custom/coreBaseLocPath>.quickAddForm.confirm"
						quickAddForm_displayResult:             null,                   //If after the hook, we want msgs "<custom/coreBaseLocPath>.quickAddForm.success" or "<custom/coreBaseLocPath>.quickAddForm.failure" to appear automatically
						//Stuff for how it should appear against viewport width
						currentBreakpoint_name:                 null,                   //One of Vuetify's breakpoints (xs|sm|md|lg|xl), via Vuetify.breakpoint.name
						currentBreakpoint_vDataTableHeaderDefs: [],                     //Also including the action header, if required
						currentBreakpoint_cols:                 [],                     //Filtered version of our B_REST_Vuetify_GenericList_Col instances, against currentBreakpoint_name
						//Stuff for when we want to open form in a <v-dialog>
						openFormInVDialog_show:     false,
						openFormInVDialog_resolver: null,
					};
				},
				watch: {
					fromModelList(             newVal,oldVal) { this._onSpecsChanged_checkRebuildReloadList("fromModelList",            {newVal,oldVal}); }, //IMPORTANT: Don't change to deep; Check B_REST_VueApp_base::_routes_define_genericListFormModule() docs for why
					fromLoader(                newVal,oldVal) { this._onSpecsChanged_checkRebuildReloadList("fromLoader",               {newVal,oldVal}); }, //IMPORTANT: Don't change to deep; Check B_REST_VueApp_base::_routes_define_genericListFormModule() docs for why
					pickerHandle(              newVal,oldVal) { this._onSpecsChanged_checkRebuildReloadList("pickerHandle",             {newVal,oldVal}); },
					"pickerHandle.isPrompting"(newVal,oldVal) { this._onSpecsChanged_checkRebuildReloadList("pickerHandle-isPrompting", {newVal,oldVal}); },
					"$bREST.uiBreakpoint.name"(newVal,oldVal) { this.currentBreakpoint_reEval();                                                          }, //Causes data table's currentBreakpoint_recalcHeadersAndColsToUse() to be fired, thus calculating headers & cols defs
				},
				created()
				{
					//Cols
					{
						for (const loop_colName in mixinOptions.cols)
						{
							if (this.cols_getByName(loop_colName)) { this.throwEx(`Col "${loop_colName}" already defined`); }
							
							const loop_col = new B_REST_Vuetify_GenericList_Col(this, loop_colName, mixinOptions.cols[loop_colName]);
							this.cols.push(loop_col);
						}
						if (this.cols.length===0) { this.throwEx(`Expected to receive cols`); }
					}
					
					//Filters
					if (mixinOptions.filters)
					{
						for (const loop_uiFilterName in mixinOptions.filters)
						{
							if (this._uiFilters_getByName(loop_uiFilterName,/*throwIfNotFound*/false)) { this.throwEx(`Filter "${loop_uiFilterName}" already defined`); }
							
							const loop_uiFilter = new B_REST_Vuetify_GenericList_Filter(this, loop_uiFilterName, mixinOptions.filters[loop_uiFilterName]);
							this.uiFilters.push(loop_uiFilter);
						}
					}
					
					//Global actions
					if (mixinOptions.globalActions)
					{
						for (const loop_globalActionName in mixinOptions.globalActions)
						{
							if (this.globalActions_getByName(loop_globalActionName)) { this.throwEx(`Global action "${loop_globalActionName}" already defined`); }
							
							const loop_globalAction = new B_REST_Vuetify_GenericList_GlobalAction(this, loop_globalActionName, mixinOptions.globalActions[loop_globalActionName]);
							this.globalActions.push(loop_globalAction);
						}
					}
					
					//Row stuff
					if (mixinOptions.row)
					{
						const rowOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.row, {
							actions:  {accept:[Object], default:null},
							click:    {accept:[Object], default:null},
							checkbox: {accept:[Object], default:null},
							style:    {accept:[Object], default:null},
						}, "Generic list row");
						
						if (rowOptions.actions)
						{
							for (const loop_rowActionName in rowOptions.actions)
							{
								if (this.rowActions_getByName(loop_rowActionName)) { this.throwEx(`Row action "${loop_rowActionName}" already defined`); }
								
								const loop_rowAction = new B_REST_Vuetify_GenericList_RowAction(this, loop_rowActionName, rowOptions.actions[loop_rowActionName]);
								this.rowActions.push(loop_rowAction);
							}
						}
						
						if (rowOptions.click)
						{
							const rowClickOptions = B_REST_Utils.object_hasValidStruct_assert(rowOptions.click, {
								isEnabled: {accept:[Boolean,Function], default:true},
								hook:      {accept:undefined,          required:true},
							}, "Generic list row click");
							
							this.rowClick_isEnabled = rowClickOptions.isEnabled;
							this.rowClick_hook      = rowClickOptions.hook;
						}
						
						if (rowOptions.checkbox)
						{
							const rowCheckboxOptions = B_REST_Utils.object_hasValidStruct_assert(rowOptions.checkbox, {
								isEnabled: {accept:[Boolean,Function], required:true},
							}, "Generic list row checkbox");
							
							this.rowCheckbox_isEnabled = rowCheckboxOptions.isEnabled;
						}
						
						if (rowOptions.style)
						{
							const rowStyleOptions = B_REST_Utils.object_hasValidStruct_assert(rowOptions.style, {
								trClass: {accept:[Function], default:null},
								trStyle: {accept:[Function], default:null},
							}, "Generic list row style");
							
							this.rowStyle_class = rowStyleOptions.trClass;
							this.rowStyle_style = rowStyleOptions.trStyle;
						}
					}
					
					//CTRL+F3 options
					if (mixinOptions.ctrlF3)
					{
						const ctrlF3Options = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.ctrlF3, {
							hook: {accept:[Function], required:true},
						}, "Generic list ctrlF3");
						
						this.ctrlF3_hook = ctrlF3Options.hook;
					}
					
					//Paging
					if (mixinOptions.paging)
					{
						const pagingOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.paging, {
							choices: {accept:[Array], default:GENERIC_LIST_CONSTS.PAGING_SIZE_DEFAULT_CHOICES},
						}, "Generic list paging");
						
						this.paging_choices = pagingOptions.choices;
					}
					else { this.paging_choices=GENERIC_LIST_CONSTS.PAGING_SIZE_DEFAULT_CHOICES; }
					
					//Quick add form
					if (mixinOptions.quickAddForm)
					{
						const quickAddFormOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.quickAddForm, {
							hook:          {accept:undefined, required:true},
							mustConfirm:   {accept:[Boolean], default:false},
							displayResult: {accept:[Boolean], default:false},
						}, "Generic list quickAddForm");
						
						this.quickAddForm_use           = true;
						this.quickAddForm_hook          = quickAddFormOptions.hook;
						this.quickAddForm_mustConfirm   = quickAddFormOptions.mustConfirm;
						this.quickAddForm_displayResult = quickAddFormOptions.displayResult;
						
						this.quickAddForm_renewModel();
					}
					
					this.extraData = mixinOptions.extraData;
					
					/*
					Recalc headers & cols to use in the bound <v-data-table>.
					IMPORTANT:
						Mustn't be done before creating the B_REST_Vuetify_GenericList_RowAction instances,
						as in currentBreakpoint_recalcHeadersAndColsToUse() we add the actions <th> only if rowActions.length>0
						Used to do the following in the B_REST_Vuetify_GenericList_Col definition code block at the top
					*/
					this.currentBreakpoint_reEval();
					
					this._onSpecsChanged_checkRebuildReloadList("created");
				},
				beforeMount()
				{
					this.currentBreakpoint_reEval(); //Causes data table's currentBreakpoint_recalcHeadersAndColsToUse() to be fired, thus calculating headers & cols defs
				},
				computed: {
					uid()                              { return this._uid;                                                                                                                        }, //All Vue component instances have a unique id
					self()                             { return this;                                                                                                                             }, //NOTE: Alternative is $vnode.componentInstance (===this), but $vnode keeps on pointing to new objs each time DOM changes, so unless we put a data() on the sub prop, it'd be an overreactive computed
					isGenericListBase()                { return true;                                                                                                                             }, //We do that in BrGenericFormBase too, to help know about the component
					quotedName()                       { return `B_REST_Vuetify_GenericList<${this.componentName}>`;                                                                              },
					cssClassBase()                     { return `generic-list--${this.componentName}`;                                                                                            },
					title()                            { return this.t("title");                                                                                                                  },
					quickAddForm_title()               { return this.pickerHandle ? this.t_alt("quickAddForm.title.picker") : this.t_alt("quickAddForm.title.normal");                            },
					quickAddForm_uses_inline()         { return this.quickAddForm_use &&  !this.pickerHandle;                                                                                     }, //Check similar logic for uiFilters_uses_x()
					quickAddForm_uses_dialog()         { return this.quickAddForm_use && !!this.pickerHandle;                                                                                     }, //Check similar logic for uiFilters_uses_x()
					globalActions_uses()               { return this.globalActions.length>0 && !this.disableGlobalActions;                                                                        },
					rowActions_uses()                  { return this.rowActions.length>0    && !this.disableRowActions;                                                                           },
					uiFilters_uses()                   { return this.uiFilters.length>0;                                                                                                          },
					uiFilters_uses_external()          { return this.uiFilters.length>0 &&  !this.pickerHandle;                                                                                   }, //When not in a popup already, it's ok to have the filters open via the global right drawer externally
					uiFilters_uses_inline()            { return this.uiFilters.length>0 && !!this.pickerHandle;                                                                                   }, //In picker popup mode, we want to open the filters inside the picker popup
					uiFilters_inlineWidth()            { return this.$bREST.uiBreakpoint.mobile ? "100%" : RIGHT_DRAWER_DESKTOP_WIDTH;                                                            }, //Same logic as <BrRightDrawer> / We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
					uiFilters_shouldClosePanel()       { return GENERIC_LIST_CONSTS.RIGHT_DRAWER_ALWAYS_CLOSE_AFTER || this.uiFilters_uses_inline || this.uiFilters_rightDrawerContent?.aboveAll; },
					uiFilters_visible()                { return this.uiFilters.filter(loop_uiFilter => !loop_uiFilter.hidden);                                                                    },
					uiFilters_visible_anySet()         { return !!this.uiFilters.find(loop_uiFilter => !loop_uiFilter.hidden && loop_uiFilter.isSet);                                             },
					final_uiFilters_prefs_lsKey()      { return this.uiFilters_prefs_lsKey ?? `generic-list-ui-filter-prefs--${this.componentName}`;                                              }, //Ex "generic-list-ui-filter-prefs--clientList"
					selectedItems_has()                { return this.selectedItems.length>0;                                                                                                      },
					final_isDisabled()                 { return this.isDoingUserHook || (this.useForLoading&&this.modelList.isLoading);                                                           },
					final_isLoading()                  { return this.isDoingUserHook || (this.useForLoading&&this.modelList.isLoading);                                                           },
					final_globalActions_show_grouped() { return this.globalActions.length>1;                                                                                                      },
					final_ctrlF3_show()                { return this.ctrlF3_use && !this.useForLoading;                                                                                           }, //WARNING: Not working when useForLoading (server-items-length set): Tried putting debugger in on_ctrlF3_update(), but not being called. Alt would be to remove search & custom-filter props, and in final_items() drop items by ourselves, however it might cause Vuetify to think we had less items back from the server than supposed and fuck paging...
					final_checkboxes_show()            { return !!this.pickerHandle || !!this.globalActions.find(loop_globalAction=>!loop_globalAction.selectionType_is_0);                       }, //Picker or global actions req selections
					//Vuetify isn't zero-based
					final_paging_index()
					{
						if (!this.useForLoading) { return 1; }
						const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
						return searchOptions.paging_index+1;
					},
					/*
					When for static list display all, otherwise go against the B_REST_ModelList's B_REST_Model_Load_SearchOptions's paging size
					NOTE: Avoid changing into a data() that would be calc in _onSpecsChanged_checkRebuildReloadList(), because it could be tweaked from outside the list via a $refs
					*/
					final_paging_size()
					{
						if (!this.useForLoading) { return GENERIC_LIST_CONSTS.PAGING_SIZE_ALL; } //For now, otherwise we should add a prop for default static paging
						
						const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
						
						return searchOptions.paging_size_isAll ? GENERIC_LIST_CONSTS.PAGING_SIZE_ALL : searchOptions.paging_size; //WARNING: Convert PAGING_SIZE_ALL from NULL to -1 if we must
					},
					/*
					When for static list, we just use normal choices, but if we load on server, we'll also include what the B_REST_Model_Load_SearchOptions instance currently holds, to prevent hell
					NOTE: Avoid changing into a data() that would be calc in _onSpecsChanged_checkRebuildReloadList(), because it could be tweaked from outside the list via a $refs
					*/
					final_paging_sizeChoices()
					{
						if (!this.useForLoading) { return [...this.paging_choices, GENERIC_LIST_CONSTS.PAGING_SIZE_ALL] ; }
						
						const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
						
						const choices = [...this.paging_choices];
						
						//Make sure current server paging size is included, if it's not all too. WARNING: Convert PAGING_SIZE_ALL from NULL to -1 if we must
						if (!searchOptions.paging_size_isAll && !choices.includes(searchOptions.paging_size))
						{
							choices.push(searchOptions.paging_size);
							choices.sort((a,b) =>
							{
								if (a===b) { return 0; }
								return a-b;
							});
						}
						choices.push(GENERIC_LIST_CONSTS.PAGING_SIZE_ALL);
						
						return choices;
					},
					final_footerProps()
					{
						return {
							'items-per-page-options': this.final_paging_sizeChoices,
						};
					},
					//Rets an arr of {action<B_REST_Vuetify_GenericList_GlobalAction>, isClickable}
					final_globalActionInfoList()
					{
						const arr = [];
						
						const selectedItems_count = this.selectedItems.length;
						
						for (const loop_globalAction of this.globalActions)
						{
							let loop_isClickable = loop_globalAction.click_isEnabled(this.selectedItems); //Check especially for perms. Bool or disabled reason tag. Throws on exception
							
							//If still OK (perms), then check against context
							if (loop_isClickable===true)
							{
								switch (loop_globalAction.selectionType)
								{
									case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0:   loop_isClickable=true;                    break;
									case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1:   loop_isClickable=selectedItems_count===1; break;
									case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N: loop_isClickable=true;                    break;
									case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1_N: loop_isClickable=selectedItems_count>0;   break;
									default: this.throwEx(`Unexpected selectionType "${loop_globalAction.selectionType}"`);
								}
							}
							
							if (loop_isClickable===true || GENERIC_LIST_CONSTS.GLOBAL_ACTIONS_INCLUDE_DISABLED)
							{
								arr.push({
									action:          loop_globalAction,
									isClickable:     loop_isClickable,
									disabledTooltip: B_REST_Utils.string_is(loop_isClickable) ? loop_globalAction.getMsg_disabledReasonTag(loop_isClickable) : null,
								});
							}
						}
						
						return arr;
					},
					/*
					We don't need to pass vals for all fields we define in datatable's currentBreakpoint_vDataTableHeaderDefs; as long as we pass only 1 field it's ok (or filters will throw).
					We add an rowInfo extra data to each item, to be able to properly handle when stuff is clickable and get easy ptrs to simplify template:
						{
							model:        B_REST_Model,
							isClickable:  <bool>
							isSelectable: <bool>
							actions:      [{action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}],
							cols:         Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
						}
					*/
					final_items()
					{
						const items = [];
						for (const loop_model of this.modelList.models) { items.push(this.toVDataTableItem(loop_model)); }
						return items;
					},
					openFormInVDialog_maxWidth()     { return this.pickerHandle?.maxWidth ?? GENERIC_LIST_CONSTS.OPEN_FORM_IN_V_DIALOG_DEFAULT_MAX_WIDTH; },
					openFormInVDialog_isFullscreen() { return this.$bREST.uiBreakpoint.mobile;                                                            }, //NOTE: We also have B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
				},
				methods: {
					throwEx(msg,details=null)     { this.$bREST.throwEx(`${this.quotedName}: ${msg}`,details);                          },
					logError(msg,details=null)    { B_REST_Utils.console_error(`${this.quotedName}: ${msg}`,details);                   },
					cols_getByName(name)          { return this.cols.find(loop_col                   => loop_col.name         ===name); }, //Can ret NULL
					globalActions_getByName(name) { return this.globalActions.find(loop_globalAction => loop_globalAction.name===name); }, //Can ret NULL
					rowActions_getByName(name)    { return this.rowActions.find(loop_rowAction       => loop_rowAction.name   ===name); }, //Can ret NULL
					/*
					Possible events:
						created
						fromModelList             {newVal,oldVal} //NOTE: We allow changing it, since BrGenericFormBase also allow changing bound B_REST_Model instance
						fromLoader                {newVal,oldVal}
						pickerHandle              {newVal,oldVal} //NOTE: Not allowed; only thing that should change is its isMultiple / selection props. Even if a picker is in B_REST_Vuetify_PickerDef.REUSE_MODE_x, it will always stay bound to the same component
						pickerHandle-isPrompting  {newVal,oldVal}
						dataTablePaginationOrSort {paging_index,pagingSize,sortedColList_names,sortedColList_isDesc}
					final_server_nbRecords:
						WARNING:
							1) Make sure the list's current page size matches what is returned, otherwise vuetify will be confused and ignore final_server_nbRecords as well
								Ex: Page size indicates 10, final_server_nbRecords indicates 100, but we return 20 items.
									Vuetify will show that we have 2 pages (1-10 and 11-20), and not understand that we should have up to 100 records.
									So if page size is 10, we must return 10 (or less if last page).
							2) For now not handling APIs where we don't know how many records get returned.
								Vuetify will think we only have 1 page and show the nav arrows disabled (even if we ret -1 here), but we'd need to be able to do modelList.loadMore().
								"Rebuild the whole <template #footer> :'("
						NOTE:
							This gets called as soon as we boot the list, even before it has time to load its initial data, so nbRecords_filtered will most likely be null at that time		
					Picker w pre-filtering:
						Check BrFieldDb::_picker_emit() docs for how to do
					WARNING:
						Model list will throw if we end up w a apiBaseUrl containing path vars to replace, but that we don't provide them
						Displaying pickerHandle's prev selection only accepts B_REST_Model instances, not PKs:
							-this.selectedItems expects an arr of B_REST_Model, and so does toVDataTableItem()
							-If we're loading the list for the 1st time and wanted to show selections from before, just passing PKs wouldn't be good,
								since those records aren't likely to be found on the first X pagingSize items
							-Ex for BrFieldDb's picker logic, we do have a BrFieldDb::selectedItem_model, but it's populated only when items=sharedList
					*/
					async _onSpecsChanged_checkRebuildReloadList(event, extraData={})
					{
						B_REST_Utils.console_todo([
							`Change :items-per-page & :page props into :options={page,itemsPerPage,sortBy,sortDesc,groupBy,groupDesc,multiSort,mustSort} to properly revert sort in _onSpecsChanged_checkRebuildReloadList() when mustAlsoResetPagingAndSort`,
							`Change fromLoader to be a class, to rem console_warn + will need to change object_areEqual check because now before/after will be the same ptr so will need to be deep`,
						]);
						
						const event_isCreated            = event==="created";
						let   modelListInstanceChanged   = false;
						let   mustReload                 = false;
						let   mustAlsoResetPagingAndSort = false;
						let   mustAlsoLoadUIFilterPrefs  = false;
						
						if (event_isCreated) { this.skipNextDataTablePaginationOrSortEvent=true; }
						
						if (!!this.fromModelList === !!this.fromLoader) { this.throwEx(`Must have only one of fromModelList or fromLoader set`); }
						
						if ((event_isCreated&&this.fromModelList) || event==="fromModelList")
						{
							if (extraData.newVal===null) { this.throwEx(`Can't unset fromModelList`); }
							const fromModelList_modelName = this.fromModelList.descriptor.name;
							if (fromModelList_modelName!==mixinOptions.modelName) { this.throwEx(`Received <${fromModelList_modelName}> modelList, but expected a <${mixinOptions.modelName}> one`); }
							this.modelList                   = this.fromModelList;                 //Change modelList ptr
							this.useForLoading               = this.modelList.useForLoading;
							this.final_server_nbRecords      = GENERIC_LIST_CONSTS.PAGING_IGNORE;
							this.uiFilters_prefs_shouldStore = false;                              //For now, but maybe not. If we decide to change, careful to pass a uiFilters_prefs_lsKey, or will override what we use when isCurrentRouteMainComponent
							modelListInstanceChanged         = true;
							//NOTE: Here we don't do mustReload=true, because user is controlling instance
						}
						
						if ((event_isCreated&&this.fromLoader) || event==="fromLoader")
						{
							if (this.fromModelList)      { this.throwEx(`Can't play w fromLoader when already using fromModelList`); }
							if (extraData.newVal===null) { this.throwEx(`Can't unset fromLoader`);                                   }
							if (!event_isCreated)
							{
								/*
								Skip useless changes (ex clicking multiple times on a BrFieldDb doing a pickers_getHandle() but not changing the options)
								IMPORTANT: Must do newVal!==oldVal, because if both points to the same obj, no matter what could have changed, both will mean "newVal"
								*/
								const {newVal:b,oldVal:a} = extraData;
								if (b!==a && B_REST_Utils.object_is(b)&&B_REST_Utils.object_is(a)&&B_REST_Utils.object_areEqual(b,a)) { return; }
								//Shoot a msg about this being fired for no reason. Also check warnings in B_REST_VueApp_base::_routes_define_genericListFormModule()
								B_REST_Utils.console_warn(`Possible that BrGenericListBase's fromLoader watch gets fired for no reason, ex if we structured an obj for :from-loader like <my-list :from-loader="{a,b,c}"> and other elems in UI change around it. If so, seems to be a bug and solution is to make a computed like abc(){return{a,b,c};} and do <my-list :from-loader="abc"> instead, for ${this.quotedName}`);
							}
							
							let apiBaseUrl           = null; //Either apiBaseUrl or reloader must end set
							let apiBaseUrl_path_vars = null; //NOTE: Ok to be NULL if api base url doesn't contain path vars to define
							let reloader             = null; //Either apiBaseUrl or reloader must end set. Note that this hasn't been used so far in any proj
							//Figure out apiBaseUrl etc to use against what we find in fromLoader, or fallback w what we have in mixin
							{
								if (this.fromLoader && B_REST_Utils.object_is(this.fromLoader))
								{
									if (this.fromLoader.reloader)   { reloader  =this.fromLoader.reloader;   }
									if (this.fromLoader.apiBaseUrl) { apiBaseUrl=this.fromLoader.apiBaseUrl; }
									
									//Optional path vars. Note that the mixinOptions shouldn't provide that, so we won't check for a mixinOptions.fromLoader.apiBaseUrl_path_vars
									if (this.fromLoader.apiBaseUrl_path_vars) { apiBaseUrl_path_vars=this.fromLoader.apiBaseUrl_path_vars; }
								}
								if (!reloader)   { reloader  =mixinOptions.fromLoader.reloader;   }
								if (!apiBaseUrl) { apiBaseUrl=mixinOptions.fromLoader.apiBaseUrl; }
								
								if (!!reloader===!!apiBaseUrl) { this.throwEx(`Must define either the apiBaseUrl or reloader prop in the mixin or component $props`); }
								
								this.final_fromLoader_reloader = reloader;
							}
							
							//Now either create modelList or update its apiBaseUrl
							if (this.modelList)
							{
								this.modelList.apiBaseUrl           = apiBaseUrl;
								this.modelList.apiBaseUrl_path_vars = apiBaseUrl_path_vars;
								mustAlsoResetPagingAndSort          = true; //Would be strange that last API call we were on page 10 and now on a new API call we're still stuck on that page
								
								//NOTE: Not sure if we should do uiFilters_prefs_checkLoadFromLS() here, because if we then do mustAlsoResetPagingAndSort, we'll revert what we'll just set
							}
							else
							{
								this.modelList = B_REST_ModelList.commonDefs_make_forLoading(mixinOptions.modelName, {
									apiBaseUrl,
									apiBaseUrl_path_vars,
									apiBaseUrl_needsAccessToken: null,
									hook_afterLoad:              null,
									useCachedShare:              false
								});
								this.useForLoading               = true;
								this.uiFilters_prefs_shouldStore = this.uiFilters_uses && this.isCurrentRouteMainComponent && !this.pickerHandle && !this.parent_modelName;  //For now the only reason why we could, but could change. If we change logic, careful to pass a uiFilters_prefs_lsKey, or will override what we use when isCurrentRouteMainComponent
								//If usage doesn't want to be in charge of loading list, then make sure we'll get all the info we need in order to put data in cols
								if (!this.final_fromLoader_reloader)
								{
									for (const loop_col of this.cols) { if(loop_col.fieldNamePaths){this.modelList.requiredFields.requiredFields_addFields(loop_col.fieldNamePaths);} }
								}
								modelListInstanceChanged  = true;
								mustAlsoLoadUIFilterPrefs = true;
							}
							mustReload = true;
						}
						
						if (event==="dataTablePaginationOrSort")
						{
							if (this.skipNextDataTablePaginationOrSortEvent) { this.skipNextDataTablePaginationOrSortEvent=false; return; }
							
							const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
							searchOptions.paging_size  = extraData.pagingSize===GENERIC_LIST_CONSTS.PAGING_SIZE_ALL ? B_REST_Model_Load_SearchOptions.PAGING_SIZE_ALL : extraData.pagingSize;
							searchOptions.paging_index = extraData.paging_index-1; //Vuetify's extraData.paging_index isn't zero-based, so must convert it to zero-base
							/*
							For sort, keep simple by just flushing our own sort params all the time
							NOTE:
								When we boot the list or when the code alts the sorts from outside the list,
								we will NOT set them correctly via <v-data-table :options="{page,itemsPerPage,sortBy,sortDesc}" /> because it'd be a pain,
								so next time user sorts via the list, we'll overwrite the search options and that's it
							*/
							searchOptions.orderByList_reset();
							for (let i=0; i<extraData.sortedColList_names.length; i++)
							{
								//NOTE: B_REST_Vuetify_GenericList_Col::toVDataTableHeaderDef() puts sortable=true only on cols w exactly 1 field
								const loop_fieldNamePath = this.cols_getByName(extraData.sortedColList_names[i]).fieldNamePaths;
								searchOptions.orderByList_add(loop_fieldNamePath, !extraData.sortedColList_isDesc[i]);
							}
							mustReload = true;
							
							if (this.fromLoader) { this.uiFilters_prefs_checkSaveToLS(); }
						}
						
						//Picker related
						{
							if (event==="pickerHandle") { this.throwEx(`Not allowed to change pickerHandle for another instance/back to NULL; only change its props, like isPrompting|isMultiple|selection`); } //NOTE: If we can, then will have to adapt code to react to it starting/becoming NULL
							
							if ((event_isCreated&&this.pickerHandle) || event==="pickerHandle-isPrompting")
							{
								if (!this.modelList) { this.throwEx(`Shouldn't play w picker when we have no modelList. Could happen ex if we made the list wo a fromLoader prop`); }
								
								/*
								Probably shouldn't have filter prefs here:
									Picking for contacts in a form where we use client #123 in some field, then another form where we use client #456
									Then we go to a main ContactList, and instead of seeing all contacts, we only see those of client #456
								If ever we decide to change, think about:
									If we then do mustAlsoResetPagingAndSort, we'll revert what we'll just set
								 	Passing a uiFilters_prefs_lsKey, or will override what we use when isCurrentRouteMainComponent
									Also check the "final_fromLoader_isSleepingToReload" related code
								*/
								this.uiFilters_prefs_shouldStore = false;
								
								/*
								Ignore pickerHandle watch if we're not in picking mode (check B_REST_Vuetify_PickerHandle::_select() docs for why)
								Also in uiFilters_uses_external mode, prevents embarrassingly leaving filters panel opened when we close the popup
								*/
								const isClosingPicker = event==="pickerHandle-isPrompting" && this.pickerHandle.isPrompting===false;
								if (isClosingPicker) { this._uiFilters_done(GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_CANCEL);return; }
								
								this.final_isPicker_isMultiple =  this.pickerHandle.isMultiple;
								this.final_isPicker_isSingle   = !this.final_isPicker_isMultiple;
								
								/*
								Set/unset selection to display in <v-chip>, when we open picker
								WARNING: Not greatly supported; check func doc above
								*/
								{
									const selectedItems = [];
									if (this.pickerHandle.selection_has)
									{
										const pickerSelectedItems = this.final_isPicker_isMultiple ? this.pickerHandle.selection : [this.pickerHandle.selection]; //Simplify as arr
										
										for (const loop_pickerSelectedItem of pickerSelectedItems)
										{
											if (!(loop_pickerSelectedItem instanceof B_REST_Model)) { this.throwEx(`Selection must be B_REST_Model instances; not supporting PKs yet. Check func docs`); }
											const loop_fakeItem = this.toVDataTableItem(loop_pickerSelectedItem); //Expects that selected items be instances of B_REST_Model
											selectedItems.push(loop_fakeItem);
										}
									}
									
									this.selectedItems = selectedItems;
								}
								
								/*
								Even though we wouldn't need to reload ex for selection changes because their checkboxes are handled by <v-data-table v-model>, still reload,
									to prevent WTFs when data has changed since last open.
								Also make sure we come back at initial paging and order
								*/
								if (!event_isCreated && !this.final_fromLoader_isSleepingToReload)
								{
									mustReload                 = true;
									mustAlsoResetPagingAndSort = true;
								}
							}
						}
						
						//Update bindings between the modelList & component filters, when we set/change modelList
						if (modelListInstanceChanged)
						{
							for (const loop_uiFilter of this.uiFilters) { loop_uiFilter.updateModelListBinding(); }
							
							if (mustAlsoLoadUIFilterPrefs) { this.uiFilters_prefs_checkLoadFromLS(); } //IMPORTANT: Don't put before filters updateModelListBinding(), or will lose their vals
						}
						
						if (mustReload)
						{
							if (mustAlsoResetPagingAndSort)
							{
								this.skipNextDataTablePaginationOrSortEvent = true; //Because paging computed are used in <v-data-table> and changing them causes the event to fire back
								const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
								searchOptions.paging_index = 0;
								searchOptions.orderByList_reset();
							}
							
							/*
							In case user is waiting for the component to be mounted to then pre-set some filters, wait before reloading
							Ex for when we're using it as a picker and do: await B_REST_Vuetify_PickerHandle::component_waitMounted()
							If we don't do so, won't throw exception; initial load will not have known to apply filters, even if they'll be shown in the drawer correctly
							NOTE:
								It's not just about checking if mounted(), as otherwise for pickers it only worked for the 1st time and then after no longer sleeping didn't work
								For now, we'll assume we only need to do that when we have a picker, but if it's not the case, then just always sleep
							*/
							if (this.pickerHandle)
							{
								this.final_fromLoader_isSleepingToReload = true;
								await this.coreMixin_sleep_nextFrame();
								this.final_fromLoader_isSleepingToReload = false;
							}
							
							if (this.modelList.isLoading) { this.throwEx(`Shouldn't get there when modelList already loading. Maybe do a await until loaded`); } //NOTE: We don't have something w promises like B_REST_Model::awaitUnsavedChangesSaved() yet
							
							return this.reloadList();
						}
					},
					/*
					Wrapper for reloading list, where we either do it ourselves or let usage use a custom func to do it
					Could be called from outside the class
					About returning extra data that's not part of an item, ex to show a balance in an invoice list in the "data-table-area--after" slot:
						B_REST_ModelList::lastLoad_coreInjection_customData can contain the result of RouteParser_base::output_json_injectCore_customData().
						In server's route, check _overridable_genericListFormModule_tweakOutput() docs for that
					*/
					async reloadList()
					{
						if (!this.useForLoading) { this.throwEx(`Can only use reloadList() when for loading`); }
						
						try
						{
							/*
							If usage wants to take care of reloading the list
							If we wanted to ret extra data that's not part of an item, ex to show a balance in an invoice list, check the docs above
							*/
							if (this.final_fromLoader_reloader) { await this.final_fromLoader_reloader.call(this,this.modelList); }
							else                                { await this.modelList.reload();                                  }
							
							//Recalc nb records found
							{
								const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
								this.final_server_nbRecords = searchOptions.lastCall_nbRecords_filtered ?? GENERIC_LIST_CONSTS.PAGING_UNKNOWN_SERVER_NB_RECORDS_TMP_FALLBACK;
							}
						}
						catch (e)
						{
							B_REST_Utils.console_error(`${this.quotedName}: reloadList failed: ${e}`);
							this.$bREST.notifs_error_generic();
							return false;
						}
						
						return true;
					},
					
					//VUETIFY RELATED
						//Each time Vuetify's breakpoint changes, we should recalc headers & cols to use in the bound <v-data-table>
						currentBreakpoint_reEval()
						{
							const newBreakpoint = this.$bREST.uiBreakpoint.name;
							
							if (this.currentBreakpoint_name!==newBreakpoint)
							{
								this.currentBreakpoint_name = newBreakpoint;
								this.currentBreakpoint_recalcHeadersAndColsToUse();
							}
						},
							/*
							Outputs according to https://vuetifyjs.com/en/api/v-data-table/#props-headers
							To prevent bugs in Vuetify:
								-We'll make sure exactly 1 col has its prop "filterable" to true:
									-Otherwise it throws lots of errs when we all are not filterable
									-Otherwise if we set true on multiple, the filter func will get called as many time as we have filterable col per row for nothing
								-We'll allow sort:
									-On all cols, when it's meant to be a table of static data (for now we check against B_REST_ModelList::useForLoading())
									-On specified cols, when it's for loadable data where the server takes care of sorting
							*/
							currentBreakpoint_recalcHeadersAndColsToUse()
							{
								const currentBreakpoint_number = this.vuetifyBreakpointToNumber(this.currentBreakpoint_name);
								const headers = [];
								const cols    = [];
								
								for (const loop_col of this.cols)
								{
									//NOTE: We could also change the algo to use B_REST_VueApp_base::uiBreakpoint_isXAndUp/Down()
									
									const loop_fromBreakpointNumber = this.vuetifyBreakpointToNumber(loop_col.style_fromBreakpoint);
									if (loop_fromBreakpointNumber > currentBreakpoint_number) { continue; }
									
									headers.push(loop_col.toVDataTableHeaderDef(/*isFirstCol*/headers.length===0));
									cols.push(loop_col);
								}
								
								if (cols.length===0)
								{
									this.throwEx(`We don't have cols for the current breakpoint "${this.currentBreakpoint_name}", out of ${this.cols.length} in total. Check that we have some:\n\tnew Col(..., {style:{fromBreakpoint:"${this.currentBreakpoint_name}"}}) //Tip: put "xs" so it's always there`);
								}
								
								//If we should add the extra action cell
								if (this.rowActions_uses)
								{
									headers.push({
										value:      GENERIC_LIST_CONSTS.ROW_ACTIONS_COL_NAME,
										text:       this.t_alt("cols.actions.label"),
										align:      "center",
										sortable:   false,
										filterable: false,
										groupable:  false,
										divider:    false,
										class:      null,
										cellClass:  null,
										width:      this.rowActions.length * GENERIC_LIST_CONSTS.ROW_ACTIONS_BTN_REQ_SIZE,
										filter:     null,
										sort:       null,
									});
								}
								
								this.currentBreakpoint_vDataTableHeaderDefs = headers;
								this.currentBreakpoint_cols                 = cols;
							},
								//One of xs|sm|md|lg|xl, or nothing which equals xs
								vuetifyBreakpointToNumber(breakpoint)
								{
									switch (breakpoint)
									{
										case "xs": return 0;
										case "sm": return 1;
										case "md": return 2;
										case "lg": return 3;
										case "xl": return 4;
										case null: return 0;
									}
									
									this.throwEx(`Unknow Vuetify breakpoint "${breakpoint}"`);
								},
						/*
						For component's final_items() and on_quickAddForm_submit()
						We don't need to pass vals for all _currentBreakpoint_cols; as long as we pass only 1 field it's ok (or filters will throw).
						We add an rowInfo extra data to each item, to be able to properly handle when stuff is clickable and get easy ptrs to simplify template:
							{
								model:        B_REST_Model,
								isClickable:  <bool>
								isSelectable: <bool>
								actions:      [{action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}],
								cols:         Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
							}
						*/
						toVDataTableItem(model)
						{
							if (!(model instanceof B_REST_Model)) { this.throwEx(`Expected an instance of B_REST_Model`); }
							
							//Setup general state for that model
							const row_isClickable  = !this.disableRowClick && this.toVDataTableItem_evalModel_row_isClickable(model);
							const row_isSelectable = this.toVDataTableItem_evalModel_row_isSelectable(model);
							const row_actions      = !this.disableRowActions && this.toVDataTableItem_evalModel_row_actions(model, GENERIC_LIST_CONSTS.ROW_ACTIONS_INCLUDE_DISABLED); //Arr of {action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}
							
							//Setup state per col for that model
							const cols_extraData = {}; //Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
							for (const loop_col of this.currentBreakpoint_cols)
							{
								const loop_col_isClickable = !this.disableCellClick && loop_col.click_isEnabled(model); //Rets false on exception
								const loop_col_isEditable  = !this.disableCellEdits && loop_col.isEditable(model);
								
								cols_extraData[loop_col.name] = {
									col:         loop_col,
									modelField:  loop_col.getModelField(model), //Rets NULL if we don't have a SINGLE req fieldNamePath, if it's for a "<toLabel>" that's not nested (useToLabel_main()), or if we didn't pass required fields (modelList.requiredFields.requiredFields_addFields()). Check B_REST_Vuetify_GenericList_Col::getModelField() docs
									isClickable: loop_col_isClickable,
									isEditable:  loop_col_isEditable,
								};
							}
							
							//IMPORTANT: Don't change the fact that we do need to pass model twice. Check method docs
							const item = {
								frontendUUID: model.frontendUUID,
								isSelectable: row_isSelectable, //Do this so Vuetify knows not to try to check non-existent checkboxes
								rowInfo: {
									model:        model,       //Pass this anyways to keep template simple
									isClickable:  row_isClickable,
									isSelectable: row_isSelectable, //Repeat usage of row_isClickable here, so we can also add the checkboxes at the right time
									actions:      row_actions,
									cols:         cols_extraData,
								},
							};
							
							if (this.currentBreakpoint_cols.length===0) { this.throwEx(`We don't have cols for the current breakpoint "${this.currentBreakpoint_name}"`); } //Shouldn't happen; already throws in currentBreakpoint_recalcHeadersAndColsToUse()
							item[this.currentBreakpoint_cols[0].name] = model; //IMPORTANT: We need to do this or filters will break. Check method docs
							
							return item;
						},
							toVDataTableItem_evalModel_row_isClickable(model)
							{
								if (!this.rowClick_isEnabled || !this.rowClick_hook) { return false; } //NULL or false
								
								//Can be a bool or a func
								if (this.rowClick_isEnabled===true) { return true; }
								
								//Otherwise it's a func
								try
								{
									return this.rowClick_isEnabled.call(this,model);
								}
								catch (e) { this.throwEx(`rowClick_isEnabled hook failed: ${e}`); }
								return false;
							},
							toVDataTableItem_evalModel_row_isSelectable(model)
							{
								if (!this.rowCheckbox_isEnabled) { return false; } //NULL or false
								
								//Can be a bool or a func
								if (this.rowCheckbox_isEnabled===true) { return true; }
								
								//Otherwise it's a func
								try
								{
									return this.rowCheckbox_isEnabled.call(this,model);
								}
								catch (e) { this.throwEx(`rowCheckbox_isEnabled hook failed: ${e}`); }
								return false;
							},
							//Rets as an arr of {action<B_REST_Vuetify_GenericList_RowAction>, isClickable}
							toVDataTableItem_evalModel_row_actions(model, includeDisabled)
							{
								const rowActions = [];
								
								for (const loop_rowAction of this.rowActions)
								{
									const loop_isClickable = loop_rowAction.click_isEnabled(model); //Bool or disabled reason tag. Rets false on exception
									
									if (loop_isClickable!==true && !includeDisabled) { continue; }
									
									rowActions.push({
										action:      loop_rowAction,
										isClickable: loop_isClickable,
										tooltip:     loop_isClickable===true ? loop_rowAction.label : (B_REST_Utils.string_is(loop_isClickable)?loop_rowAction.getMsg_disabledReasonTag(loop_isClickable):null),
									});
								}
								
								return rowActions;
							},
					//GEN
						row_evalClass(rowInfo)
						{
							let rowClass = this.rowStyle_class ? this.rowStyle_class.call(this,rowInfo.model) : undefined;
							
							if (rowInfo.model.toRemoveOrDelete) { rowClass=this.$bREST.classProp_addTag(rowClass,GENERIC_LIST_CONSTS.CLASS_ROW_TO_REM_OR_TO_DEL); }
							
							return rowClass;
						},
						row_evalStyle(rowInfo) { return this.rowStyle_style ? this.rowStyle_style.call(this,rowInfo.model) : undefined; },
						async on_row_click(rowInfo, isCtrlClickOrMiddleClick)
						{
							this.isDoingUserHook = true;
							await this.rowClick_hook.call(this,rowInfo.model,isCtrlClickOrMiddleClick); //NOTE: Rets false on exception, but we won't care for now
							this.isDoingUserHook = false;
						},
						async on_cell_click(col, rowInfo, isCtrlClickOrMiddleClick)
						{
							this.isDoingUserHook = true;
							await col.click_hook(rowInfo.model,isCtrlClickOrMiddleClick); //NOTE: Rets false on exception, but we won't care for now
							this.isDoingUserHook = false;
						},
						async on_globalAction_click(action, isCtrlClickOrMiddleClick)       { return this.on_xAction_click("global",action,isCtrlClickOrMiddleClick,this.selectedItems.map(loop_item=>loop_item.rowInfo.model)); },
						async on_rowAction_click(action, rowInfo, isCtrlClickOrMiddleClick) { return this.on_xAction_click("row",   action,isCtrlClickOrMiddleClick,rowInfo.model);                                              },
							/*
							Usage ex:
								export default {
									name: "animalList",
									mixins: B_REST_Vuetify_GenericListBase_createMixin({
										...
										globalActions: {
											add: {
												click: {
													async hook(action,selectedModels) { alert(1); },
												},
												...
											},
										},
										row: {
											...
											actions: {
												edit: {
													click: {
														async hook(action,model) { alert(2); },
													},
													...
									...
								}
							IMPORTANT: Should ret false on exception
							*/
							async on_xAction_click(type, xAction, isCtrlClickOrMiddleClick, modelOrModels)
							{
								this.isDoingUserHook = true;
								
								let modelCount   = null;
								let modelsString = null;
								
								if (xAction.mustConfirm || xAction.displayResult)
								{
									if (modelOrModels instanceof B_REST_Model)
									{
										modelCount   = 1;
										modelsString = modelOrModels.toLabel(this.quotedName);
									}
									//When we have multiple, displays something like "<label1>, <label2>, <label3>... +123"
									else if (B_REST_Utils.array_is(modelOrModels))
									{
										modelCount = modelOrModels.length;
										
										if (modelCount===0) { modelsString=this.$bREST.t_core("app.components.BrGenericListBase.globalActions.allFiltered",{count:this.final_server_nbRecords}); }
										else
										{
											const smallerLimit = Math.min(modelCount, GENERIC_LIST_CONSTS.X_ACTION_MODELS_STRING_MAX_LABEL_COUNT);
											
											const fewModelLabels = [];
											for (let i=0; i<smallerLimit; i++) { fewModelLabels.push(modelOrModels[i].toLabel(this.quotedName)); }
											modelsString = fewModelLabels.join(", ");
											
											if (modelCount>smallerLimit) { modelsString += `... +${modelCount-smallerLimit}`; }
										}
									}
									else { this.throwEx(`Didn't know what to do w modelOrModels`,modelOrModels); }
									
									this.xPrompt = new B_REST_Vuetify_Prompt(xAction.label, /*isModal*/true);
								}
								
								//If we must freeze and ask user for confirmation
								let canContinue = true;
								if (xAction.mustConfirm)
								{
									this.xPrompt.actions = [
										new B_REST_Vuetify_Prompt_Action("cancel", this.t_alt("prompt.cancel"), null), //NOTE: We have "prompt.no" too...
										null,
										new B_REST_Vuetify_Prompt_Action("yes", this.t_alt("prompt.yes"), "error"),
									];
									
									this.xPrompt.body = xAction.getMsg_confirm(modelCount, modelsString);
									
									const selectedActionOrNull = await this.xPrompt.show();
									if (selectedActionOrNull!=="yes") { canContinue=false; }
								}
								
								//Then do the action hook
								if (canContinue)
								{
									let success = false;
									
									try
									{
										success = await xAction.click_hook(modelOrModels, isCtrlClickOrMiddleClick); //Rets false on exception
									}
									catch (e) { B_REST_Utils.console_error(`${this.quotedName}: got err on ${type} ${xAction.name}`,e); }
									
									//If we want to display whether it worked or not
									if (xAction.displayResult)
									{
										const msg   = success ? xAction.getMsg_success(modelCount,modelsString) : xAction.getMsg_failure(modelCount,modelsString);
										const color = success ? "success"                                       : "error";
										this.$bREST.notifs_tmp({msg,color});
									}
								}
								
								this.xPrompt         = null;
								this.isDoingUserHook = false;
							},
					//SERVER
						//For now, only called when used for server loading
						on_dataTablePaginationOrSort_change({page:paging_index, itemsPerPage:pagingSize, sortBy:sortedColList_names, sortDesc:sortedColList_isDesc})
						{
							this._onSpecsChanged_checkRebuildReloadList("dataTablePaginationOrSort", {paging_index,pagingSize,sortedColList_names,sortedColList_isDesc});
						},
						/*
						When we change sort, will call this once, and we have to ret a new arr sorted a diff way
						Called from BrGenericListBaseDataTable::customSortCallback()
						WARNING:
							-Not called when useForLoading (and if we would, we'd need to revert sort each time we open picker, if any)
							-Don't implement a custom sort func in ModelList, because what we get here is a skimmed of arr of things after filters are applied
						*/
						on_sort_update(filteredItems, sortedColList_names, sortedColList_isDesc, locale, customSorters)
						{
							if (sortedColList_names.length===0) { return filteredItems; }
							
							const sortedItems = [...filteredItems];
							
							const sortFields = []; //Arr of {fieldNamePath, isASC}
							for (let i=0; i<sortedColList_names.length; i++)
							{
								/*
								NOTE: B_REST_Vuetify_GenericList_Col::toVDataTableHeaderDef() puts sortable=true only on cols w exactly 1 field
								WARNING: Could also point on a lookup, but for now we'll break if it happens; todo later
								*/
								
								const loop_col = this.cols_getByName(sortedColList_names[i]);
								if (!loop_col.fieldDescriptor_isDB) { this.throwEx(`For now, we don't support sorting by non DB cols, for "${loop_col.name}"`); }
								
								sortFields.push({
									fieldNamePath: loop_col.fieldNamePaths,
									isASC:         !sortedColList_isDesc[i],
									whenANotSet:   sortedColList_isDesc[i] ?  1 : -1, //NOTE: We might want to change that later, if we want NULLs at beginning or end
									whenBNotSet:   sortedColList_isDesc[i] ? -1 :  1, //NOTE: Same as the above
									whenABefore:   sortedColList_isDesc[i] ?  1 : -1,
									whenBBefore:   sortedColList_isDesc[i] ? -1 :  1,
								});
							}
							
							//-1:A first, 0:Skip, 1:B first
							sortedItems.sort((loop_a,loop_b) =>
							{
								for (const loop_sortField of sortFields)
								{
									const loop_a_field = loop_a.rowInfo.model.select(loop_sortField.fieldNamePath);
									const loop_b_field = loop_b.rowInfo.model.select(loop_sortField.fieldNamePath);
									
									if (loop_a_field.val===loop_b_field.val) { continue;                          }
									if (!loop_a_field.isSet)                 { return loop_sortField.whenANotSet; }
									if (!loop_b_field.isSet)                 { return loop_sortField.whenBNotSet; }
									return loop_a_field.val<loop_b_field.val ? loop_sortField.whenABefore : loop_sortField.whenBBefore;
								}
								
								return 0;
							});
							
							return sortedItems;
						},
					//CTRL+F3
						/*
						Called from BrGenericListBaseDataTable.vue::customFilterCallback()
						On each search term change, will call this for every cell of every row, to know if any cell "matches", therefore keeping the row in filtered rows arr.
						However, since in final_items we only pass 1 cell, then it'll be fine
						WARNING:
							-Not called when useForLoading() :(
						*/
						on_ctrlF3_update(loop_cellVal, nonEmptySearchString, loop_item)
						{
							const loop_model = loop_item.rowInfo.model;
							
							//Either use the custom hook, or model's toLabel func
							if (this.ctrlF3_hook)
							{
								try
								{
									return this.ctrlF3_hook.call(this,loop_model,nonEmptySearchString);
								}
								catch (e) { this.logError(`CTRLF3 hook failed`,e); }
							}
							
							const toLabel = loop_model.toLabel(this.quotedName); //Can yield NULL
							if (toLabel!==null) { return toLabel.indexOf(nonEmptySearchString)!==-1; }
							
							//If model's toLabel() yield NULL, then check in all cols, one by one
							{
								const cols = loop_item.rowInfo.cols;
								
								for (const loop_colName in cols)
								{
									const loop_col        = cols[loop_colName];
									const loop_modelField = loop_col.modelField;
									if (!(loop_modelField instanceof B_REST_ModelFields.DB)) { continue; } //NOTE: Can also be NULL
									const loop_text = loop_modelField.valToText();
									if (loop_text!==null && loop_text.toString().indexOf(nonEmptySearchString)!==-1) { return true; }
								}
							}
							
							return false;
						},
					//FILTERS
						assertGetModelListServerSearchOptions()
						{
							return this.modelList.searchOptions || this.throwEx(`Needed B_REST_Model_Load_SearchOptions but we don't have`);
						},
						//To save filter & paging prefs to LS
						uiFilters_prefs_checkSaveToLS()
						{
							if (!this.uiFilters_prefs_shouldStore) { return; }
							
							const searchOptions = this.assertGetModelListServerSearchOptions();  //Shouldn't throw if useForLoading, but JIC
							const objOrNULL     = searchOptions.toObj();                         //Rets NULL if we've got nothing special set
							
							if (objOrNULL)
							{
								const objCopy = {...objOrNULL};
								B_REST_Utils.object_removeProp(objCopy, "extraData");
								
								B_REST_Utils.localStorage_set(this.final_uiFilters_prefs_lsKey, B_REST_Utils.json_encode(objCopy), /*isPersistent*/false); //NOTE: Not setting persistent, because if we change user it might not make sense anymore/get access to things we shouldn't
							}
							else { B_REST_Utils.localStorage_remove(this.final_uiFilters_prefs_lsKey); }
						},
						//To load filter & paging prefs from LS
						uiFilters_prefs_checkLoadFromLS()
						{
							if (!this.uiFilters_prefs_shouldStore) { return; }
							
							const jsonOrNULL = B_REST_Utils.localStorage_get(this.final_uiFilters_prefs_lsKey, /*throwIfNull*/false);
							if (jsonOrNULL)
							{
								const obj = B_REST_Utils.json_decode(jsonOrNULL);
								
								const searchOptions = this.assertGetModelListServerSearchOptions();  //Shouldn't throw if useForLoading, but JIC
								searchOptions.fromObj(obj);
							}
						},
						/*
						Helper for global actions in implementation
						Expects an instance of B_REST_Request_base
						*/
						uiFilters_applyToRequest_qsa(request) { return this.assertGetModelListServerSearchOptions().toQSA(request); },
						/*
						Opens & close the <BrGenericListBaseFilters>
						NOTES:
							Check uiFilters_apply() & uiFilters_reset() to see that when panel closes, we get a result obj like {action:<apply|reset>}
						IMPORTANT:
							We won't care about what happens when the panel closes, so if we want the modelList to reload w new filters, usage must take care of it, ex:
								Applying filters:  reloadList()
								Resetting filters: modelList.searchOptions.uiFilters_reset() (not reloading)
						*/
						async uiFilters_togglePanel()
						{
							if (this.uiFilters_uses_inline) { this.uiFilters_inlinePanelOpenState=!this.uiFilters_inlinePanelOpenState; }
							//Otherwise when uiFilters_uses_external, "teleport" the <BrGenericListBaseFilters> in the <BrRightDrawer>
							else
							{
								const rightDrawerOpenMethod = GENERIC_LIST_CONSTS.RIGHT_DRAWER_OPEN_SIDE_BREAKPOINTS.includes(this.currentBreakpoint_name) ? "open_side" : "open_modal";
								
								//Init if not yet done
								if (!this.uiFilters_rightDrawerContent) { this.uiFilters_rightDrawerContent=new B_REST_Vuetify_RightDrawerContent(this.t_alt('filters.label')); }
								
								if (this.uiFilters_rightDrawerContent.isActive) { this.uiFilters_rightDrawerContent.close({action:GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_CANCEL}); }
								else
								{
									const closeData = await this.uiFilters_rightDrawerContent[rightDrawerOpenMethod](); //Yields something like {action}, where action is one of GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_x
									//NOTE: Read method docs about why we do nothing w closeData
								}
							}
						},
						//Rets an instance of B_REST_Vuetify_GenericList_Filter
						uiFilters_getByName(name) { return this._uiFilters_getByName(name,/*throwIfNotFound*/true); },
							_uiFilters_getByName(name, throwIfNotFound=true)
							{
								const uiFilter = this.uiFilters.find(loop_col => loop_col.name===name);
								if (!uiFilter && throwIfNotFound) { this.throwEx(`Filter "${name}" not defined in mixin`); }
								return uiFilter;
							},
						//Helpers
							uiFilters_setVal(name, valOrArrOrNULL)
							{
								const uiFilter = this._uiFilters_getByName(name);
								if (uiFilter.op_is_between) { this.throwEx(`Filter "${name}" is a between, so use uiFilters_setVal_x() & uiFilters_setVal_y() instead`); }
								uiFilter.modelField.val = valOrArrOrNULL;
							},
							uiFilters_setVal_x(name, valOrNull) { this._uiFilters_setVal_between(name,"modelField_x",valOrNull); },
							uiFilters_setVal_y(name, valOrNull) { this._uiFilters_setVal_between(name,"modelField_y",valOrNull); },
								_uiFilters_setVal_between(name, which, valOrNull)
								{
									const uiFilter = this._uiFilters_getByName(name);
									if (!uiFilter.op_is_between) { this.throwEx(`Filter "${name}" isn't a between, so use uiFilters_setVal() instead`); }
									uiFilter[which].val = valOrNull;
								},
							uiFilters_getVal(name)
							{
								const uiFilter = this._uiFilters_getByName(name);
								if (uiFilter.op_is_between) { this.throwEx(`Filter "${name}" is a between, so use uiFilters_getVal_x() & uiFilters_getVal_y() instead`); }
								return uiFilter.modelField.val;
							},
							uiFilters_getVal_x(name, valOrNull) { return this._uiFilters_getVal_between(name,"modelField_x"); },
							uiFilters_getVal_y(name, valOrNull) { return this._uiFilters_getVal_between(name,"modelField_y"); },
								_uiFilters_getVal_between(name, which)
								{
									const uiFilter = this._uiFilters_getByName(name);
									if (!uiFilter.op_is_between) { this.throwEx(`Filter "${name}" isn't a between, so use uiFilters_getVal() instead`); }
									return uiFilter[which].val;
								},
							uiFilters_hide(name) { this._uiFilters_getByName(name).hidden=true;  },
							uiFilters_show(name) { this._uiFilters_getByName(name).hidden=false; },
						//Ret the promise
						async uiFilters_apply()
						{
							if (this.uiFilters_shouldClosePanel) { this._uiFilters_done(GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_APPLY); }
							
							this.uiFilters_prefs_checkSaveToLS();
							return this.reloadList();
						},
						//Ret the promise
						async uiFilters_reset(skipHidden=true)
						{
							if (this.uiFilters_shouldClosePanel) { this._uiFilters_done(GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_RESET); }
							
							for (const loop_uiFilter of this.uiFilters)
							{
								if (skipHidden && loop_uiFilter.hidden) { continue; }
								
								if (loop_uiFilter.op_is_between)
								{
									loop_uiFilter.modelField_x.clear(); loop_uiFilter.modelField_x.userTouch_toggle(true);
									loop_uiFilter.modelField_y.clear(); loop_uiFilter.modelField_y.userTouch_toggle(true);
								}
								else { loop_uiFilter.modelField.clear(); loop_uiFilter.modelField.userTouch_toggle(true); }
							}
							
							if (GENERIC_LIST_CONSTS.RELOAD_ON_RESET_FILTERS)
							{
								this.uiFilters_prefs_checkSaveToLS(); //If we put this out of this if, means that we should also call uiFilters_prefs_checkSaveToLS() whenever any filter is changed, even if we don't submit, which is maybe not good
								
								//Ret the promise
								return this.reloadList();
							}
						},
							_uiFilters_done(action)
							{
								if      (this.uiFilters_uses_inline)                                                 { this.uiFilters_inlinePanelOpenState=false;         }
								else if (this.uiFilters_uses_external && this.uiFilters_rightDrawerContent.isActive) { this.uiFilters_rightDrawerContent.close({action}); }
							},
					async on_selectedItems_change()
					{
						//If we're in single picker mode, as soon as we check something we should close - just do it delayed so we have time to see the checkbox animation
						if (this.selectedItems_has && this.final_isPicker_isSingle)
						{
							await this.coreMixin_sleep_vuetifyAnimationDuration();
							this.on_picker_submit();
						}
					},
					on_multiplePickerFooter_removeChip(item) { B_REST_Utils.array_remove_byVal(this.selectedItems,item); },
					//PICKER
						on_picker_cancel() { this.pickerHandle.cancel(); }, //Closes the picker
						on_picker_submit()
						{
							const selectedModels = [];
							for (const loop_selectedItem of this.selectedItems) { selectedModels.push(loop_selectedItem.rowInfo.model); }
							
							this.pickerHandle.submit(this.final_isPicker_isMultiple ? selectedModels : selectedModels[0]); //Closes the picker
						},
					//OPEN FORM / PICKER RELATED
						//Rets model once created/saved
						async openFormInVDialog(pkTag=null)
						{
							const promise = new Promise((s,f) => this.openFormInVDialog_resolver=s);
							
							//NOTE: We don't pass any extra data, even if it was for a picker, because behavior should be the same whether we go in the form manually or from a picker (for now)
							this.openFormInVDialog_vBind = {
								fromPkTag:        pkTag,
								fromNew:          pkTag===null,
								parent_pkTag:     this.parent_pkTag,                                  //Could be NULL
								parent_modelName: this.parent_modelName,                              //Could be NULL
								parent_routeName: this.subModelListOptions?.parent_routeName ?? null, //When it's used in a BrGenericFormBaseSubModelList
								parent_saveCallback: (model) =>
								{
									if (!model || model.isNew) { this.throwEx(`parent_saveCallback should always ret a saved model`); }
									this._openFormInVDialog_resolve(model);
								},
								closable: true,
							};
							this.openFormInVDialog_show = true;
							
							return promise;
						},
						openFormInVDialog_cancel() { this._openFormInVDialog_resolve(null); },
							_openFormInVDialog_resolve(model=null)
							{
								this.openFormInVDialog_show = false;
								this.openFormInVDialog_resolver(model!==null);
								
								if (this.pickerHandle)
								{
									if (model) { this.pickerHandle.submit(this.final_isPicker_isMultiple?[model]:model); } //Closes the picker
									else       { this.pickerHandle.cancel();                                             } //Maybe annoying to do so
								}
								//Show changes
								else if (this.fromLoader)    { this.reloadList();                     }
								else if (this.fromModelList) { this.throwEx(`Not supported for now`); }
							},
					//QUICK ADD FORM RELATED
						//Do this at the beginning and once after every save, so we can start with a fresh new model
						quickAddForm_renewModel()
						{
							this.quickAddForm_model = B_REST_Model.commonDefs_make(this.modelName);
						},
						//Clears up the quick add form, so we can add another one, or next time we reuse the list it'll still be ok
						quickAddForm_cleanup() { this.quickAddForm_renewModel(); },
						/*
						Calls hook, potentially confirming first
						Depending on usage, hook must do some of:
							-Validating if we should add w model.validation_isValid
							-Doing const savedSomething = model.awaitUnsavedChangesSaved({...})
							-Doing modelList.prepend(model)
							-Doing modelList.add(model)
						For ex:
							-If it's for adding an invoiceDetail to an invoice, we prolly just want to add it and save later
							-If we're in a general list, we prolly want to always save the model right away,
								however maybe not add it to the actual modelList if it was pre-sorted or paginated and wouldn't make visual sense to see it there
						If hook resolves, will call quickAddForm_renewModel() so we can add again
						WARNING:
							If we decide to save the model in the hook and that it should have an FK to something, don't forget to do model.select("someFK").val=123
						*/
						async on_quickAddForm_submit()
						{
							this.isDoingUserHook = true;
							
							if (this.quickAddForm_mustConfirm || this.quickAddForm_displayResult)
							{
								this.xPrompt = new B_REST_Vuetify_Prompt(this.quickAddForm_title, true/*isModal*/);
							}
							
							//If we must freeze and ask user for confirmation
							let canContinue = true;
							if (this.quickAddForm_mustConfirm)
							{
								this.xPrompt.actions = [
									new B_REST_Vuetify_Prompt_Action("cancel", this.t_alt("prompt.cancel"), null), //NOTE: We have "prompt.no" too...
									null,
									new B_REST_Vuetify_Prompt_Action("yes", this.t_alt("prompt.yes"), "error"),
								];
								
								this.xPrompt.body = this.pickerHandle ? this.t_alt('quickAddForm.confirm.picker') : this.t_alt('quickAddForm.confirm.normal');
								
								const selectedActionOrNull = await this.xPrompt.show();
								if (selectedActionOrNull!=="yes") { canContinue=false; }
							}
							
							//Then do the action hook
							if (canContinue)
							{
								let success = false;
								
								try
								{
									//We count throwing errs and ret false as non success
									success = await this.quickAddForm_hook.call(this,this.modelList,this.quickAddForm_model);
								}
								catch (e) { this.logError(`Quick add form submit failed`,e); }
								
								//If we want to display whether it worked or not
								if (this.quickAddForm_displayResult)
								{
									const msg   = success ? this.t_alt('quickAddForm.success') : this.t_alt('quickAddForm.failure');
									const color = success ? "success"                          : "error";
									this.$bREST.notifs_tmp({msg,color});
								}
								
								this.xPrompt = null;
								
								if (success)
								{
									//If we're in picker mode, we'll also auto put it in selection
									if (this.pickerHandle)
									{
										const fakeItem = this.toVDataTableItem(this.quickAddForm_model);
										
										if (this.final_isPicker_isMultiple) { this.selectedItems.push(fakeItem); }
										else
										{
											this.selectedItems = [fakeItem];
											//WARNING: Don't do on_picker_submit() here (as long as we only have selectedItems to control selection and not 2 vars), because Vuetify will auto call it via on_selectedItems_change()
										}
									}
									
									//Always clear up the quick add form, so we can add another one, or next time we reuse the list it'll still be ok
									this.quickAddForm_cleanup();
								}
							}
							
							this.isDoingUserHook = false;
						},
					
					//ACTION HELPERS
						/*
						To run API call on multiple models at the same time, either specifying some B_REST_Model_Load_SearchOptions filters, explicit B_REST_Model instances or just PKs,
							ex to batch send emails, print stuff, change statuses or delete records
						Check related:
							Server's RouteParser_base::genericListFormModule_helper_batchAction() & RouteParser_base::_overridable_genericListFormModule_action_del()
							Frontend's B_REST_Descriptor::batchAPICall_x(), B_REST_Model::commonDefs_batchAPICall_x(), B_REST_ModelList::batchAPICall_x(), B_REST_ModelList::deleteSome(), BrGenericListBase::xActions_helper_batchAction_x(), B_REST_Vuetify_GenericList_Action::defineCommonAction_delete()
						Params:
							action:                     Ptr on a <B_REST_Vuetify_GenericList.XAction>action
							apiBaseUrl:                 Ex "/clients/{pkTag}/contracts/{contact_pk}"
							apiBaseUrl_path_vars:       For when apiBaseUrl is like "/clients/{pkTag}/contracts/{contact_pk}" and we need to pass {pkTag,contract_pk}
							modelOrModelsOrAllFiltered: B_REST_Model instance of arr of them, but never NULL. If empty, means we want to use list's search options filters as source
							options: {
								requestBody:             Either NULL or {} to be passed in POST etc
								expectsContentType:      One of B_REST_Utils.CONTENT_TYPE_x, or check B_REST_Request::expectsContentType_x(). Ex if csv/pdf, will auto download
								throwIfMoreThanXMatches: If not NULL, will block action at all if we target too many records. Good to prevent hell ex w deletions
								handler:                 If set, default behavior for toasting result via B_REST_App_base::notifs_tmp() won't happen and must be done via handler. Func as (responseOrDownloadResultOrNULL=null). Must resolve w bool
								thenReloadList:          Useful ex for when we del records. Ignored if a download call (ex csv/pdf) or when handler is specified
							}
						Action's success & failure loc keys will be able to access the following details:
						{
							totalCount,
							successes_count,
							successes_pks,
							successes_labels,
							failures_count,
							failures_pks,
							failures_labels,
						}
							Ex:
								printContracts: {
									label:   "Print",
									success: "Printed {successes_count} contracts ({successes_labels})",
									failure: "Could print {successes_count}/{totalCount} contracts but couldn't for {failures_labels}",
								}
						Usage ex, in par w server's RouteParser_base::genericListFormModule_helper_batchAction() usage ex docs, for global action only, row action only, or having btn at both places:
							globalActions: {
								...
								changeStatus: {
									click: {
										...
										async hook(action,selectedModels,isCtrlClickOrMiddleClick)
										{
											return this.xActions_helper_batchAction_PATCH(action, "/ft/{type}/changeStatus",{type:"invoice"}, selectedModels, {requestBody:{status:"closed"},thenReloadList:true});
										},
									},
									...
								},
								...
							},
							rowActions: {
								...
								changeStatus: {
									click: {
										...
										async hook(action,model,isCtrlClickOrMiddleClick)
										{
											return this.xActions_helper_batchAction_PATCH(action, "/ft/{type}/changeStatus",{type:"invoice"}, model, {requestBody:{status:"closed"},thenReloadList:true});
										},
									},
									...
								},
								...
							},
								-> For how to deal w these in server, check server's RouteParser_base::genericListFormModule_helper_batchAction() usage ex docs
						Other usage ex, for CSV download:
							globalActions: {
								...
								export: {
									click: {
										...
										async hook(action,selectedModels,isCtrlClickOrMiddleClick)
										{
											return this.xActions_helper_batchAction_GET_File_csv(action, "/clients/export",{}, selectedModels);
										},
									},
									...
								},
								...
							},
								-> For how to deal w these in server, check server's RouteParser_base::genericListFormModule_helper_batchAction() usage ex docs
						For a delete usage ex, check B_REST_Vuetify_GenericList_Action_x::defineCommonAction_delete(). In server, has nothing to do, as long as it's a RouteParser_base::_asGenericListFormModule()
						NOTE:
							-When using this, B_REST_Vuetify_GenericList_Action_base::displayResult should be set to false, or we could get duplicate notifications
							-We have all these verbs in B_REST_API: GET | GET_File | POST | POST_File | POST_Multipart | PUT | PUT_Multipart | PATCH | PATCH_Multipart | DELETE
						*/
						async xActions_helper_batchAction_GET(         action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options={}) { return this._xActions_helper_batchAction_x("batchAPICall_GET",         action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options); },
						async xActions_helper_batchAction_POST(        action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options={}) { return this._xActions_helper_batchAction_x("batchAPICall_POST",        action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options); },
						async xActions_helper_batchAction_PUT(         action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options={}) { return this._xActions_helper_batchAction_x("batchAPICall_PUT",         action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options); },
						async xActions_helper_batchAction_PATCH(       action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options={}) { return this._xActions_helper_batchAction_x("batchAPICall_PATCH",       action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options); },
						async xActions_helper_batchAction_DELETE(      action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options={}) { return this._xActions_helper_batchAction_x("batchAPICall_DELETE",      action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options); },
						async xActions_helper_batchAction_GET_File_csv(action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options={}) { return this._xActions_helper_batchAction_x("batchAPICall_GET_File_csv",action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,{...options,expectsContentType:B_REST_Utils.CONTENT_TYPE_CSV}); }, //IMPORTANT: Don't rem the injected expectsContentType, or the isFileDownload const logic will fail
							async _xActions_helper_batchAction_x(descriptorMethodName, action,apiBaseUrl,apiBaseUrl_path_vars,modelOrModelsOrAllFiltered,options={})
							{
								options = B_REST_Utils.object_hasValidStruct_assert(options, {
									requestBody:             {accept:[null,Object],   default:null},
									expectsContentType:      {accept:[String],        default:B_REST_Utils.CONTENT_TYPE_JSON},
									throwIfMoreThanXMatches: {accept:[null,Number],   default:null},
									handler:                 {accept:[null,Function], default:null},
									thenReloadList:          {accept:[Boolean],       default:false},
								}, "BrGenericListBase::_xActions_helper_batchAction_x()");
								
								//KISS
								if (!B_REST_Utils.array_is(modelOrModelsOrAllFiltered)) { modelOrModelsOrAllFiltered=[modelOrModelsOrAllFiltered]; }
								
								const isFileDownload    = GENERIC_LIST_CONSTS.HELPER_BATCH_ACTION_DOWNLOADABLE_CONTENT_TYPES.includes(options.expectsContentType); //NOTE: If eventually we need to have some content types work both as download or not, should have an option to hint whether it should be downloadable or not
								const use_searchOptions = modelOrModelsOrAllFiltered.length===0;
								
								//Stop right now if we selected too many and we care
								if (options.throwIfMoreThanXMatches!==null)
								{
									const count = modelOrModelsOrAllFiltered.length || this.final_server_nbRecords; //Meaning, if length is 0 it's because we wanted to go w modelList.searchOptions
									if (count>options.throwIfMoreThanXMatches)
									{
										B_REST_Utils.console_error(`Targetting too many records (${count}/${options.throwIfMoreThanXMatches}`);
										
										if (options.handler) { return options.handler(null); } //NOTE: We ignore thenReloadList here. Could add a 2nd param to give info as to why handler got response=NULL
										
										const msg = this.$bREST.t_core(`app.components.BrGenericListBase.globalActions.genericState.tooManySelections`,{count,max:options.throwIfMoreThanXMatches});
										this.$bREST.notifs_tmp({msg, color:"error"});
										return false;
									}
								}
								
								//Throws only if we provided wrong options or if B_REST_Response::debug_isDump
								const responseOrDownloadResult = await this.descriptor[descriptorMethodName]({
									apiBaseUrl,
									apiBaseUrl_path_vars,
									isFileDownload,
									apiBaseUrl_needsAccessToken: true, //HC, because really, why would an anonymous/public user be allowed to del module stuff ?
									use_searchOptions:           use_searchOptions  ? this.modelList.searchOptions : null,
									use_models:                  !use_searchOptions ? modelOrModelsOrAllFiltered   : null,
									use_pks:                     null, //NOTE: For now, we can't pass PKs directly, but we could eventually
									requestBody:                 options.requestBody,
									expectsContentType:          options.expectsContentType,
									uploadProgressCallback:      null, //Could be useful to add eventually
									downloadProgressCallback:    null, //Could be useful to add eventually
								});
								
								if (isFileDownload)  { return true;                                      } //NOTE: We ignore thenReloadList here. Also don't ret responseOrDownloadResult, because actions expects a bool as ret
								if (options.handler) { return options.handler(responseOrDownloadResult); } //NOTE: We ignore thenReloadList here
								
								const response     = responseOrDownloadResult; //If we get here, this is always a B_REST_Response
								let   isSuccessful = response?.isSuccess ?? false;
								let   msg          = null;
								
								if (options.expectsContentType===B_REST_Utils.CONTENT_TYPE_JSON && isSuccessful)
								{
									const stats = response.data?.stats ?? null;
									
									if (stats)
									{
										isSuccessful = stats.allSuccessful;
										
										msg = action.getMsg_x(isSuccessful?"success":"failure", {
											totalCount:       stats.totalCount,
											successes_count:  stats.successes.count,
											successes_pks:    stats.successes.pks,
											successes_labels: stats.successes.labels,
											failures_count:   stats.failures.count,
											failures_pks:     stats.failures.pks,
											failures_labels:  stats.failures.labels,
										});
									}
									else
									{
										B_REST_Utils.console_error(`Response had to ret a {stats} node as per RouteParser_base::genericListFormModule_helper_batchAction()`);
										isSuccessful = false;
										//We don't set msg here, so code below takes care of it
									}
								}
								
								//If we get here, it's because either it was non-JSON response, or because it was a JSON that had a server error
								if (msg===null)
								{
									msg = this.$bREST.t_core(`app.components.BrGenericListBase.globalActions.genericState.${isSuccessful?"success":"failure"}`);
								}
								
								this.$bREST.notifs_tmp({msg, color:isSuccessful?"success":"error"});
								if (options.thenReloadList)
								{
									this.selectedItems = []; //Do this, or we'll think we have nothing more selected but will, and headbang to try to debug
									this.reloadList();
								}
								
								return isSuccessful;
							},
				},
			},
		];
	};
	
	/*
	Usage ex:
		//From dynamic import func
		const someComponent = () => import("./someComponent.vue");
		const isGenericList = await B_REST_Vuetify_GenericListBase_isDerivedComponentImport(someComponent);
		
		//From static import
		import SomeComponent from "./SomeComponent.vue";
		const isGenericList = await B_REST_Vuetify_GenericListBase_isDerivedComponentImport(SomeComponent);
	NOTE: Doing this 100 times won't slow down the app or cause mem leak. Vue/Webpack will ret the same obj ptr
	*/
	export async function B_REST_Vuetify_GenericListBase_isDerivedComponentImport(dynamicImportFunc_or_staticImport)
	{
		return B_REST_Vuetify_GenericListBase_getMixinOptions(dynamicImportFunc_or_staticImport)!==null;
	};
		//If component is a BrGenericListBase der, rets what was passed to B_REST_Vuetify_GenericListBase_createMixin()
		export async function B_REST_Vuetify_GenericListBase_getMixinOptions(dynamicImportFunc_or_staticImport)
		{
			const options = await B_REST_VueApp_base.getVueComponentOptions(dynamicImportFunc_or_staticImport);
			return options.mixins?.length===2 && options.mixins[1].isGenericListBase===true ? options.mixins[1] : null;
		};
	
	function validator_fromLoader(val)
	{
		if (val===undefined || val===true || B_REST_Utils.object_is(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "fromLoader" to be not set, true, or an obj like {apiBaseUrl<string>:null, apiBaseUrl_path_vars<string>:null, reloader<async(modelList)>:null}`);
		return false;
	}
	
</script>

<style scoped>
	
	.br-generic-list {
		overflow-x: hidden; /* Req if uiFilters_uses_inline, otherwise when inline filter drawer is closed, its translateX transform causes a huge gap to be scrollable to the right */
	}
	
</style>