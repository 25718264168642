
import { B_REST_Utils } from "@/bREST/core/classes";
import { default as MyApp_Class } from "@/custom/App.js";
const MyApp = MyApp_Class.instance;
import RegFlowFilter from "./RegFlowFilter.js";



/*
Note about not having session_fk & franchisee_fk filters for when we use this for presential & virtual calendar management:
	-For now, in server's RouteParser_Calendar (and Calendar.js), API paths for management contain the session & franchisee like:
		"calendar/management/{session}/presential/{franchisee}/{range}", so as pathVars
	-If we change these to filters, then we must change the paths to no longer include {session} and {franchisee} (not a prob)
	-Then we'd also need to indicate which filters should be editable by the user, vs being HC
	-But changing these to filters would make it easier if later we do want let the user decide of a session & franchisee (in some cases)
	-However, for now, <reg-flow-filter> converts everything to <v-chip> and expects, but session & franchisee should become dropdowns
*/



export default class RegFlowFilters
{
	static get FILTER_NAME_CONFIG_PROGRAMS()   { return "configPrograms";    }
	static get FILTER_NAME_FRANCHISEE_PARKS()  { return "franchiseeParks";   }
	static get FILTER_NAME_STATS_MUSCULATION() { return "stats_musculation"; }
	static get FILTER_NAME_STATS_FLEXIBILITY() { return "stats_flexibility"; }
	static get FILTER_NAME_STATS_INTENSITY()   { return "stats_intensity";   }
	static get FILTER_NAME_STATS_CARDIO()      { return "stats_cardio";      }
	static get FILTER_NAME_STATS_MOVEMENT()    { return "stats_movement";    }
		static get FILTER_NAMES() { return RegFlowFilters._FILTER_NAMES; }
			static _FILTER_NAMES = [
				RegFlowFilters.FILTER_NAME_CONFIG_PROGRAMS,
				RegFlowFilters.FILTER_NAME_FRANCHISEE_PARKS,
				RegFlowFilters.FILTER_NAME_STATS_MUSCULATION,
				RegFlowFilters.FILTER_NAME_STATS_FLEXIBILITY,
				RegFlowFilters.FILTER_NAME_STATS_INTENSITY,
				RegFlowFilters.FILTER_NAME_STATS_CARDIO,
				RegFlowFilters.FILTER_NAME_STATS_MOVEMENT,
			];
				//Because they don't want to see stats ones for now
				static get FILTER_NAMES_EXCLUDING_STATS() { return RegFlowFilters._FILTER_NAMES_EXCLUDING_STATS; }
					static _FILTER_NAMES_EXCLUDING_STATS = [
						RegFlowFilters.FILTER_NAME_CONFIG_PROGRAMS,
						RegFlowFilters.FILTER_NAME_FRANCHISEE_PARKS,
					];
	
	//Instances of RegFlowFilter
	_configPrograms     = null;
	_franchiseeParks    = null;
	_stats_musculation  = null;
	_stats_flexibility  = null;
	_stats_intensity    = null;
	_stats_cardio       = null;
	_stats_movement     = null;
		//IMPORTANT: Check note above for if we ever want to add filters for session_fk & franchisee_fk
	
	
	constructor()
	{
		this._configPrograms    = RegFlowFilter.create_sharedList("configProgramList", RegFlowFilters.makeFilterLabel("configPrograms"));
		this._franchiseeParks   = RegFlowFilter.create_custom([], RegFlowFilters.makeFilterLabel("franchiseeParks")); //Start w no items, and populate on demand
		this._stats_musculation = RegFlowFilter.create_enumField("ConfigProgram", "stats_musculation");
		this._stats_flexibility = RegFlowFilter.create_enumField("ConfigProgram", "stats_flexibility");
		this._stats_intensity   = RegFlowFilter.create_enumField("ConfigProgram", "stats_intensity");
		this._stats_cardio      = RegFlowFilter.create_enumField("ConfigProgram", "stats_cardio");
		this._stats_movement    = RegFlowFilter.create_enumField("ConfigProgram", "stats_movement");
	}
	
	static makeFilterLabel(sharedListName) { return MyApp.t_custom(`components.regFlowFilters.filters.${sharedListName}`); }
	
	//Must match w FILTER_NAME_x
	get configPrograms()    { return this._configPrograms;    }
	get franchiseeParks()   { return this._franchiseeParks;   }
	get stats_musculation() { return this._stats_musculation; }
	get stats_flexibility() { return this._stats_flexibility; }
	get stats_intensity()   { return this._stats_intensity;   }
	get stats_cardio()      { return this._stats_cardio;      }
	get stats_movement()    { return this._stats_movement;    }
	
	//As a map of {<filterName>:<arr of pkOrEnumTag>}
	selections_addMultiple(filters)
	{
		for (const loop_filterName in filters)
		{
			if (!B_REST_Utils.object_hasPropName(this,loop_filterName)) { B_REST_Utils.throwEx(`Unknown filter "${loop_filterName}"`); }
			
			this[loop_filterName].selections_addMultiple(filters[loop_filterName]);
		}
	}
	selections_clearAll() { for (const loop_filterName of RegFlowFilters.FILTER_NAMES) {this[loop_filterName].selections_clear();} }
};
