<template>
	<v-main class="public-layout">
		<div class="fill-height full-bg-img" :data-file-name="bg_fileName">
			<router-view />
		</div>
		<p v-if="$bREST.currentSessionInfo_debug_show" class="text-caption ma-2" style="position:fixed;right:24px;top:5px;z-index:1000;width:300px;" v-html="$bREST.currentSessionInfo_debug_html" />
	</v-main>
</template>

<script>
	
	export default {
		computed: {
			bg_fileName()
			{
				const session_fk = this.$bREST.currentSessionInfo_session_fk;
				const season     = this.$bREST.sharedLists_getSrc("sessionList").get_byPK(session_fk).select("season").val;
				return `${season}-${this.$bREST.uiBreakpoint.name==="xs"?"mobile":"desktop"}`;
			},
		},
	};
	
</script>

<style scoped>
	
	.full-bg-img {
		background-repeat:   no-repeat;
		background-size:     cover;
		background-position: center;
	}
		.full-bg-img[data-file-name="autumn-desktop"]  { background-image: url("/_businessFiles/publicBgs/autumn-desktop.jpg"); }
		.full-bg-img[data-file-name="autumn-mobile"]   { background-image: url("/_businessFiles/publicBgs/autumn-mobile.jpg");  }
		.full-bg-img[data-file-name="spring-desktop"]  { background-image: url("/_businessFiles/publicBgs/spring-desktop.jpg"); }
		.full-bg-img[data-file-name="spring-mobile"]   { background-image: url("/_businessFiles/publicBgs/spring-mobile.jpg");  }
		.full-bg-img[data-file-name="summer-desktop"]  { background-image: url("/_businessFiles/publicBgs/summer-desktop.jpg"); }
		.full-bg-img[data-file-name="summer-mobile"]   { background-image: url("/_businessFiles/publicBgs/summer-mobile.jpg");  }
		.full-bg-img[data-file-name="winter-desktop"]  { background-image: url("/_businessFiles/publicBgs/winter-desktop.jpg"); }
		.full-bg-img[data-file-name="winter-mobile"]   { background-image: url("/_businessFiles/publicBgs/winter-mobile.jpg");  }
	
</style>