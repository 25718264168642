<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="name" for-lang="fr" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="name" for-lang="en" /> </v-col>
				<!-- 🚀↑app>modules>x>XForm.vue>template>fields↑🚀 -->
			</v-row>
		</template>
		
		<!-- 🚀↑app>modules>x>XForm.vue>template>slots↑🚀 -->
		
		
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericForm } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "configSourceMarketingForm",
		components: {
			/* 🚀↑app>modules>x>XForm.vue>js>components↑🚀 */
		},
		mixins: B_REST_Vuetify_GenericForm.createMixin({
			modelName:          "ConfigSourceMarketing",
			apiBaseUrl:         "/configSourceMarketings/",
			showValidationErrs: true,    //🚀❓ If we want red err msgs near the save btn at the bottom of the form ❓🚀
			showSkeletonLoader: true,    //🚀❓ While we load an existing record, do we want a grey "skeleton" overlay ? ❓🚀
		  //autoUpdateInterval: 2000,    //🚀❓ If we want that blurring fields auto save modifs every X msecs ❓🚀
			requiredFields:     "<all>", //🚀❓ Check server's ModelOptions_base.php docs for "fieldNamePath" possibilities ❓🚀
			todos: [
				//🚀❓ Arr of {isDone,isBug,text} that will appear automatically at the top of the form ❓🚀
			],
			async modelReady()
			{
				/*
					🚀❓
						When a new record, called right on component mount.
						When an existing record, called after it's done loading & afterLoad() hook done
						Usage ex, where we also adjust for possible cases where we'd get a parent pk
							if (this.model.isNew)
							{
								//For when route is like /citizens/:citizen/animals/:pkTag, or we're from a BrGenericListBase::openFormInVDialog(), etc. Can get better cue parent_modelName or parent_routeName. Check props docs
								if (this.parent_pkTag) { this.model.select("citizen_fk").val=this.parent_pkTag; }
							}
					🚀❓
				*/
			},
			async afterLoad(response,models)                 { }, //🚀❓ When an existing record, a hook where we still have the API B_REST_Response available to pimp the model. Called before modelReady() ❓🚀
			async customValidator()                          { }, //🚀❓ Called at the beginning of awaitUnsavedChangesSaved_x() to check if it's ok to save. Can also be called manually. Should put customErrorList.x_add/x_if() here. Check BrGenericFormBase.vue::customErrors_x() docs or how we use it ex in UserForm.vue or MyProfile.vue ❓🚀
			async beforeSave(request,model)                  { }, //🚀❓ When we want to save, a hook so we can pimp the API B_REST_Request_x that will be sent to the server ❓🚀
			async afterSave(response,model,isSuccess,wasNew) { }, //🚀❓ Like afterLoad(), we just saved and we still have access to the API B_REST_Response, to do extra stuff ❓🚀
		}),
		data()
		{
			return {
				dataSets: {
					/* 🚀↑app>modules>x>XForm.vue>js>dataSets↑🚀 */
				},
			};
		},
		computed: {
			
		},
		methods: {
			
		},
	};
	
</script>