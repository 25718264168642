
import { B_REST_Utils }           from "@/bREST/core/classes";
import CalendarEvent              from "./CalendarEvent.js";
import CalendarEventClient        from "./CalendarEventClient.js";
import RegFlow                    from "@/custom/components/regFlow/RegFlow.js";
import RegFlowFilters             from "@/custom/components/regFlow/filters/RegFlowFilters.js";
import { default as MyApp_Class } from "@/custom/App.js";
const MyApp = MyApp_Class.instance;



export default class Calendar
{
	static get EVENT_TIME_MINS_MULTIPLE()                         { return  5; } //IMPORTANT: Server's Model_Event::EVENT_TIME_MINS_MULTIPLE must match w frontend's Calendar::EVENT_TIME_MINS_MULTIPLE. Makes sure events start & end at common minutes, ex 00,15,30,45, or 00,05,10,15... + how often a cron is supposed to call Model_CurrentSessionInfo::setNow_clipped()
	static get EVENT_STAFF_PUNCH_CAME_NO_SHOW_MINS_BEFORE_START() { return 30; } //IMPORTANT: Server's Model_Event::EVENT_STAFF_PUNCH_CAME_NO_SHOW_MINS_BEFORE_START must match w frontend's Calendar::EVENT_STAFF_PUNCH_CAME_NO_SHOW_MINS_BEFORE_START. Only makes sense if unregging from future events doesn't care about attendance status.
	
	
	static get VIEW_TYPE_MONTH()           { return "month";         }
	static get VIEW_TYPE_WEEK()            { return "week";          }
	static get VIEW_TYPE_DAY()             { return "day";           }
	static get VIEW_TYPE_CUSTOM_DT_RANGE() { return "customDTRange"; } //NOTE: Not supported in Calendar.vue; intended to be used manually elsewhere, and should be for PURPOSE_MY_SCHEDULE
	
	static get PURPOSE_MANAGEMENT_PRESENTIAL() { return "managementPresential"; } //When admin manages a session's fixed weekly template, restricted to a session
	static get PURPOSE_REG_FLOW_PRESENTIAL()   { return "regFlowPresential";    } //When client regs to a session's fixed weekly template, restricted to a session
	static get PURPOSE_REG_FLOW_MAKEUP()       { return "regFlowMakeUp";        } //When client books makeUps, being able to nav through time, restricted to a session
	static get PURPOSE_REG_FLOW_TRIAL()        { return "regFlowTrial";         } //When client books trials, being able to nav through time, not restricted to a session
	static get PURPOSE_MY_SCHEDULE()           { return "mySchedule";           } //When client & staff navigate through time to see their stuff, not restricted to a session
	static _PURPOSE_CONFIGS = {
		managementPresential: {isManagement:true,  isRegFlow:false, isMySchedule:false, regFlowPlaceCountKeyName:null,     isWeeklyTemplateMode:true},
		regFlowPresential:    {isManagement:false, isRegFlow:true,  isMySchedule:false, regFlowPlaceCountKeyName:"normal", isWeeklyTemplateMode:true},
		regFlowMakeUp:        {isManagement:false, isRegFlow:true,  isMySchedule:false, regFlowPlaceCountKeyName:"makeUp", isWeeklyTemplateMode:false},
		regFlowTrial:         {isManagement:false, isRegFlow:true,  isMySchedule:false, regFlowPlaceCountKeyName:"trial",  isWeeklyTemplateMode:false},
		mySchedule:           {isManagement:false, isRegFlow:false, isMySchedule:true,  regFlowPlaceCountKeyName:null,     isWeeklyTemplateMode:false},
	};
	
	//Where [0] = Sunday. Needs this for Vuetify, but also to keep view_shift_x() code simple
	static get WEEKLY_TEMPLATE_MODE_DUMMY_DATES() { return Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES; }
		//WARNING: To check that 2 dates are equal, use date.getTime() and not just == or ===. Also, if ever we get a new Date() from outside and it didn't specify His, dates might never match
		static _WEEKLY_TEMPLATE_MODE_DUMMY_DATES = [
			{dt:B_REST_Utils.dt_fromYmd("2051-01-01"), Ymd:"2051-01-01"},
			{dt:B_REST_Utils.dt_fromYmd("2051-01-02"), Ymd:"2051-01-02"},
			{dt:B_REST_Utils.dt_fromYmd("2051-01-03"), Ymd:"2051-01-03"},
			{dt:B_REST_Utils.dt_fromYmd("2051-01-04"), Ymd:"2051-01-04"},
			{dt:B_REST_Utils.dt_fromYmd("2051-01-05"), Ymd:"2051-01-05"},
			{dt:B_REST_Utils.dt_fromYmd("2051-01-06"), Ymd:"2051-01-06"},
			{dt:B_REST_Utils.dt_fromYmd("2051-01-07"), Ymd:"2051-01-07"},
		];
		//Read the above warning
			static _WEEKLY_TEMPLATE_MODE_DUMMY_DATES_SUNDAY_TIME   = Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES[0].dt.getTime();
			static _WEEKLY_TEMPLATE_MODE_DUMMY_DATES_SATURDAY_TIME = Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES[6].dt.getTime();
	
	//For API calls in 
	static get VIEW_UPDATE_QSA_FILTERS()     { return "f"; }
	static get VIEW_UPDATE_RANGE_DELIMITER() { return "~"; }
	
	
	_purposeTag                 = null;                  //One of PURPOSE_x. Check their docs for what they mean
	_purposeConfig              = null;                  //Ptr to a _PURPOSE_CONFIGS obj behind the purposeTag
	_viewType                   = null;                  //One of VIEW_TYPE_x
	_date                       = null;                  //Date that's always visible in the current viewType, as DateTime. For isWeeklyTemplateMode purposes, points on a WEEKLY_TEMPLATE_MODE_DUMMY_DATES. Ignored in VIEW_TYPE_CUSTOM_DT_RANGE
	_rangeTitle                 = {main:null,sub:null};  //As {main,sub} label parts
	_range_out_from             = null;                  // Limit from, for fetching events. In VIEW_TYPE_MONTH; points to the very first date block in the calendar, usually outside the current month
	_range_in_from              = null;                  // Used for label. Inclusive date from in the current view. If in VIEW_TYPE_MONTH, always point to xx-01
	_range_in_to                = null;                  // Used for label. Inclusive date to in the current view. If in VIEW_TYPE_MONTH, always point to xx-28/31
	_range_out_to               = null;                  // Limit to, for fetching events. In VIEW_TYPE_MONTH; points to the very last date block in the calendar, usually outside the current month
	_filters                    = null;                  //Optional RegFlowFilters instance
	_ifManagement_session_fk    = null;                  //If purpose config isManagement, we must limit display to a given session; note that we -could- have session_fk be part of RegFlowFilters. Check RegFlowFilters.js docs for why not for now
	_ifManagement_franchisee_fk = null;                  //Same as for ifManagement_session_fk (except in virtual we don't need this; but we don't have a virtual schedule for now anyways), we also can't display multiple franchisee schedules at the same time (unless it's virtual, for now)
	_ifRegFlow_instance         = null;                  //Instance of RegFlow, if isRegFlow
	_ifCustomDTRange_dt_from    = null;                  //If in VIEW_TYPE_CUSTOM_DT_RANGE, mandatory Date instance
	_ifCustomDTRange_dt_to      = null;                  //Same as the above
	_events                     = [];                    //Arr of CalendarEvent instances
	_isLoading                  = true;
	_hooks_afterViewUpdate      = [];                    //Arr of function(calendar,errorMsg=null)
	
	
	
	/*
	Expecting an obj like:
		{
			purposeTag:                 One of PURPOSE_x
			viewType:                   One of VIEW_TYPE_x
			ifWeeklyTemplate_weekday:   Initial weekday of the template (0=sunday), that will appear inside the wanted viewType (check _PURPOSE_CONFIGS for which are weekly templates). If not set, will default to the weekday behind App.js::currentSessionInfo_dt_now()
			                                WARNING: Says always start on mondays
			ifNavigable_date:           Same logic as ifWeeklyTemplate_weekday, except we can set any date in time. Ignored in VIEW_TYPE_CUSTOM_DT_RANGE. WARNING: If we pass a "new Date('Y-m-d')" wo H:i:s, will appear off by -1 day because of timezone. Should use B_REST_Utils::dt_fromYmd() to avoid this prob
			filters:                    Any set filters, as a map of {RegFlowFilters::FILTER_NAME_x:<arr of pkOrEnumTag>}. Check RegFlowFilters for info on which filters we have
			ifManagement_session_fk:    Check var docs
			ifManagement_franchisee_fk: Check var docs
			ifRegFlow_instance:         Check var docs
			afterViewUpdate:            Check hooks_afterViewUpdate var docs. Here, we can pass just 1 func, otherwise can be done later via hooks_afterViewUpdate_add()
		}
	NOTE: Doesn't do an initial load / display of the right view; call updateView_reloadEvents() manually
	*/
	constructor(config)
	{
		config = B_REST_Utils.object_hasValidStruct_assert(config, {
			purposeTag:                 {accept:[String],        required:true},
			viewType:                   {accept:[String],        required:true},
			ifWeeklyTemplate_weekday:   {accept:[Number,null],   default:null},
			ifNavigable_date:           {accept:[Date,null],     default:null}, //Check warning above
			filters:                    {accept:[Object,null],   default:null},
			ifManagement_session_fk:    {accept:[Number,null],   default:null}, //Req if isManagement purpose
			ifManagement_franchisee_fk: {accept:[Number,null],   default:null}, //Req if isManagement purpose
			ifRegFlow_instance:         {accept:[RegFlow,null],  default:null}, //Req if isRegFlow purpose
			ifCustomDTRange_dt_from:    {accept:[Date,null],     default:null}, //Req if VIEW_TYPE_CUSTOM_DT_RANGE
			ifCustomDTRange_dt_to:      {accept:[Date,null],     default:null}, //Req if VIEW_TYPE_CUSTOM_DT_RANGE
			afterViewUpdate:            {accept:[Function,null], default:null},
		}, "Calendar");
		
		if (!B_REST_Utils.object_hasPropName(Calendar._PURPOSE_CONFIGS,config.purposeTag)) { this._throwEx(`Unsupported purpose "${config.purposeTag}"`); }
		this._purposeTag    = config.purposeTag;
		this._purposeConfig = Calendar._PURPOSE_CONFIGS[config.purposeTag];
		
		//Date, either as a real date or fake date (if template). Note that in VIEW_TYPE_CUSTOM_DT_RANGE it's ignored
		{
			if (this.isWeeklyTemplateMode)
			{
				if      (!config.ifWeeklyTemplate_weekday)                                     { config.ifWeeklyTemplate_weekday=MyApp.currentSessionInfo_dt_now_weekday;                  }
				else if (config.ifWeeklyTemplate_weekday<0||config.ifWeeklyTemplate_weekday>6) { this._throwEx(`ifWeeklyTemplate_weekday must be between 0 & 6`,ifWeeklyTemplate_weekday); }
				this._date = Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES[config.ifWeeklyTemplate_weekday].dt;
			}
			else if (config.ifNavigable_date)
			{
				B_REST_Utils.dt_assert_isValid(config.ifNavigable_date);
				this._date = config.ifNavigable_date; //WARNING: Check constructor warning about timezone rounding to wrong date
			}
			else { this._date = MyApp.currentSessionInfo_dt_now; }
			
			//Those are for VIEW_TYPE_CUSTOM_DT_RANGE; we won't validate that it's set when it's that view, because we could be changing it later
			{
				this._ifCustomDTRange_dt_from = config.ifCustomDTRange_dt_from;
				this._ifCustomDTRange_dt_to   = config.ifCustomDTRange_dt_to;
			}
		}
		
		//Things that are only (for now) for presential & virtual management calendars
		if (this.isManagement)
		{
			if (!config.ifManagement_session_fk)    { this._throwEx(`Must specify session_fk in presential & virtual management purposes`); }
			if (!config.ifManagement_franchisee_fk) { this._throwEx(`Must specify session_fk in presential management purpose`);            }
			
			this._ifManagement_session_fk    = config.ifManagement_session_fk;
			this._ifManagement_franchisee_fk = config.ifManagement_franchisee_fk;
		}
		//Things for reg flow
		else if (this.isRegFlow)
		{
			if (!config.ifRegFlow_instance) { this._throwEx(`Must specify ifRegFlow_instance in reg flow purposes`); }

			this._ifRegFlow_instance = config.ifRegFlow_instance;
		}
		
		if (config.afterViewUpdate) { this.hooks_afterViewUpdate_add(config.afterViewUpdate); }
		
		this._view_changeType_validate(config.viewType);
		this._viewType = config.viewType; //IMPORTANT: Don't change to "view_changeType(config.viewType)"; check constructor docs for why
	}
	
	
	static t_common(subLocPath,details=null) { return MyApp.t_custom(`app.consts.calendar.${subLocPath}`,details); } //WARNING: Path struct also used in App.js::consts_x_asTranslatedItems()
	_throwEx(msg,details=null) { B_REST_Utils.throwEx(`Calendar: ${msg}`,details); }
	
	get purposeTag()                     { return this._purposeTag;                                             }
	get purpose_isManagementPresential() { return this._purposeTag===Calendar.PURPOSE_MANAGEMENT_PRESENTIAL;    } //NOTE: Rather than using this, should prolly rely on purposeConfig.x
	get purpose_isRegFlowPresential()    { return this._purposeTag===Calendar.PURPOSE_REG_FLOW_PRESENTIAL;      } //NOTE: Same as the above
	get purpose_isRegFlowMakeUp()        { return this._purposeTag===Calendar.PURPOSE_REG_FLOW_MAKEUP;          } //NOTE: Same as the above
	get purpose_isRegFlowTrial()         { return this._purposeTag===Calendar.PURPOSE_REG_FLOW_TRIAL;           } //NOTE: Same as the above
	get purpose_isMySchedule()           { return this._purposeTag===Calendar.PURPOSE_MY_SCHEDULE;              } //NOTE: Same as the above
	get isManagement()                   { return this._purposeConfig.isManagement;                             }
	get isRegFlow()                      { return this._purposeConfig.isRegFlow;                                }
	get isMySchedule()                   { return this._purposeConfig.isMySchedule;                             }
	get ifManagement_session_fk()        { return this._ifManagement_session_fk;                                }
	get ifManagement_franchisee_fk()     { return this._ifManagement_franchisee_fk;                             }
	get ifRegFlow_instance()             { return this._ifRegFlow_instance;                                     }
	get regFlowPlaceCountKeyName()       { return this._purposeConfig.regFlowPlaceCountKeyName;                 }
	get isWeeklyTemplateMode()           { return this._purposeConfig.isWeeklyTemplateMode;                     }
	get date()                           { return this._date;                                                   }
	get date_Ymd()                       { return B_REST_Utils.dt_toYmd(this._date);                            }
	get date_sundayOffset()              { return this._date.getDay();                                          }
	get viewType()                       { return this._viewType;                                               }
	get viewType_isMonth()               { return this._viewType===Calendar.VIEW_TYPE_MONTH;                    }
	get viewType_isWeek()                { return this._viewType===Calendar.VIEW_TYPE_WEEK;                     }
	get viewType_isDay()                 { return this._viewType===Calendar.VIEW_TYPE_DAY;                      }
	get viewType_isCustomDTRange()       { return this._viewType===Calendar.VIEW_TYPE_CUSTOM_DT_RANGE;          }
	get rangeTitle()                     { return this._rangeTitle;                                             }
	get range_out_from()                 { return this._range_out_from;                                         }
	get range_in_from()                  { return this._range_in_from;                                          }
	get range_in_to()                    { return this._range_in_to;                                            }
	get range_out_to()                   { return this._range_out_to;                                           }
	get events()                         { return this._events;                                                 }
	get isLoading()                      { return this._isLoading;                                              }
	
	get hooks_afterViewUpdate()     { return this._hooks_afterViewUpdate;     }
	hooks_afterViewUpdate_add(func) { this._hooks_afterViewUpdate.push(func); }
	
	get ifCustomDTRange_dt_from()     { return this._ifCustomDTRange_dt_from; }
	get ifCustomDTRange_dt_to()       { return this._ifCustomDTRange_dt_to;   }
	set ifCustomDTRange_dt_from(val)  { B_REST_Utils.instance_isOfClass_assert(Date,val); this._ifCustomDTRange_dt_from=val; }
	set ifCustomDTRange_dt_to(val)    { B_REST_Utils.instance_isOfClass_assert(Date,val); this._ifCustomDTRange_dt_to  =val; }
	
	//Ret bool if refreshing the view worked
	async view_changeType(val, targetDate=null)
	{
		this._view_changeType_validate(val);
		
		this._viewType = val;
		
		return targetDate ? this.view_goDate(targetDate) : this.updateView_reloadEvents();
	}
		_view_changeType_validate(val)
		{
			switch (val)
			{
				case Calendar.VIEW_TYPE_MONTH:
					if (this.isWeeklyTemplateMode) { this._throwEx(`Can't use VIEW_TYPE_MONTH in weekly templates`); }
				break;
				case Calendar.VIEW_TYPE_CUSTOM_DT_RANGE:
					if (!this.isMySchedule)                                             { this._throwEx(`Can only use VIEW_TYPE_CUSTOM_DT_RANGE for mySchedule`);  } //For now
					if (!this._ifCustomDTRange_dt_from || !this._ifCustomDTRange_dt_to) { this._throwEx(`VIEW_TYPE_CUSTOM_DT_RANGE missing ifCustomDTRange_dt_x`); }
				break;
				case Calendar.VIEW_TYPE_WEEK: case Calendar.VIEW_TYPE_DAY:
					//Nothing more to do
				break;
				default: this._throwEx(`Unsupported viewType "${val}"`);
			}
		}
		//Helpers also ret bools
		async view_changeType_month(targetDate=null) { return this.view_changeType(Calendar.VIEW_TYPE_MONTH,targetDate); }
		async view_changeType_week( targetDate=null) { return this.view_changeType(Calendar.VIEW_TYPE_WEEK, targetDate); }
		async view_changeType_day(  targetDate=null) { return this.view_changeType(Calendar.VIEW_TYPE_DAY,  targetDate); }
		async view_changeType_customDTRange()        { return this.view_changeType(Calendar.VIEW_TYPE_CUSTOM_DT_RANGE);  }
	//Ret bool if refreshing the view worked
	async view_goDate(date)
	{
		if (B_REST_Utils.string_is(date)) { date = B_REST_Utils.dt_fromYmd(date.substr(0,10)); } //Could throw
		B_REST_Utils.dt_assert_isValid(date);
		if (this.viewType_isCustomDTRange) { this._throwEx(`Can't do that when viewType_isCustomDTRange`); }
		
		this._date = date;
		
		return this.updateView_reloadEvents();
	}
		//Funcs to navigate time (and refetch events) while staying in the current view type. Ret bool if refreshing the view worked
		async view_goToday()        { return this.view_goDate(MyApp.currentSessionInfo_dt_now); }
		async view_shift_previous() { return this._view_shift_x(false);                         }
		async view_shift_next()     { return this._view_shift_x(true);                          }
			async _view_shift_x(isNext)
			{
				if (this.viewType_isCustomDTRange) { this._throwEx(`Can't do that when viewType_isCustomDTRange`); }
				
				let targetDate = null;
				switch (this._viewType)
				{
					case Calendar.VIEW_TYPE_MONTH:
						if (this.isWeeklyTemplateMode) { this._throwEx(`Can nav months in weekly template mode`); }
						targetDate = B_REST_Utils.dt_deltaMonths(this._date,isNext?1:-1);
					break;
					case Calendar.VIEW_TYPE_WEEK:
						if (this.isWeeklyTemplateMode) { this._throwEx(`Can nav months in weekly template mode`); }
						targetDate = B_REST_Utils.dt_deltaDays(this._date,isNext?7:-7);
					break;
					case Calendar.VIEW_TYPE_DAY:
						let delta = isNext?1:-1;
						//In weekly template mode, we cycle saturdays & sundays
						if (this.isWeeklyTemplateMode)
						{
							const date_time = this._date.getTime(); //WARNING: To check that 2 dates are equal, use date.getTime() and not just == or ===. Also, if ever we get a new Date() from outside and it didn't specify His, dates might never match
							
							if      ( isNext && date_time===Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES_SATURDAY_TIME) { delta=-6; }
							else if (!isNext && date_time===Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES_SUNDAY_TIME)   { delta= 6; }
						}
						targetDate = B_REST_Utils.dt_deltaDays(this._date,delta);
					break;
					default: this._throwEx(`Unsupported viewType "${this._viewType}"`);
				}
				
				return this.view_goDate(targetDate);
			}
	
	//Ret bool if refreshing the view worked
	async weeklyTemplateMode_setWeekViewType(sundayOffset=null)
	{
		//If we've got no week day, then check against what day we're supposed to be
		if (sundayOffset===null) { sundayOffset=MyApp.currentSessionInfo_dt_now_weekday; }
		
		return this.view_changeType_week(Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES[sundayOffset].dt);
	}
	//Ret bool if refreshing the view worked
	async weeklyTemplateMode_setDayViewType_goWeekday(sundayOffset) { return this.view_changeType_day(Calendar._WEEKLY_TEMPLATE_MODE_DUMMY_DATES[sundayOffset].dt); }
	
	static weekday_makeLabel_short(sundayOffset) { return Calendar.t_common(`weekdays.${sundayOffset}.short`); }
	static weekday_makeLabel_long( sundayOffset) { return Calendar.t_common(`weekdays.${sundayOffset}.long`);  }
	
	/*
	Rets bool if refreshing the view worked. In case of err, it's also sent via hooks_afterViewUpdate
	Optimization notes:
		It's better to load ALL info for ALL events in a given view, rather than loading the minimum for the calendar and load the extra details in popups,
		because of the way data is tightly compressed in tables, requiring more overhead when we need them, and also for usage of cache.
		Ex, fetching all one shot could be 15kb, vs loading a minimum of 9kb for the calendar and an extra 1kb each time we open an event.
		It's almost the same traffic, and w only 1 call we have less chances of creating bugs and if we want to make a UX change to calendar, we don't need to alter backend
	*/
	async updateView_reloadEvents()
	{
		if (this._viewType===null || this._date===null) { this._throwEx(`View type & date not set`); }
		
		let errorMsg = null;
		
		this._isLoading = true;
		
		this._events = [];
		
		//Figure range info (as Date instances)
		{
			switch (this._viewType)
			{
				case Calendar.VIEW_TYPE_MONTH:
					this._range_in_from  = B_REST_Utils.dt_shiftDateTime_month_first_00_00_00(  this._date);
					this._range_in_to    = B_REST_Utils.dt_shiftDateTime_month_last_23_59_59(   this._date);
					this._range_out_from = B_REST_Utils.dt_shiftDateTime_week_sunday_00_00_00(  this._range_in_from);
					this._range_out_to   = B_REST_Utils.dt_shiftDateTime_week_saturday_23_59_59(this._range_in_to);
				break;
				case Calendar.VIEW_TYPE_WEEK:
					//NOTE: We'll always -display- the calendar as from monday to sunday, but in isWeeklyTemplateMode we have to convert here to 0-6 instead of 1-0 or it'll find nothing
					if (this.isWeeklyTemplateMode)
					{
						this._range_out_from = this._range_in_from = B_REST_Utils.dt_shiftDateTime_week_sunday_00_00_00(  this._date);
						this._range_out_to   = this._range_in_to   = B_REST_Utils.dt_shiftDateTime_week_saturday_23_59_59(this._date);
					}
					else
					{
						this._range_out_from = this._range_in_from = B_REST_Utils.dt_shiftDateTime_week_H_i_s(this._date, 1, 0, 0, 0);
						this._range_out_to   = this._range_in_to   = B_REST_Utils.dt_shiftDateTime_week_H_i_s(this._date, 7,23,59,59);
					}
				break;
				case Calendar.VIEW_TYPE_DAY:
					this._range_out_from = this._range_in_from = B_REST_Utils.dt_shiftTime_00_00_00(this._date);
					this._range_out_to   = this._range_in_to   = B_REST_Utils.dt_shiftTime_23_59_59(this._date);
				break;
				case Calendar.VIEW_TYPE_CUSTOM_DT_RANGE:
					this._range_out_from = this._range_in_from = this._ifCustomDTRange_dt_from;
					this._range_out_to   = this._range_in_to   = this._ifCustomDTRange_dt_to;
				break;
				default: this._throwEx(`Unhandled viewType "${this._viewType}"`);
			}
		}
		
		const range_out_from_Ymd = B_REST_Utils.dt_toYmd(this._range_out_from);
		const range_in_from_Ymd  = B_REST_Utils.dt_toYmd(this._range_in_from);
		const range_in_to_Ymd    = B_REST_Utils.dt_toYmd(this._range_in_to);
		const range_out_to_Ymd   = B_REST_Utils.dt_toYmd(this._range_out_to);
		
		//Update range title -now-, if in template mode. Otherwise, we need the result of an API call
		if (this.isWeeklyTemplateMode)
		{
			this._rangeTitle = {
				main: this.viewType_isWeek ? null : Calendar.t_common(`weekdays.${this._range_in_from.getDay()}.long`),
				sub:  null,
			};
		}
		
		//Refetch events + if we weren't in template mode, we needed the result of the API call to update the range title
		{
			const pathVars_range_from = this.isWeeklyTemplateMode ? this._range_out_from.getDay() : range_out_from_Ymd;
			const pathVars_range_to   = this.isWeeklyTemplateMode ? this._range_out_to.getDay()   : range_out_to_Ymd;
			const pathVars            = {range:`${pathVars_range_from}${Calendar.VIEW_UPDATE_RANGE_DELIMITER}${pathVars_range_to}`};  //Yields something like "<from>~<to>", either weekdays or dates, depending on purpose
			
			let path = null;
			
			//Make request URL, against purpose
			{
				if (this.isManagement)
				{
					path                = "calendar/management/{session}/presential/{franchisee}/{range}";
					pathVars.session    = this.ifManagement_session_fk;
					pathVars.franchisee = this.ifManagement_franchisee_fk;
				}
				else if (this.isRegFlow)    { path="calendar/regFlow/{range}";                  }
				else if (this.isMySchedule) { path="calendar/mySchedule/{range}";               }
				else                        { this._throwEx(`Unknown calendar purpose config`); }
			}
			
			try
			{
				const request = new MyApp.GET(path, pathVars);
				request.needsAccessToken = false;
				
				if (this._ifRegFlow_instance)
				{
					//Parse filters; ex add "f[configPrograms]=1,2,3" and "f[stats_musculation]=low,high"
					for (const loop_filterName of RegFlowFilters.FILTER_NAMES)
					{
						const loop_filter = this._ifRegFlow_instance.filters[loop_filterName];
						if (loop_filter.selections_has)
						{
							const loop_qsa_key = `${Calendar.VIEW_UPDATE_QSA_FILTERS}[${loop_filterName}]`; //Ex "f[configPrograms]"
							request.qsa_add(loop_qsa_key, loop_filter.selections.join(","));
						}
					}
					
					//We might not have a idUser yet (so no access token), so we must rely on hashes to point to our Model_RegFlow PK
					RegFlow.apiCalls_injectRegFlowHash(request,this._ifRegFlow_instance);
				}
				
				
				const response = await MyApp.call(request);
				const responseData = response.data;
				
				//If we weren't in template mode, we needed the result of the API call to update the range title
				if (!this.isWeeklyTemplateMode)
				{
					const sessionInfo = responseData.nonWeeklyTemplateRangeSessionInfo;
					
					//Figure out range title
					{
						const monthLabel = Calendar.t_common(`months.${this._range_in_from.getMonth()}.long`);
						
						let rangeTitle_main = null;
						let rangeTitle_sub  = null;
						
						//Main part about time
						switch (this._viewType)
						{
							case Calendar.VIEW_TYPE_MONTH:           rangeTitle_main=`${monthLabel} ${this._range_in_from.getFullYear()}`; break;
							case Calendar.VIEW_TYPE_WEEK:            rangeTitle_main=`${range_in_from_Ymd} - ${range_in_to_Ymd}`;          break;
							case Calendar.VIEW_TYPE_DAY:             rangeTitle_main=MyApp.d_format_long(this._range_in_from);             break;
							case Calendar.VIEW_TYPE_CUSTOM_DT_RANGE: rangeTitle_main=`${range_in_from_Ymd} - ${range_in_to_Ymd}`;          break;
							default:                                 this._throwEx(`Unknown viewType "${this._viewType}"`);
						}
						
						//Second part about session and its weeks
						if (sessionInfo?.name)
						{
							const session_weekNumber_x   = sessionInfo.weekIdx_x+1;
							const session_weekNumber_y   = sessionInfo.weekIdx_y!==null ? sessionInfo.weekIdx_y+1 : session_weekNumber_x;
							const viewSpansMultipleWeeks = session_weekNumber_x!==session_weekNumber_y; //NOTE: We can't just go w if it's VIEW_TYPE_MONTH, because we could have only 1 week (yet/remaining) for that session in that month
							
							const weeksLabel = viewSpansMultipleWeeks ? Calendar.t_common("weeksXtoY.long",{x:session_weekNumber_x,y:session_weekNumber_y}) : Calendar.t_common("weekX.long",{x:session_weekNumber_x});
							rangeTitle_sub = `${sessionInfo.name} - ${weeksLabel}`;
						}
						
						this._rangeTitle = {main:rangeTitle_main, sub:rangeTitle_sub};
					}
				}
				
				const events = [];
					for (const loop_eventObj of responseData.events)
					{
						const loop_event = new CalendarEvent(loop_eventObj, responseData.lookups);
						events.push(loop_event);
					}
				this._events = events;
			}
			catch (e)
			{
				errorMsg = e;
				B_REST_Utils.console_error(errorMsg);
			}
		}
		
		this._isLoading = false;
		
		//Maybe not good that it fires here, as if we get an API prob, maybe hook should have been called anyways
		for (const loop_func of this._hooks_afterViewUpdate) { loop_func(this,errorMsg); }
		
		return errorMsg===null;
	}
	
	toVCalendarEvents() { return this._events.map(loop_event => loop_event.toVCalendarEvent()); }
	
	//Rets the nb of events for that date
	events_countDaily(date) { return this._events.filter(loop_event => loop_event.date_Ymd===date).length; }
	
	//Only if we're staff. Rets true or err msg
	async events_reLoadParticipants(event)
	{
		B_REST_Utils.instance_isOfClass_assert(CalendarEvent, event);
		if (!this.isMySchedule) { this._throwEx(`Can't use this w that calendar purpose`); }
		
		try
		{
			const request  = new MyApp.GET("calendar/mySchedule/participants/{event}/{occurrenceIdx}", {event:event.pk,occurrenceIdx:event.occurrenceIdx});
			const response = await MyApp.call(request);
			const responseData = response.data;
			
			event.mySchedule_staffParticipants = responseData.participants.map(loop_participantObj => new CalendarEventClient(loop_participantObj));
			return true;
		}
		catch (e) { return `Got unhandled error: ${e}`; }
	}
	
	/*
	Always resolves
	Rets true or err msg
	Only things we can do is request to miss occurrence in advance and get a makeUp / trial token
	*/
	async eventActions_mySchedule_client(event)
	{
		B_REST_Utils.instance_isOfClass_assert(CalendarEvent, event);
		if (!this.isMySchedule) { this._throwEx(`Can't use this w that calendar purpose`); }
		
		this._isLoading = true;
		
		try
		{
			switch (event.mySchedule_clientActionState)
			{
				case CalendarEvent.MY_SCHEDULE_CLIENT_ACTION_STATE_ANY_MISS_CAN:
					const request = new MyApp.POST("calendar/mySchedule/miss");
					request.data = {
						event:         event.pk,
						occurrenceIdx: event.occurrenceIdx,
						reason:        event.mySchedule_clientMissed_reason,
					};
					const response     = await MyApp.call(request);
					const responseData = response.data;
					
					event.mySchedule_clientActionState = responseData.clientActionState; //Let server decide which state to really become
				break;
				default: this._throwEx(`Not supposed to do nothing w mySchedule_clientActionState "${event.mySchedule_clientActionState}", or it's unknown`);
			}
			
			this._isLoading = false;
			return true;
		}
		catch (e)
		{
			this._isLoading = false;
			return `Got unhandled error: ${e}`;
		}
	}
	
	/*
	Always resolves
	Rets true or translated err msg or false for generic err msg
	*/
	async eventActions_mySchedule_staff(event, eventClient, actionName, details)
	{
		B_REST_Utils.instance_isOfClass_assert(CalendarEvent,       event);
		B_REST_Utils.instance_isOfClass_assert(CalendarEventClient, eventClient);
		if (!this.isMySchedule) { this._throwEx(`Can't use this w that calendar purpose`); }
		
		this._isLoading = true;
		
		try
		{
			let errMsg = null;
			
			switch (actionName)
			{
				case "setState":
					const request = new MyApp.POST("calendar/mySchedule/participants/{event}/{occurrenceIdx}/setClientAttendance/{client}", {event:event.pk,occurrenceIdx:event.occurrenceIdx,client:eventClient.client_fk});
					request.data_set("state", details.state);
					const response     = await MyApp.call(request);
					const responseData = response.data;
					
					errMsg            = responseData.errMsg;
					eventClient.state = responseData.state;   //Let server decide which state to really become
				break;
				default: B_REST_Utils.throwEx(`Unexpected actionName "${actionName}"`);
			}
			
			this._isLoading = false;
			return errMsg===null ? true : errMsg;
		}
		catch (e)
		{
			this._isLoading = false;
			return false;
		}
	}
};
